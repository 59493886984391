import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from "src/app/services/api.service";
import { SurveyDataRequestBody } from '../models/surveyData.model';


export interface TestData {
  dropdownTestData: any[];
}

@Component({
  selector: 'app-testapi',
  templateUrl: './testapi.component.html',
  styleUrls: ['./testapi.component.scss']
})
export class TestapiComponent implements OnInit {

  testAPIForm: FormGroup;

  dropdownData: TestData[] = [];

  surveyDataRequestBodyObject: SurveyDataRequestBody = {
    colVar: '',
    colVarValue: [],
    rowvars: [],
    chartType: '',
    year: '',
    region: 0,
    state: 0,
    msa: 0,
    filterVar: '',
    filterVarValue: [],
    filterVar2: '',
    filterVarValue2: []
  };


  constructor(private fb: FormBuilder, private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.setSurveyDataRequestBody();
    this.createForm();
  }

  setSurveyDataRequestBody(): void {

    this.surveyDataRequestBodyObject.year = '2017';
    this.surveyDataRequestBodyObject.colVar = 'hhassav';
    this.surveyDataRequestBodyObject.rowvars = ['praceeth2', 'pagegrp', 'peducgrp', 'pempstat', 'hhincome', 'pdisabl_age25to64', 'gtcbsast'];
  }

  async createForm() {
    console.log('createForm...');
    await this.getDropdownValues();

    this.testAPIForm = this.fb.group({
      testData: new FormControl(this.dropdownData, Validators.required),
    });
  }

  private async getDropdownValues() {
    console.log('getDropdownValues...');

    const countResult: any = await this.apiService.getTestCount();
    console.log('countResult');
    console.log(countResult);
    // this.dropdownData = countResult.data.map((i) => i.count);
    this.dropdownData = countResult[0].count;

  }

  getData(): void {
    this.apiService.getSurveyData(this.surveyDataRequestBodyObject).then((response: any) => {
      if (response.data.length > 0) {
        console.log('Response received...');
        console.log(response);
      } else {
        console.log('NO RESPONSE');
        console.log(response);
      }
    });
  }


  submit() {
    this.getData();
  }

}
