import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GenericModel } from "src/app/models/GenericModel.model";
import { ScriptsService } from "src/app/services/scripts.service";
import { PlaceDataService } from 'src/app/services/place-data.service';
import {
  ChartDataSource,
  ChartCategory,
  ChartDataset,
  TableDataForChart,

} from "../../models/chartTool.model";
import { placeChartConfig } from "../../common/Chart";
import {
  SurveyDataMetadata,
  SurveyDataRequestBody,
  SurveyEvent,
} from "src/app/models/surveyData.model";
import { ApiVbarComponent } from "../api-vbar/api-vbar.component";
import * as FusionCharts from "fusioncharts";
import { ApiService } from "src/app/services/api.service";


@Component({
  selector: 'app-place-data-chart',
  templateUrl: './place-data-chart.component.html',
  styleUrls: ['./place-data-chart.component.scss']
})
export class PlaceDataChartComponent implements OnInit {
  dataTitle: string;
  dataSubtitle: string;
  toolTypeTitle: string;
  msadata: any[];
  selectedMSA = 0;
  originalCategories: ChartCategory[] = [
    {
      category: [],
    },
  ];
  originalDataset: ChartDataset[];
  dataSource: ChartDataSource = {
    chart: {}
  };

  // Separating this from chart config because wouldn't update as object
  type: string;
  columnHeaders: GenericModel[];
  tableData: any[][];
  chartData: any[];
  chartTypeForQuery = "vbar";
  requestBody: SurveyDataRequestBody;
  requestMetadata: SurveyDataMetadata;
  tableHeaderRow: any[] = [];
  statedata: any[];
  showChart = true;
  loading = true;
  msaCode: number = 0;

  constructor(private scripts: ScriptsService, private route: ActivatedRoute, private placeDataService: PlaceDataService, private apiService: ApiService,) {

  }

  @ViewChild(ApiVbarComponent) private dataTable: ApiVbarComponent;

  async ngOnInit(): Promise<void> {

  }

  getPlaceChart() {

    this.dataTable.getData({

      requestBody: this.requestBody,
      requestMetadata: this.requestMetadata,
    });
  }
  ngAfterViewInit() {

    this.setComponentProperties();

  }

  setComponentProperties(): void {
    if ("msa" === this.placeDataService.curTypeTitle) {
      this.apiService.getMsas().then((msas: any) => {
        this.msadata = msas;
        let msa = this.msadata.find(
          (o) => o.Value === this.placeDataService.placeName
        );
        this.msaCode = msa.Code;
        this.setData();
      });
    } else {
      this.setData();
    }
  }

  setData() {

    this.type = "mscolumn2d";
    this.toolTypeTitle = "Vertical";
    this.chartTypeForQuery = "vbar";
    this.requestBody = {
      chartType: this.chartTypeForQuery,
      year: "multi",
      colVar: "hunbnk",
      rowvars: ["hhincome"],
      needNewData: true,
      filterVar: "",
      filterVar2: "",
      region: this.placeDataService.curRegion,
      state: this.placeDataService.curState,
      msa: this.msaCode,
    };
    this.requestMetadata = {
      yVariableTopic: "Bank Account Ownership",
      yVariableText: "Unbanked",
      area: this.placeDataService.curTypeTitle,
      year: "multi",
      colVarValue: ["Unbanked"],

      filterText: "",
      filterText2: "",
    };
    this.dataSource.chart = placeChartConfig;
    this.dataTable.getData({
      requestBody: this.requestBody,
      requestMetadata: this.requestMetadata,
    });
  }

  receiveTableData($event: TableDataForChart) {
    if ($event.tableData.length > 0) {
      this.tableData = $event.tableData;
      this.tableHeaderRow = $event.tableHeaderRow;
      this.dataTitle = this.dataSource.chart.caption = $event.dataTitle;
      this.dataSubtitle = this.dataSource.chart.subCaption = $event.dataSubtitle;
      console.log("tableData:",this.tableData)
      // if (this.requestBody.year === 'multi') {
      this.createOriginalMultiyearChart();
      // } else {
      //   this.createOriginalChart();
      // }
    } else {
      this.showChart = false;
    }
    this.loading = false;
  }

  createOriginalMultiyearChart(): void {
    this.originalDataset = [];
    this.originalCategories = [
      {
        category: [],
      },
    ];
    this.dataSource.categories = [];
    this.dataSource.dataset = [];

    const { colVarValue } = this.requestMetadata;
    // Multiyear data comes in three arrays
    const { category } = this.originalCategories[0];

    this.showChart = false;
    // Any missing records were handled in the table build, so data can be added to the chart in order
    // Multiyear does years in reverse order
    for (let i = this.tableData.length - 1; i >= 0; i--) {
      //add missing data
      if(this.tableData[i][0].length<=colVarValue.length)
      {
        for(let c=0 ; c< colVarValue.length; c++)
        {
         if( this.tableData[i][0].find(x=> x.colheader == colVarValue[c]) == undefined)
         {
           this.tableData[i][0].push( { colheader: colVarValue[c], dcount:0, percent:0, isNA:false })
         } 
         
        } 
      }
      const currentYearArray = this.tableData[i][0];
      category.push({ label: currentYearArray[0].year.toString() });
      // Tracker for which array to use in originalDataset
      let dataIndex = 0;
   
      for (let j = 0; j < currentYearArray.length; j++) {
        const item = currentYearArray[j];
        // Make sure item should be displayed
        if (colVarValue.includes(item.colheader)) {
          // For initial items, create dataset
          if (i === this.tableData.length - 1) {
            this.originalDataset.push({ seriesname: item.colheader, data: [] })
          }
          // Check if data exists to display
          if (this.showChart === false && !item.isNA) {
            this.showChart = true;
          }
          // Make sure that item surpasses supression count or make percent NA
          if (item.dcount < this.dataTable.supressioncount) {
            item.percent = "NA";
          }
          // NA values need to be added as displayValue
          if (item.percent === 'NA' || item.percent === '-') {
            this.originalDataset[dataIndex].data.push({ value: "0", displayValue: item.percent });
          } else if (item.percent === 0) {
            this.originalDataset[dataIndex].data.push({ value: "0", displayValue: '-' });
          } else {
            this.originalDataset[dataIndex].data.push({ value: item.percent, valueBgColor: '#ffffff' });
          }
          dataIndex += 1;
        }
      }
    }
    this.dataSource.categories = this.originalCategories;
    this.dataSource.dataset = this.originalDataset;
  }

}
