import { Component, OnInit } from '@angular/core';
import { ScriptsService } from '../services/scripts.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private scripts: ScriptsService,) { }

  ngOnInit(): void {
    this.scripts.loadHeaderFooterScript();
  }
}
