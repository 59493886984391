import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ApiService } from "src/app/services/api.service";
import { ScriptsService } from "src/app/services/scripts.service";
import { ExcelExportService } from "src/app/services/excel-export.service";
import { PdfExportService } from "src/app/services/pdf-export.service";
import { ActivatedRoute } from "@angular/router";
import { checkEnv, getContentURL } from 'src/app/util/config';

@Component({
  selector: "app-yoy-analysis",
  templateUrl: "./yoy-analysis.component.html",
  styleUrls: ["./yoy-analysis.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class YoyAnalysisComponent implements OnInit {
  statedata: any[];
  selectedState: string = "";
  selectedMSA: string = "";
  selectedArea: string = "";
  fileName: string;
  title: string;
  public externalHtml: SafeHtml;
  serverAppendUrl = getContentURL();

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private scripts: ScriptsService,
    private excelExportService: ExcelExportService,
    private pdfExportService: PdfExportService,
    private route: ActivatedRoute
  ) {
    this.initSetup();
  }

  initSetup(): void {
    const { type, geo } = this.route.snapshot.queryParams;
    this.apiService.getStateNames().then((states: any) => {
      this.statedata = states;

      if ("state" === type) {
        this.selectedArea = "State";
        let stateCode = this.statedata.find(
          (o) => o.FullText.toLowerCase() === geo.toLowerCase()
        );
        this.selectedState = stateCode.Value;
      } else if ("msa" === type) {
        this.selectedArea = "MSA";
        this.selectedMSA = geo;
      }

      this.getEstimateHtml();
    });
  }

  ngOnInit(): void {
    this.scripts.loadHeaderFooterScript();
  }

  getFileName() {
    if ("MSA" === this.selectedArea) {
      let str = this.selectedMSA;
      str = str.replace(/[,|~|.]/g, "").replace(/[\s|_|/]/g, "-");

      this.fileName = "msa-unbanked-" + str.toLowerCase();
    } else if ("State" === this.selectedArea) {
      this.fileName = "state-unbanked-" + this.selectedState.toLowerCase();
    }
    return this.fileName;
  }

  getTitle() {
    this.title = "Unbanked Rates by Year for ";
    if ("MSA" === this.selectedArea) {
      this.title += this.selectedMSA;
    } else if ("State" === this.selectedArea) {
      let statedesc = this.statedata.find(
        (o) => o.Value === this.selectedState
      );
      this.title += statedesc.FullText;
    }
  }

  getEstimateHtml() {
    const headers = new HttpHeaders({
      "Content-Type": "text/plain",
    });

    let filename = this.getFileName();
    let contentURL = this.serverAppendUrl + `/analysis/household-survey/year-on-year/${filename}.html`;// will not work for localhost

    if (checkEnv() === 'prod') {
      const requestProd = this.http
        .get(
          contentURL,
          {
            headers: headers,
            responseType: "text",
          }
        )
        .subscribe(
          (res) => {
            const index = res.lastIndexOf("<table");
            const indexbody = res.indexOf("</table>");
            this.getTitle();

            this.externalHtml = this.sanitizer.bypassSecurityTrustHtml(
              res.slice(index, indexbody)
            );
          },
          (error) => {
            this.externalHtml = "<h3>No Data Found</h3>";
          }
        );
    } else { //withCredentials: true only implemented for lower environments, not needed for prod

      const request = this.http
        .get(
          contentURL,
          {
            headers: headers,
            responseType: "text",
              withCredentials: true,
            }
          )
          .subscribe(
            (res) => {
              const index = res.lastIndexOf("<table");
              const indexbody = res.indexOf("</table>");
              this.getTitle();

              this.externalHtml = this.sanitizer.bypassSecurityTrustHtml(
                res.slice(index, indexbody)
              );
            },
            (error) => {
              this.externalHtml = "<h3>No Data Found</h3>";
            }
          );
    }
  }

  exportexcel(): void {
    // Identify the file name to use
    let excelFileName = this.fileName + ".csv";
    // Get the table id to export
    let element = document.getElementsByTagName("table")[0];
    let headnote = '"' + this.title + '"\n\n';
    let footnotes = document.getElementById("footnotes").querySelectorAll("li");

    // Call Excel Service
    this.excelExportService.exportToExcel(
      excelFileName,
      element,
      headnote,
      footnotes
    );
  }

  public exportpdf() {
    let element = document.getElementsByTagName("table")[0];
    let footnotes = document.getElementById("footnotes");
    let pdfParams = {
      fileName: this.fileName,
      dataTable: element,
      title: this.title,
      footNote: footnotes,
    };
    this.pdfExportService.downloadPDF(pdfParams);    
  }

  export(event) {
    if ("downloadcsv" === event.srcElement.classList[1]) {
      this.exportexcel();
    } else if ("downloadpdf" === event.srcElement.classList[1]) {
      this.exportpdf();
    }
  }
}
