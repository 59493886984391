import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { UtilsService } from 'src/app/core/utils.service';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatButtonModule
} from '@angular/material/button';
import {
  MatCheckboxModule
} from '@angular/material/checkbox';
import {
  MatInputModule
} from '@angular/material/input';
import {
  MatSelectModule
} from '@angular/material/select';
import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import {
  MatNativeDateModule
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HeaderComponent } from './header/header.component';
import { RootComponent } from './root/root.component';
import { MainComponent } from './main/main.component';
import { LoadingComponent } from './core/loading.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomTableComponent } from './custom-table/custom-table/custom-table.component';
import { TestapiComponent } from './testapi/testapi.component';
import { FiveYearEstimateComponent } from './five-year-estimate/five-year-estimate.component';
import { GenericYearPipe } from './util/GenericYearPipe'
import { FooterComponent } from './footer/footer.component';
import { CustomDataYearPipe } from './util/CustomDataYearPipe';
import { MSAYearPipe } from './util/MSAYearPipe';
import { VerticalBarComponent } from './chart-tool/vertical-bar/vertical-bar.component';
import { PlaceDataChartComponent } from './place-data-page/place-data-chart/place-data-chart.component';
// Import angular-fusioncharts
import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as ExcelExport from "fusioncharts/fusioncharts.excelexport";
import * as Maps from 'fusioncharts/fusioncharts.maps';

// For Map definition files
import * as World from 'fusioncharts/maps/fusioncharts.world';
import * as USA from 'fusioncharts/maps/fusioncharts.usa';

import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { DataTableComponent } from './chart-tool/data-table/data-table.component';
import { DisplayOptionsBarComponent } from './chart-tool/display-options-bar/display-options-bar.component';
import { ChartCriteriaSelectionComponent } from './chart-tool/chart-criteria/chart-criteria-selection.component';
import { ChartHeaderComponent } from './chart-tool/chart-header/chart-header.component';
import { MainHeaderComponent } from './custom-table/main-header/main-header.component';

import * as Ext from '../assets/accessibility/fusioncharts.ext.accessibility';
import * as Ext1 from '../assets/accessibility/fusioncharts.ext.accessibility-resources-strings-en';
import { ExportButtonComponent } from './export-button/export-button.component';
import { PlacePageHeaderComponent } from './survey-map/place-page-header/place-page-header.component';
import { GeographyOptionsBarComponent } from './survey-map/geography-options-bar/geography-options-bar.component';
import { MsaDataDropdownComponent } from './survey-map/msa-data-dropdown/msa-data-dropdown.component';
import { RegionDataDropdownComponent } from './survey-map/region-data-dropdown/region-data-dropdown.component';
import { NationalDataDropdownComponent } from './survey-map/national-data-dropdown/national-data-dropdown.component';
import { CompareAreasDropdownComponent } from './survey-map/compare-areas-dropdown/compare-areas-dropdown.component';
import { StateDataDropdownComponent } from './survey-map/state-data-dropdown/state-data-dropdown.component';
import { PlaceDataPageComponent } from './place-data-page/place-data-page.component';
import { PlaceDataLinksTableComponent } from './place-data-page/place-data-links-table/place-data-links-table.component';
import { PlaceDataHeaderComponent } from './place-data-page/place-data-header/place-data-header.component';
import { ChartExportComponent } from './chart-tool/chart-export/chart-export.component';
import { YoyAnalysisComponent } from './place-data-page/yoy-analysis/yoy-analysis.component';
import { ApiVbarComponent } from './place-data-page/api-vbar/api-vbar.component';
import { MapPageComponent } from './survey-map/map-page/map-page.component';
import { APP_INITIALIZER } from '@angular/core';
import { DataTableService } from './services/data-table.service';
import { CompareToolHeaderComponent } from './compare-tool/compare-tool-header/compare-tool-header.component';
import { CompareToolComponent } from './compare-tool/compare-tool.component';
import { CompareToolCriteriaComponent } from './compare-tool/compare-tool-criteria/compare-tool-criteria.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, Maps, USA, World, FusionTheme, ExcelExport, Ext, Ext1);
FusionCharts.options['license']({
  key: 'CyA8qlbE4D2A22A3A2A1A1D1A1H5E2H1A8apzA2E5D-8H-8woxB4B1sevE4H1B9D4C3D2D2C4C1F2H4A1B3A3B1B3axxH2B7B2xD2C2E1mlF-7C11C2C7egvD4F3H3eD-16C-13F4E2D3F1G1I4B2C8E3E2B2rttB1B11GD1xG-10sG4A19A32bqD8ZB5G4ooxA9C5A5E7E6C5A4B1C3C7C3D7D7D2f==',
  creditLabel: false,
});

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RootComponent,
    MainComponent,
    LoadingComponent,
    CustomTableComponent,
    TestapiComponent,
    FiveYearEstimateComponent,
    GenericYearPipe,
    CustomDataYearPipe,
    FooterComponent,
    MSAYearPipe,
    CustomDataYearPipe,
    VerticalBarComponent,
    DataTableComponent,
    DisplayOptionsBarComponent,
    ChartCriteriaSelectionComponent,
    ChartHeaderComponent,
    ExportButtonComponent,
    MainHeaderComponent,
    PlacePageHeaderComponent,
    GeographyOptionsBarComponent,
    MsaDataDropdownComponent,
    RegionDataDropdownComponent,
    NationalDataDropdownComponent,
    CompareAreasDropdownComponent,
    StateDataDropdownComponent,
    PlaceDataChartComponent,
    PlaceDataPageComponent,
    PlaceDataLinksTableComponent,
    PlaceDataHeaderComponent,
    ChartExportComponent,
    YoyAnalysisComponent,
    ApiVbarComponent,
    MapPageComponent,
    ChartExportComponent,
    CompareToolHeaderComponent,
    CompareToolComponent,
    CompareToolCriteriaComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    MatSidenavModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    MatTableModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    NgSelectModule,
    FlexLayoutModule,
    // Specify FusionChartsModule as import
    FusionChartsModule
  ],
  //initalize the datatable at app start so it gets the data in time to accurately make the table.
  providers: [UtilsService, DataTableService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: DataTableService) => () => { return ds.ngOnInit() },
      deps: [DataTableService],
      multi: true
    }],
  bootstrap: [RootComponent]
})
export class AppModule { }
