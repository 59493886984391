import { Component, Input, OnInit } from '@angular/core';
import { getContentURL } from 'src/app/util/config';

@Component({
  selector: 'app-compare-tool-header',
  templateUrl: './compare-tool-header.component.html',
  styleUrls: ['./compare-tool-header.component.scss']
})
export class CompareToolHeaderComponent {

  serverAppendUrl = getContentURL();

  @Input() surveyYearTitle: string;

}
