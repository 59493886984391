import { Component, OnInit } from "@angular/core";
import { getContentURL } from 'src/app/util/config';

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"],
})
export class MainHeaderComponent implements OnInit {
  serverAppendUrl = getContentURL();

  constructor() { }

  ngOnInit(): void { }
}
