import { Component, Input, OnInit } from '@angular/core';
import { getContentURL } from 'src/app/util/config';

@Component({
  selector: "app-chart-header",
  templateUrl: "./chart-header.component.html",
  styleUrls: ["./chart-header.component.scss"],
})
export class ChartHeaderComponent implements OnInit {
  serverAppendUrl = getContentURL();

  @Input() title: string;
  @Input() chartTypeForUrl: string;
  chartType: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.displayChartTypes(this.chartTypeForUrl);
  }

  displayChartTypes(typeVal: string) {
    this.chartType = [
      {
        type: "hbar",
        value: "Horizontal",
        charturl: `${window.location.origin}/custom-chart?chartType=hbar`,
      },
      {
        type: "sbar",
        value: "Stacked",
        charturl: `${window.location.origin}/custom-chart?chartType=sbar`,
      },
      {
        type: "vbar",
        value: "Vertical",
        charturl: `${window.location.origin}/custom-chart?chartType=vbar`,
      },
      {
        type: "map",
        value: "Map",
        charturl: `${window.location.origin}/custom-chart?chartType=map`,
      },
      {
        type: "pchart",
        value: "Pie",
        charturl: `${window.location.origin}/custom-chart?chartType=pchart`,
      },
    ];

    this.chartType = this.chartType.filter(function (item) {
      return item.type !== typeVal;
    });
  }
}
