import { Pipe, PipeTransform } from '@angular/core';
import { GenericModel } from 'src/app/models/GenericModel.model';

@Pipe({
  name: 'MSAYearFilter',
  pure: false
})
export class MSAYearPipe implements PipeTransform {
  transform(items: GenericModel[], filter: string | number): any {
    if (typeof filter === 'number') {
      filter = filter.toString();
    }
    let selectedYearString: string = 'in' + filter;

    if (!items || !filter) {
      return items;
    }
    if (filter == "multi") {
      return items.filter(item => item.in2023 === 'y');
    }

    switch (selectedYearString) {
      case 'in2009': return items.filter(item => item.in2009 === 'y');
      case 'in2011': return items.filter(item => item.in2011 === 'y');
      case 'in2013': return items.filter(item => item.in2013 === 'y');
      case 'in2015': return items.filter(item => item.in2015 === 'y');
      case 'in2017': return items.filter(item => item.in2017 === 'y');
      case 'in2019': return items.filter(item => item.in2019 === 'y');
      case 'in2021': return items.filter(item => item.in2021 === 'y');
      case 'in2023': return items.filter(item => item.in2023 === 'y');

    }
  }
}