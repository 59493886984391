import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-mainapproot',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Household Survey';

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private router: Router, private modalService: BsModalService) {
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}
