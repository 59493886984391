import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickExcel(event) {
    this.onClick.emit(event);
  }

  onClickPdf(event) {
    this.onClick.emit(event);
  }
}
