import { Injectable } from '@angular/core';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

@Injectable({
  providedIn: "root",
})
export class ExcelExportService {
  exportToExcel(
    excelFileName: string,
    element: any,
    headnote: any,
    footnote: any
  ): void {
    // generate workbook and add the worksheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, {
      raw: true,
    });

    let csvOutput: string = "";
    headnote = this.cleanString(headnote);
    csvOutput += headnote;

    // creating csv output for table
    csvOutput += XLSX.utils.sheet_to_csv(worksheet, {
      forceQuotes: true,
    });

    // add footnote
    csvOutput = csvOutput + "\n";
    footnote.forEach((item) => {
      let itemtext = this.cleanString(item.innerText);
      csvOutput = csvOutput + '"' + itemtext + '"\n';
    });

    /* save to file */
    FileSaver.saveAs(new Blob([csvOutput]), `${excelFileName}`);
  }

  cleanString(input) {
    let output = "";
    if (input.charAt(0) === "-") {
      input = " " + input;
    }
    for (let i = 0; i < input.length; i++) {
      if (input.charCodeAt(i) <= 127) {
        output += input.charAt(i);
      } else {
        output += " ";
      }
    }
    return output;
  }
}