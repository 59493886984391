import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-display-options-bar',
  templateUrl: './display-options-bar.component.html',
  styleUrls: ['./display-options-bar.component.scss']
})
export class DisplayOptionsBarComponent implements OnInit {

  @Output() regroupEvent = new EventEmitter<void>();

  @Output() rotateEvent = new EventEmitter<void>();

  @Input() showRegroupButton: boolean;

  @Input() showRotateButton: boolean;

  @Input() chartType: string;

  constructor() { }

  ngOnInit(): void {
  }

  regroupBarData(): void {
    this.regroupEvent.emit();
  }

  rotateBars(): void {
    this.rotateEvent.emit()
  }
}
