import { Component, Input, OnInit } from '@angular/core';
import { PlaceDataService } from 'src/app/services/place-data.service';

@Component({
  selector: 'app-region-data-dropdown',
  templateUrl: './region-data-dropdown.component.html',
  styleUrls: ['./region-data-dropdown.component.scss']
})
export class RegionDataDropdownComponent {

  constructor(public placedataservice: PlaceDataService) {
  }

  @Input() latestSurveyYear: number;

  selectedRegion: string = "";

  @Input() Regions: any[];
}
