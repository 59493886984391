import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GenericModel } from "src/app/models/GenericModel.model";
import { ScriptsService } from "src/app/services/scripts.service";
import {
  ChartDataSource,
  ChartCategory,
  ChartDataset,
  TableDataForChart,
  PieChartData,
  MapChartData,
  ChartSize,
  ChartProcess,
  ChartDatasetValue,
  ChartTypeForQuery,
  ChartFormat,
  ExportFormat,
  DropdownData,
} from "../../models/chartTool.model";
import { vbarChartConfig, hbarChartConfig, sbarVertChartConfig, sbarHorChartConfig, pieChartConfig, mapChartConfig } from "../../common/Chart";
import {
  SurveyDataMetadata,
  SurveyDataRequestBody,
  SurveyEvent,
} from "src/app/models/surveyData.model";
import { DataTableComponent } from "../data-table/data-table.component";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-vertical-bar",
  templateUrl: "./vertical-bar.component.html",
  styleUrls: ["./vertical-bar.component.scss"],
})
export class VerticalBarComponent implements OnInit {
  dataTitle: string;
  SubtitleData: any[];
  Api: ApiService;
  dataSubtitle: string;
  surveyDataRequestBodyObject: SurveyDataRequestBody = {
    colVar: "",
    colVarValue: [],
    rowvars: [],
    chartType: "",
    year: "",
    region: 0,
    state: 0,
    msa: 0,
    filterVar: "",
    filterVarValue: [],
    filterVar2: "",
    filterVarValue2: [],
  };
  toolTypeTitle: string;
  originalCategories: ChartCategory[] = [
    {
      category: [],
    },
  ];
  regroupedCategories: ChartCategory[];
  originalDataset: ChartDataset[];
  regroupedDataset: ChartDataset[];
  regrouped = false;
  showRegroupButton: boolean;
  showRotateButton: boolean;
  dataSource: ChartDataSource = {
    chart: {}
  };
  dataSourceShadow: ChartDataSource = {
    chart: {}
  };
  defaultType: ChartFormat;
  columnHeaders: GenericModel[];
  tableData: any[][];
  chartData: any[];
  chartTypeForQuery: ChartTypeForQuery = "vbar";
  requestBody: SurveyDataRequestBody;
  requestMetadata: SurveyDataMetadata;
  tableHeaderRow: any[] = [];
  statedata: any[];
  years: string[] = [];
  chart: any;
  chartShadow: any;
  chartWidth = "900";
  chartWidthShadow = "900";
  chartHeight = "650";
  chartHeightShadow = "650";
  chartTypeShadow: ChartFormat;
  previousChartSize: ChartSize = 'small';
  newChartSize: ChartSize = '';
  firstChart = true;
  chartProcess: ChartProcess = 'default';
  needNewChartType = false;
  needNewChartSize = false;
  needNewChartConfig = false;
  needNewLegendConfig = false;
  previousLegendNumColumns = '4';
  newLegendNumColumns = '4';
  rotateValues = false;
  needRotateValues = false;
  rotateChartType: ChartFormat;
  exportFormat: ExportFormat;
  exporting = false;
  hideMap = false;
  showChart = true;
  loading = true;
  paletteColors = ['#003256', 'B7AC83', '#38B6FF', '#C8326C', '#20509E', '#A48923', '#00908C'];

  constructor(private scripts: ScriptsService, private route: ActivatedRoute,private apiService: ApiService) {
    this.setComponentProperties();
    this.Api=apiService;
  }

  @ViewChild(DataTableComponent) private dataTable: DataTableComponent;

  async ngOnInit(): Promise<void> {
    this.scripts.loadHeaderFooterScript();
    this.GetDataForSubTitle();
  }

  GetDataForSubTitle() {
    this.Api.getDataForSubTitle().then((x) => (this.SubtitleData = x));
  }
  
  ngAfterViewInit() {
    this.sendInitialRequest();
  }

  initialized($event): void {
    // Capture chart instance
    this.chart = $event.chart;
  }

  initializedShadow($event): void {
    // Capture chart instance
    this.chartShadow = $event.chart;
  }


  renderComplete($event): void {
    switch (this.chartProcess) {
      case 'newOrFiltered':
      case 'rotate':
      case 'regroup':
        this.handleChartChanges();
        break;
      case 'noData':
        if (this.chartTypeForQuery === 'map') {
          this.hideMap = true;
        }
        this.loading = false;
        this.showChart = false;
        break;
      default:
        break;
    }
  }

  handleChartChanges(): void {
    if (this.needNewChartSize) {
      if (this.chartProcess === 'newOrFiltered') {
        this.setChartSize(this.newChartSize);
      } else {
        this.setChartSize(this.newChartSize, this.rotateChartType);
      }
      this.needNewChartSize = false;
    } else if (this.needNewLegendConfig && !this.needNewChartConfig) {
      this.chart.setChartAttribute('legendNumColumns', this.newLegendNumColumns)
      this.needNewLegendConfig = false;
    } else if (this.needRotateValues && !this.needNewChartConfig) {
      this.chart.setChartAttribute('rotateValues', '1')
      this.needRotateValues = false;
    } else if (this.needNewChartConfig) {
      if (this.chartProcess === 'newOrFiltered') {
        this.setChartConfig(this.defaultType);
      } else {
        this.setChartConfig(this.rotateChartType);
      }
      this.needNewChartConfig = false;
    } else {
      this.loading = false;
    }
  }

  // Resizing chart element instead of removing from DOM - strange behavior when removing from DOM
  handleNoData(): void {
    this.createSubtitle(); 
    this.chartProcess = 'noData';
    this.previousChartSize = 'hidden';
    this.chart.resizeTo('50', '50');
  }

  async renderCompleteShadow(): Promise<void> {
    const exportFileName = this.dataSourceShadow.chart.caption
      .replace(/\s/g, "_")
      .replace(/,/g, "_")
      .replace(/__/g, "_");

    this.chartShadow.exportChart({
      exportFormat: this.exportFormat,
      exportFileName,
    });
    this.exporting = false;
  }

  setComponentProperties(): void {
    const { chartType } = this.route.snapshot.queryParams;
    switch (chartType) {
      case "hbar":
        this.defaultType = "msbar2d";
        this.toolTypeTitle = "Horizontal";
        this.chartTypeForQuery = chartType;
        this.showRegroupButton = true;
        this.showRotateButton = true;
        this.requestBody = {
          chartType: this.chartTypeForQuery,
          year: "",
          colVar: "hunbnk",
          rowvars: ["peducgrp"],
          filterVar: "",
          filterVar2: "",
          filterVarValue: [],
          filterVarValue2: [],
          region: 0,
          state: 0,
          msa: 0,
          needNewData: true,
        };
        this.requestMetadata = {
          yVariableTopic: "Bank Account Ownership",
          yVariableText: "Unbanked",
          xVariableTopic: "Demographic",
          xVariableText: "Education",
          area: "National",
          year: "",
          colVarValue: ["Unbanked", "Has bank account"],
          rowvarsValues: [
            "No high school diploma",
            "High school diploma",
            "Some college",
            "College degree",
          ],
          filterText: "",
          filterText2: "",
        };
        this.dataSource.chart = hbarChartConfig;
        break;
      case "sbar":
        this.defaultType = "stackedcolumn2d";
        this.toolTypeTitle = "Stacked";
        this.chartTypeForQuery = chartType;
        this.showRegroupButton = false;
        this.showRotateButton = true;
        this.requestBody = {
          chartType: this.chartTypeForQuery,
          year: "",
          colVar: "hunbnk",
          rowvars: ["pagegrp"],
          filterVar: "",
          filterVar2: "",
          filterVarValue: [],
          filterVarValue2: [],
          region: 0,
          state: 0,
          msa: 0,
          needNewData: true,
        };
        this.requestMetadata = {
          yVariableTopic: "Bank Account Ownership",
          yVariableText: "Unbanked",
          xVariableTopic: "Demographic",
          xVariableText: "Age group",
          area: "National",
          year: "",
          colVarValue: ["Unbanked", "Has bank account"],
          rowvarsValues: [
            "15 to 24 years",
            "25 to 34 years",
            "35 to 44 years",
            "45 to 54 years",
            "55 to 64 years",
            "65 years or more",
          ],
          filterText: "",
          filterText2: "",
        };
        this.dataSource.chart = sbarVertChartConfig;
        break;
      case "pchart":
        this.defaultType = "pie2d";
        this.toolTypeTitle = "Pie";
        this.chartTypeForQuery = chartType;
        this.showRegroupButton = false;
        this.showRotateButton = false;
        this.chartHeight = '600';
        this.requestBody = {
          chartType: this.chartTypeForQuery,
          year: "",
          colVar: "hunbnk",
          filterVar: "",
          filterVar2: "",
          filterVarValue: [],
          filterVarValue2: [],
          region: 0,
          state: 0,
          msa: 0,
          needNewData: true,
        };
        this.requestMetadata = {
          yVariableTopic: "Bank Account Ownership",
          yVariableText: "Unbanked",
          area: "National",
          year: "",
          colVarValue: ["Unbanked", "Has bank account"],
        };
        this.dataSource.chart = pieChartConfig;
        break;
      case "map":
        this.defaultType = "maps/usa";
        this.toolTypeTitle = "Map";
        this.chartTypeForQuery = chartType;
        this.showRegroupButton = false;
        this.showRotateButton = false;
        this.requestBody = {
          chartType: this.chartTypeForQuery,
          year: "",
          colVar: "hunbnk",
          
          filterVar: "",
          filterVar2: "",
          filterVarValue: [],
          filterVarValue2: [],
          region: 0,
          state: 0,
          msa: 0,
          needNewData: true,
        };
        this.requestMetadata = {
          yVariableTopic: "Bank Account Ownership",
          yVariableText: "Unbanked",
          area: "All States",
          year: "",
          colVarValue: ["Unbanked"],
          filterText: "",
          filterText2: "",
        };
        this.dataSource.chart = mapChartConfig;
        break;
      case "vbar":
      default:
        this.defaultType = "mscolumn2d";
        this.toolTypeTitle = "Vertical";
        this.chartTypeForQuery = "vbar";
        this.showRegroupButton = true;
        this.showRotateButton = true;
        this.requestBody = {
          chartType: this.chartTypeForQuery,
          year: "",
          colVar: "hunbnk",
          rowvars: ["hhincome"],
          filterVar: "",
          filterVar2: "",
          filterVarValue: [],
          filterVarValue2: [],
          region: 0,
          state: 0,
          msa: 0,
          needNewData: true,
        };
        this.requestMetadata = {
          yVariableTopic: "Bank Account Ownership",
          yVariableText: "Unbanked",
          xVariableTopic: "Demographic",
          xVariableText: "Family income",
          area: "National",
          year: "",
          colVarValue: ["Unbanked", "Has bank account"],
          rowvarsValues: [
            "Less than $15,000",
            "$15,000 to $30,000",
            "$30,000 to $50,000",
            "$50,000 to $75,000",
            "At least $75,000",
          ],
          filterText: "",
          filterText2: "",
        };
        this.dataSource.chart = vbarChartConfig;
        break;
    }
  }

  sendInitialRequest(): void {
    // Make sure that years request has been received. If not, call again after delay
    if (this.years.length === 0) {
      setTimeout(() => {
        this.sendInitialRequest()
      }, 500);
    } else {
      // filtervars in data-table.service not always populating in time, so set a delay here for initial request only
      setTimeout(() => {
        if (this.chartTypeForQuery === 'map') {
          this.requestBody.rowvars = ['gestfips'];
        }
        this.requestBody.year = this.years[0];
        this.requestMetadata.year = this.years[0];
        this.dataTable.getData({
          requestBody: this.requestBody,
          requestMetadata: this.requestMetadata,
        });
      }, 1000);
    }
  }

  async receiveRequestBody($event: SurveyEvent): Promise<void> {
    this.loading = true;
    this.needNewChartType = false;
    this.needNewChartSize = false;
    this.needNewChartConfig = false;
    this.needRotateValues = false;
    this.rotateValues = false;
    this.regrouped = false;
  
    this.surveyDataRequestBodyObject = $event.requestBody;
    const needNewData = this.compareRequestBody($event.requestBody);
    // For pie, there is no filtering of data, so if needNewData is false, don't do anything.
    if (!needNewData && $event.requestBody.chartType === 'pchart') {
      this.loading = false;
    } else {
      this.requestBody = $event.requestBody;
      this.requestMetadata = $event.requestMetadata;
      this.requestBody.needNewData = needNewData ? true : false;
      this.clearChartData();
      this.chartProcess = 'default';
      await this.dataTable.getData($event);
    }
  }

  receiveDropdownData($event: DropdownData) {
    this.statedata = $event.statedata;
    this.years = $event.years;
  }

  // Compare requests to see if new data needs retrieved or current data needs filtered
  compareRequestBody(newRequestBody: SurveyDataRequestBody): boolean {
    const fieldsToCompare = ['year', 'area', 'region', 'state', 'msa', 'colVar', 'rowvars', 'filterVar', 'filterVarValue', 'filterVar2', 'filterVarValue2'];
    for (let i = 0; i < fieldsToCompare.length; i++) {
      const field = fieldsToCompare[i];
      // Compare stringify for arrays. Rowvar not available in pchart.
      if ((this.chartTypeForQuery !== "pchart" && field === 'rowvars') || field === 'filterVarValue' || field === 'filterVarValue2') {
        if (JSON.stringify(this.requestBody[field]) !== JSON.stringify(newRequestBody[field])) {
          return true;
        }
      } else {
        if (this.requestBody[field] !== newRequestBody[field]) {
          return true;
        }
      }
    }
    return false;
  }
  createSubtitle() {
    this.dataSubtitle = this.dataTable.CreateSubtitle();
    /*this.dataSubtitle = "";

    if (this.surveyDataRequestBodyObject.colVar) {
      let title = this.SubtitleData.find(
        (o) => o.VariableName === this.surveyDataRequestBodyObject.colVar
      );
      if (title != null && title.Notes != null) this.dataSubtitle = title.Notes;
    }
    if (this.dataSubtitle == "") {
      this.dataSubtitle = "All households";
    }*/
  /*  if (this.surveyDataRequestBodyObject.filterVar) {
      var element = this.FilterVarsValues.find(
        (x) => x.VariableName == this.surveyDataRequestBodyObject.filterVar
      );
      this.dataSubtitle += ", " + element.VarLabel + " is ";
      this.surveyDataRequestBodyObject.filterVarValue.forEach((ele) => {
        var value = this.FilterVarsValues.find(
          (x) =>
            x.Code == ele &&
            x.VariableName == this.surveyDataRequestBodyObject.filterVar
        );
        this.dataSubtitle += value.Value + ", ";
      });
      this.dataSubtitle = this.dataSubtitle.substring(
        0,
        this.dataSubtitle.lastIndexOf(",")
      );
      //var value = this.FilterVarsValues.find(x=> x.Code == this.surveyDataRequestBodyObject.filterVarValue &&  x.VariableName==this.surveyDataRequestBodyObject.filterVar)
    }
    if (this.surveyDataRequestBodyObject.filterVar2) {
      var element = this.FilterVarsValues.find(
        (x) => x.VariableName == this.surveyDataRequestBodyObject.filterVar2
      );
      // var value = this.FilterVarsValues.find(x=> x.Code == this.surveyDataRequestBodyObject.filterVarValue2 &&  x.VariableName==this.surveyDataRequestBodyObject.filterVar2)
      this.dataSubtitle += ", " + element.VarLabel + " is ";
      this.surveyDataRequestBodyObject.filterVarValue2.forEach((ele) => {
        var value = this.FilterVarsValues.find(
          (x) =>
            x.Code == ele &&
            x.VariableName == this.surveyDataRequestBodyObject.filterVar2
        );
        this.dataSubtitle += value.Value + ", ";
      });
      this.dataSubtitle = this.dataSubtitle.substring(
        0,
        this.dataSubtitle.lastIndexOf(",")
      );
    }*/
  }

  async receiveTableData($event: TableDataForChart): Promise<void> {
    if ($event.tableData != null && $event.tableData.length > 0) {
      this.tableData = $event.tableData;
      this.tableHeaderRow = $event.tableHeaderRow;
      this.createSubtitle(); 
      $event.dataSubtitle= this.dataSubtitle;
      this.dataTitle = this.dataSource.chart.caption = $event.dataTitle;
      this.dataSubtitle = this.dataSource.chart.subCaption = $event.dataSubtitle;
     
      // Only compile chart data if tableHeaderRow has length - otherwise no data
      if (this.tableHeaderRow.length > 0) {
        if (this.chartTypeForQuery === 'pchart') {
          this.createOriginalPieChart();
        } else if (this.chartTypeForQuery === 'map') {
          this.createOriginalMap();
        }
        else if (this.requestBody.year === 'multi') {
          this.createOriginalMultiyearChart();
        } else {
          this.createOriginalChart();
        }
        // Using setChartData method - is more reliable than just updating this.dataSource
        this.chart.setChartData(this.dataSource);
      } else {
        this.showChart = false;
      }
      if (!this.firstChart && this.showChart) {
        this.chartProcess = 'newOrFiltered';
        await this.setListenerFlags();
        // If chart type needs changed back to default, change chartType and then chart size will be checked from renderComplete (Any filtered data goes back to original chartType)
        if (this.needNewChartType) {
          this.chart.chartType(this.defaultType);
          this.needNewChartType = false;
        } else if (this.needNewChartSize || this.needRotateValues) {
          // Change chart size or rotate values if needed
          this.handleChartChanges();
        } else {
          this.loading = false;
        }
      } else if (!this.firstChart && !this.showChart) {
        this.handleNoData();
      } else {
        this.loading = false;
        this.firstChart = false;
      }
    } else {
      this.handleNoData();
      // this.showChart = false;
      // this.loading = false;
    }
  }

  clearChartData(): void {
    this.hideMap = false;
    this.dataSource.categories = [];
    this.dataSource.data = [];
    this.dataSource.dataset = [];
    this.chart.setChartData(this.dataSource);
  }

  // Go through data received and construct objects for chart
  createOriginalChart(): void {
    const { allHouseholds, colVarValue, rowvarsValues } = this.requestMetadata;
    const yValueIndexes = this.findYValueIndexes();
    // First item in data array holds row header and can be deleted
    this.tableData[1].shift();
    // All Households data is in the first array and the remaining data in a second. If All Household data is requested for the chart, combine the array.
    this.chartData = allHouseholds
      ? this.tableData[0].concat(this.tableData[1])
      : this.tableData[1];
    let currentCategory = "";
    this.originalDataset = [];
    this.originalCategories = [
      {
        category: [],
      },
    ];
    this.dataSource.categories = [];
    this.dataSource.dataset = [];
    // Get seriesnames from table construction
    this.tableHeaderRow.map((header, i) => {
      if (colVarValue.includes(header.colheader)) {
        this.originalDataset.push({ seriesname: header.colheader, data: [] })
      }
    });
    const { category } = this.originalCategories[0];

    this.showChart = false;

    // Any missing records were handled in the table build, so data can be added to the chart in order
    this.chartData.forEach((itemArr) => {
      // Check if rowheader is part of filters
      if (rowvarsValues.includes(itemArr[0]) || itemArr[0] === 'All Households') {
        // Check if label is new. If so, push to categories and start new dataset array.
        if (itemArr[0] !== currentCategory) {
          category.push({ label: itemArr[0] });
          currentCategory = itemArr[0];
          // dataIndex = 0;
        }
        // Go through each element in array, starting at 3, which is where chart data starts
        for (let i = 3; i < itemArr.length; i++) {
          // Check if item should be shown
          if (yValueIndexes.includes(i)) {
            // Get index of yValueIndexes
            const datasetIndex = yValueIndexes.findIndex((rec) => rec === i);
            // Check if item is an object
            if (typeof itemArr[i] === 'object') {
              this.showChart = true;
              const valueObj: ChartDatasetValue = {
                value: itemArr[i].percent,
              }
              // If chart is stacked and value below 2.5%, put a background color behind the value
              if (this.chartTypeForQuery === 'sbar' && itemArr[i].percent <= 2.5) {
                valueObj.valueBgColor = this.paletteColors[i - 3];
              }
              this.originalDataset[datasetIndex].data.push(valueObj);
            } else {
              // Items that are NA or - will just be strings
              if (itemArr[i] === 'NA') {
                this.originalDataset[datasetIndex].data.push({ value: "0", displayValue: 'NA' });
              } else if (itemArr[i] === '-') {
                this.originalDataset[datasetIndex].data.push({ value: "0", displayValue: '-' });
              }
            }
          }
        }
      }
    });
    if (this.showChart) {
      this.dataSource.categories = this.originalCategories;
      this.dataSource.dataset = this.originalDataset;
    }
  }

  createOriginalPieChart(): void {
    this.showChart = false;
    // Any missing records were handled in the table build, so data can be added to the chart in order
    this.dataSource.data = this.tableData[0].map((item) => {
      // Make sure that item surpasses supression count or make percent NA
      if (item.dcount < this.dataTable.supressioncount) {
        item.percent = "NA";
      } else {
        this.showChart = true;
      }
      const dataObj: PieChartData = {
        label: item.colheader,
        value: item.percent
      }
      if (item.percent === 'NA' || item.percent === '-') {
        dataObj.value = "0",
          dataObj.displayValue = item.percent
      } else if (item.percent === 0) {
        dataObj.value = "0",
          dataObj.displayValue = '-'
      };
      return dataObj;
    })
    // If only one record, change text to white
    if (this.tableData[0].length === 1) {
      this.dataSource.chart.valueFontColor = 'ffffff'
    } else {
      this.dataSource.chart.valueFontColor = '#0C4D81'
    }
  }

  // Function will create chart object and color range in one loop
  createOriginalMap(): void {
    const { colVarValue } = this.requestMetadata;
    this.showChart = false;
    const filteredData: MapChartData[] = [];
    // const colors: MapColor = [];
    let legendMinimumValue = 100;
    let legendMaximumValue = 0;
    // Loop through all states
    // tabledata now contains regular table data from fixed table, the first 3 indexes contain metadata
    // starting at tabledata[1][3] are arrays which contain all the data for the row/col

    this.tableData[1].forEach((item) => {
      //if (item.code !== undefined) {
      //find the correct array that contains the data we want to look at 
      var j= 3;
      for(var k=3; k< item.length;k++)
      {
        if (colVarValue.includes(item[k].colheader))
        {
          j=k;
          break;
        }
      }
      if (item[j] !== undefined && item[0]!= 'state' && item[j] !== 'NA') {
        // Check if item should be shown
        // const id = this.statedata.find((state) => state.Code == item.code).Value;
        // const id = this.statedata.find((state) => state.Code == item[3].code).Value;
        const id = item[0];
        if (colVarValue.includes(item[j].colheader)) {
          const dataObj: MapChartData = {
            id,
            value: item[j].percent.toFixed(1),
            fontColor: "#000000"
          }
          // Make sure that item surpasses supression count or make percent NA
          if (item[j].dcount > this.dataTable.supressioncount) {
            this.showChart = true;
            if (legendMinimumValue > Number(item[j].percent.toFixed(1))) {
              legendMinimumValue = Number(item[j].percent.toFixed(1));
            }
            if (legendMaximumValue < Number(item[j].percent.toFixed(1))) {
              legendMaximumValue = Number(item[j].percent.toFixed(1));
            }
          } else {

            dataObj.value = '';
            dataObj.toolText = `${id}, NA`;
          }
          filteredData.push(dataObj);
        }
      }
      else if (item[0]!= 'state' && item[j] == 'NA')
      {
        const id = item[0];
        const dataObj: MapChartData = {
          id,
          value: '0',
          fontColor: "#000000"
        }
        dataObj.value = '';
            dataObj.toolText = `${id}, NA`;
            filteredData.push(dataObj);
      }
      if (item[0]!= 'state' && item[j] == '-')
        {
          const id = item[0];
          const dataObj: MapChartData = {
            id,
            value: '0',
            fontColor: "#000000"
          }
          dataObj.value = '';
              dataObj.toolText = `${id}, -`;
              filteredData.push(dataObj);
        }
       
    })
    this.createMapColorRange(legendMaximumValue, legendMinimumValue);
    if (this.showChart) {
      this.dataSource.data = filteredData;
    }
  }

  createOriginalMultiyearChart(): void {
    this.originalDataset = [];
    this.originalCategories = [
      {
        category: [],
      },
    ];
    this.dataSource.categories = [];
    this.dataSource.dataset = [];

    const { colVarValue } = this.requestMetadata;
    // Multiyear data comes in three arrays
    const { category } = this.originalCategories[0];
    this.showChart = false;
    // Multiyear does years in reverse order
    for (let i = this.tableData.length - 1; i >= 0; i--) {
       //add missing data
       if(this.tableData[i][0].length<=colVarValue.length)
       {
         for(let c=0 ; c< colVarValue.length; c++)
         {
          if( this.tableData[i][0].find(x=> x.colheader == colVarValue[c]) == undefined)
          {
            this.tableData[i][0].push( { colheader: colVarValue[c], dcount:0, percent:0, isNA:false, code: c+1,  year:this.years[i]})
          } 
         
         } 
         this.tableData[i][0].sort((a, b) => (a.code > b.code) ? 1 : -1);
       }
      const currentYearArray = this.tableData[i][0];
      category.push({ label: currentYearArray[0].year.toString() });
      // Tracker for which array to use in originalDataset
      let dataIndex = 0;
      for (let j = 0; j < currentYearArray.length; j++) {
        const item = currentYearArray[j];
        // Make sure item should be displayed
        if (colVarValue.includes(item.colheader)) {
          // For initial items, create dataset
          if (i === this.tableData.length - 1) {
            this.originalDataset.push({ seriesname: item.colheader, data: [] })
          }
          // Check if data exists to display
          if (this.showChart === false && !item.isNA) {
            this.showChart = true;
          }
          // Make sure that item surpasses supression count or make percent NA
          if (item.dcount < this.dataTable.supressioncount) {
            item.percent = "NA";
          }
          // NA values need to be added as displayValue
          if (item.percent === 'NA' || item.percent === '-') {
            this.originalDataset[dataIndex].data.push({ value: "0", displayValue: item.percent });
          } else if (item.percent === 0) {
            this.originalDataset[dataIndex].data.push({ value: "0", displayValue: '-' });
          } else {
            const valueObj: ChartDatasetValue = {
              value: item.percent,
            }
            // If chart is stacked and value below 2.5%, put a background color behind the value
            if (this.chartTypeForQuery === 'sbar' && item.percent <= 2.5) {
              valueObj.valueBgColor = this.paletteColors[j];
            }
            this.originalDataset[dataIndex].data.push(valueObj);
          }
          dataIndex += 1;
        }
      }
    }
    this.dataSource.categories = this.originalCategories;
    this.dataSource.dataset = this.originalDataset;
  }

  findYValueIndexes(): number[] {
    const { colVarValue } = this.requestMetadata;
    // Push index number from All Households data that match requested columns. Index is +3 to match array structure
    const filteredIndexes: number[] = [];
    const allHouseholdsObjs: any[] = this.tableData[0][0].slice(3);
    allHouseholdsObjs.map((item, i) => {
      if (colVarValue.includes(item.colheader)) {
        filteredIndexes.push(i + 3);
      }
    });
    return filteredIndexes;
  }

  async setListenerFlags(): Promise<void> {
    if (this.chartTypeForQuery !== 'map') {
      const currentChartType = await this.chart.chartType();
      if (this.defaultType !== currentChartType) {
        this.needNewChartType = true;
        this.needNewChartConfig = true;
      }
      this.newChartSize = this.calculateChartSize();
      if (this.newChartSize !== this.previousChartSize) {
        this.needNewChartSize = true;
      }
      // See if data values need rotated for bar chart
      if (this.chartTypeForQuery === 'vbar' || this.chartTypeForQuery === 'hbar') {
        this.calculateRotateText();
      }
      this.newLegendNumColumns = this.calculateLegendColumns();
      if (this.newLegendNumColumns !== this.previousLegendNumColumns) {
        this.needNewLegendConfig = true;
      }
    } else if (this.chartTypeForQuery === 'map' && this.previousChartSize === 'hidden') {
      // If chart is a map, and the chart was previously hidden, set chart size back for map
      this.chart.resizeTo('900', '650');
    }
  }

  calculateChartSize(chartType: string = this.chartTypeForQuery): ChartSize {
    let totalItems = 0;
    switch (chartType) {
      case 'vbar':
      case 'hbar':
        totalItems = this.dataSource.categories[0].category.length * this.dataSource.dataset.length;
        // Check number of items for x & y axis to determine chart size
        if (totalItems <= 15) {
          return 'small';
        } else if (totalItems > 15 && totalItems < 21) {
          return 'medium';
        } else {
          return 'large';
        }
      case 'sbar':
        totalItems = this.dataSource.dataset.length;
        if (totalItems <= 6) {
          return 'small';
        } else {
          return 'medium';
        }
      case 'pchart':
        totalItems = this.dataSource.data.length;
        if (totalItems <= 2) {
          return 'small';
        } else if (totalItems > 2 && totalItems < 5) {
          return 'medium';
        } else if (totalItems >= 5 && totalItems < 7) {
          return 'large';
        } else {
          return 'xlarge';
        }
      default:
        return 'small';
    }
  }

  setChartSize(chartSize: ChartSize, chartType: ChartFormat = this.defaultType): void {
    this.previousChartSize = chartSize;
    switch (chartType) {
      case 'mscolumn2d':
        if (chartSize === 'small') {
          this.chart.resizeTo('900', '650');
        } else if (chartSize === 'medium') {
          this.chart.resizeTo('1100', '650');
        } else if (chartSize === 'large') {
          this.chart.resizeTo('1100', '650');
        }
        break;
      case 'msbar2d':
        if (chartSize === 'small') {
          this.chart.resizeTo('900', '650');
        } else if (chartSize === 'medium') {
          this.chart.resizeTo('900', '850');
        } else if (chartSize === 'large') {
          this.chart.resizeTo('900', '1100');
        }
        break;
      case 'stackedcolumn2d':
        if (chartSize === 'small') {
          this.chart.resizeTo('900', '650');
        } else if (chartSize === 'medium') {
          this.chart.resizeTo('900', '850');
        }
        break;
      case 'stackedbar2d':
        if (chartSize === 'small') {
          this.chart.resizeTo('900', '650');
        } else if (chartSize === 'medium') {
          this.chart.resizeTo('1100', '650');
        }
        break;
      case 'pie2d':
        if (chartSize === 'small') {
          this.chart.resizeTo('900', '600');
        } else if (chartSize === 'medium') {
          this.chart.resizeTo('900', '625');
        } else if (chartSize === 'large') {
          this.chart.resizeTo('900', '650');
        } else if (chartSize === 'xlarge') {
          this.chart.resizeTo('900', '675');
        }
        break;
      default:
        break;
    }
  }

  // Rotate value will always be 0 after Update Chart is clicked. See if it needs changed to 1. 
  calculateRotateText(): void {
    if (this.newChartSize === 'large') {
      if (this.dataSource.categories[0].category.length * this.dataSource.dataset.length >= 30) {
        this.rotateValues = true;
        this.needRotateValues = true;
      }
    }
  }

  setChartConfig(chartConfigType: ChartFormat): void {
    switch (chartConfigType) {
      case 'mscolumn2d':
        const vbarConfigCopy = Object.assign({}, vbarChartConfig);
        vbarConfigCopy.caption = this.dataTitle;
        vbarConfigCopy.subCaption = this.dataSubtitle;
        vbarConfigCopy.legendNumColumns = this.newLegendNumColumns;
        vbarConfigCopy.rotateValues = this.rotateValues ? '1' : '0';
        this.chart.setChartAttribute(vbarConfigCopy);
        break;
      case 'msbar2d':
        const hbarConfigCopy = Object.assign({}, hbarChartConfig);
        hbarConfigCopy.caption = this.dataTitle;
        hbarConfigCopy.subCaption = this.dataSubtitle;
        hbarConfigCopy.legendNumColumns = this.newLegendNumColumns;
        hbarConfigCopy.rotateValues = this.rotateValues ? '1' : '0';
        this.chart.setChartAttribute(hbarConfigCopy);
        break;
      case 'stackedcolumn2d':
        sbarVertChartConfig.caption = this.dataTitle;
        sbarVertChartConfig.subCaption = this.dataSubtitle;
        sbarVertChartConfig.legendNumColumns = this.newLegendNumColumns;
        this.chart.setChartAttribute(sbarVertChartConfig);
        break;
      case 'stackedbar2d':
        sbarHorChartConfig.caption = this.dataTitle;
        sbarHorChartConfig.subCaption = this.dataSubtitle;
        sbarHorChartConfig.legendNumColumns = this.newLegendNumColumns;
        this.chart.setChartAttribute(sbarHorChartConfig);
        break;
      default:
        break;
    }
  }

  // Set how max column width of legend based on character length of legend and chart size
  calculateLegendColumns(chartType: ChartFormat = this.defaultType): string {
    let legendCharLength = 0;
    switch (chartType) {
      case 'mscolumn2d':
      case 'stackedbar2d':
        legendCharLength = this.dataSource.dataset.map((item) => item.seriesname).join().length;
        if (this.newChartSize === 'small') {
          if (legendCharLength < 90) {
            return '4';
          }
          if (legendCharLength >= 90 && legendCharLength < 100) {
            return '3';
          }
          if (legendCharLength >= 100) {
            return '2';
          }
        }
        if (this.newChartSize === 'medium') {
          if (legendCharLength < 90) {
            return '5';
          }
          if (legendCharLength >= 90 && legendCharLength < 130) {
            return '4';
          }
          if (legendCharLength >= 130) {
            return '3';
          }
        }
        if (this.newChartSize === 'large') {
          if (legendCharLength < 100) {
            return '6';
          }
          if (legendCharLength >= 100 && legendCharLength < 150) {
            return '5';
          }
          if (legendCharLength >= 150 && legendCharLength < 200) {
            return '4';
          }
          if (legendCharLength >= 200) {
            return '3';
          }
        }
      case 'msbar2d':
      case 'stackedcolumn2d':
      case 'pie2d':
        legendCharLength = chartType === 'pie2d'
          ? this.dataSource.data.map((item: PieChartData) => item.label).join().length
          : this.dataSource.dataset.map((item) => item.seriesname).join().length;
        // Chart width doesn't change, so charLength is only factor
        if (legendCharLength < 90) {
          return '4';
        }
        if (legendCharLength >= 95 && legendCharLength < 100) {
          return '3';
        }
        if (legendCharLength >= 100) {
          return '2';
        }
      default:
        return '4';
    }
  }

  createMapColorRange(legendMaximumValue: number, legendMinimumValue: number): void {
    // Get maxiumum and minimum values and configure the colors accordingly
    const valueIncrement = (legendMaximumValue - legendMinimumValue) / 4;
    let currentValue = legendMinimumValue;
    const chartGradientColors = [
      '#8098AA',
      '#406580',
      '#244f6e',
      '#003256'
    ];
    this.dataSource.colorrange = {
      code: "#BFCCD5",
      gradient: "1",
      color: []
    };

    const gradientColorCount = legendMinimumValue === legendMaximumValue ? 0 : 3;
    for (let i = 0; i <= gradientColorCount; i++) {
      currentValue += valueIncrement;
      this.dataSource.colorrange.color.push({
        maxvalue: currentValue.toFixed(1),
        code: chartGradientColors[i],
      })
    }
  }

  async regroupData(): Promise<void> {
    this.chartProcess = 'regroup';
    this.needNewChartType = false;
    this.needNewChartSize = false;
    this.needNewChartConfig = false;
    this.regrouped = !this.regrouped;
    if (this.regrouped) {
      // Categories and dataset need restructured
      const newCategories = this.originalDataset.map((item) => ({
        label: item.seriesname,
      }));
      const newSeriesData = this.originalDataset.map((item) => item.data);
      const newSeriesnames = this.originalCategories[0].category.map(
        (item) => item.label
      );
      this.regroupedCategories = [{ category: newCategories }];
      this.regroupedDataset = newSeriesnames.map((item, i) => {
        const dataArr = newSeriesData.map((data) => data[i]);
        return {
          seriesname: item,
          data: dataArr,
        };
      });
      this.dataSource.categories = this.regroupedCategories;
      this.dataSource.dataset = this.regroupedDataset;
    } else {
      this.dataSource.categories = this.originalCategories;
      this.dataSource.dataset = this.originalDataset;
    }
    if (this.rotateValues) {
      this.needRotateValues = true;
    }
    this.newLegendNumColumns = this.calculateLegendColumns();
    if (this.newLegendNumColumns !== this.previousLegendNumColumns) {
      this.needNewLegendConfig = true;
    }
    const chartAttributes = await this.chart.getChartAttribute();
    this.dataSource.chart = chartAttributes;
  }

  async receiveRotateEvent(): Promise<void> {
    this.chartProcess = 'rotate';
    const currentChartType: ChartFormat = await this.chart.chartType();
    switch (currentChartType) {
      case 'mscolumn2d':
        this.rotateChartType = 'msbar2d';
        break;
      case 'msbar2d':
        this.rotateChartType = 'mscolumn2d';
        break;
      case 'stackedcolumn2d':
        this.rotateChartType = 'stackedbar2d';
        break;
      case 'stackedbar2d':
        this.rotateChartType = 'stackedcolumn2d';
        break;
      default:
        return;
    }
    this.loading = true;
    this.needNewChartConfig = true;
    // Check if new chart size is needed before starting process - small won't need one
    this.needNewChartSize = this.previousChartSize === 'small' ? false : true;
    this.newLegendNumColumns = this.calculateLegendColumns(this.rotateChartType);
    if (this.newLegendNumColumns !== this.previousLegendNumColumns) {
      this.needNewLegendConfig = true;
    }
    // Set chart type, then let renderedComplete handle if new chart size is needed
    this.chart.chartType(this.rotateChartType);
  }

  async receiveExportEvent(exportFormat: ExportFormat): Promise<void> {
    this.exportFormat = exportFormat;
    // Prepare data for chartExport element - a new element is created each time an export is requested - was not handling multiple exports otherwise
    // Duplicate chart data and use that for export
    this.dataSourceShadow = JSON.parse(JSON.stringify(this.dataSource));

    // Footnotes have to be handled as annotations to export the chart. Margin is added to the bottom of the chart based on length of footnotes, and footnotes are positioned using $chartEndY so that they are always on the bottom of the table.
    // Combine footnotes and compile into one text item with {br} to separate notes
    const bullet = "•"
    let footnoteString = `${bullet} ${this.dataTable.footnotes.join(`{br}{br}${bullet} `)}`;

    this.dataSourceShadow.annotations = {
      "groups": [{
        "id": "footnote",
        "font": "Helvetica,Arial,sans-serif",
        "fontSize": "10",
        "color": "#212529",
        "fillcolor": "#000000",
        "items": [
          {
            "type": "text",
            "wrap": "1",
            "text": footnoteString,
            "x": "50",
            "y": "$chartEndY-10",
            "align": "left",
            "vAlign": "top",
            "wrapWidth": "850",
          }
        ]
      }]
    };

    // Get current chart configuration
    // Update export element configs
    this.chartTypeShadow = await this.chart.chartType();
    const chartAttributes = await this.chart.getChartAttribute();
    const newChartObj = Object.assign({}, chartAttributes);



    newChartObj.baseFontSize = '13';
    newChartObj.captionFontSize = '1.125rem';
    newChartObj.subcaptionFontSize = '1rem';
    newChartObj.valueFontsize = '13';

    if (this.chartTypeShadow === 'mscolumn2d') {
        newChartObj.canvastoppadding = "40";
        if (this.newChartSize === 'large') {
          newChartObj.legendPosition ='bottom';
          newChartObj.legendNumRows = '4';
          newChartObj.legendIconScale = '1.5';
          }
    }
    if (this.chartTypeShadow === 'stackedcolumn2d' || this.chartTypeShadow === 'stackedbar2d') {
      newChartObj.plotspacepercent = (100 - (this.dataSourceShadow.categories[0].category.length * 8)).toString();
    }
    if (this.chartTypeShadow === 'msbar2d') {
      newChartObj.canvasrightpadding = "120"
      if (this.newChartSize === 'large') {
        newChartObj.legendPosition ='bottom';
        newChartObj.legendNumRows = '6';
        newChartObj.legendNumColumns = '2';

        }
    }
    if (this.chartTypeShadow === 'pie2d') {
      newChartObj.canvastoppadding = "40";
      newChartObj.legendPosition ='bottom';
      newChartObj.legendNumRows = '6';
      newChartObj.legendNumColumns = '2';
      newChartObj.legendIconScale = '1.5';
    }

    this.dataSourceShadow.chart = newChartObj;

    // Chart type, width and height are set on initialization. chartBottomMargin is set with chart config obj
    const chartBottomMargin = await this.calculateExportConfig(footnoteString.length);
    this.dataSourceShadow.chart.chartBottomMargin = chartBottomMargin;

    // Once exporting is true, element will be added to DOM and initializeShadow will trigger
    this.exporting = true;
  }

  async calculateExportConfig(footnoteLength: number): Promise<string> {
    const { height, width } = await this.chart.resizeTo();

      if(this.chartTypeShadow === 'stackedbar2d' || this.chartTypeShadow === 'stackedcolumn2d'  && this.dataSource.dataset.length >= 7) {
        this.chartWidthShadow = (parseInt(width) * 1.5).toString();
        this.chartHeightShadow = (parseInt(height) * 1.5).toString();   

        let baseBottomMargin = 15;
        baseBottomMargin += Math.floor(footnoteLength / 100) * 10;
        return baseBottomMargin.toString();
      }
      else if(this.chartTypeShadow === 'msbar2d' || this.chartTypeShadow === 'mscolumn2d' && this.dataSource.dataset.length >= 7){
        this.chartWidthShadow = (parseInt(width) * 1.3).toString();
        this.chartHeightShadow = (parseInt(height) * 1.3).toString();     

        let baseBottomMargin = 15;
        baseBottomMargin += Math.floor(footnoteLength / 100) * 10;
        return baseBottomMargin.toString();
      }else if(this.chartTypeShadow === 'pie2d' && this.previousChartSize == 'xlarge'){
        this.chartWidthShadow = (parseInt(width) * 1.3).toString();
        this.chartHeightShadow = (parseInt(height) * 1.3).toString();     

        let baseBottomMargin = 60;
        baseBottomMargin += Math.floor(footnoteLength / 100) * 10;
        return baseBottomMargin.toString();
      }
      else{
        this.chartWidthShadow = (parseInt(width) * 1).toString();
        this.chartHeightShadow = (parseInt(height) * 1).toString();
        // For every 100 characters in the footnotes, increase margin and y position by 10 
        let baseBottomMargin = 60;
        baseBottomMargin += Math.floor(footnoteLength / 100) * 10;
        return baseBottomMargin.toString();
      }
  }
}