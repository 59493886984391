import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartFilter } from '../models/chartTool.model';
import { CustomDataModel } from '../models/CustomDataModel';
import { ConstructMultiyearTableResult, ConstructTableResult } from '../models/dataTable.model';
import { SurveyDataRequestBody } from '../models/surveyData.model';
import { ApiService } from './api.service';



@Injectable({
  providedIn: 'root'
})
export class DataTableService implements OnInit {

  TableOrganized: any[][] = [];

  Multiyeartableorganize: any[][][] = [];

  Multiyear: boolean = false;

  //supression count is the amount of dcount necessary to be displayed. if dcount < supression then it is NA instead.
  supressioncount: number = 100;
  unsupressedunkown :number = 98;

  dataHasNA: boolean = false;

  dataHasDash: boolean = false;

  chart: boolean = false;

  chartFilterObj: ChartFilter;
  Api: ApiService;
  filtervarvalues: CustomDataModel[];

  years: string[] = [];
  SubtitleData: any[];

  constructor(private apiService: ApiService, private router: Router) { this.Api = apiService; }

  async ngOnInit(): Promise<void> {
    const validUser = await this.checkValidUser();
    this.GetDataForSubTitle();
    if (!validUser) {
      this.router.navigate(['/unauthorized']);
    } else {
      await this.GetYearsFromDb();
      this.Api.getFilterData().then((y) => {
        this.filtervarvalues = y;
      });
    }
  }
  GetDataForSubTitle() {
    this.Api.getDataForSubTitle().then((x) => (this.SubtitleData = x));
  }
  // If a user with a non-FDIC IP address tries to access a lower environment, it should be refused
  async checkValidUser(): Promise<boolean> {
    const currentUrl = window.location.href;
    if (currentUrl.includes('survey-d') || currentUrl.includes('survey-s') || currentUrl.includes('survey-q')) {
      const validIp = await this.Api.getUserIp() as unknown as boolean;
      return validIp;
    }
    return true;
  }

  async GetYearsFromDb() {
    this.years = await this.apiService.getSurveyYears();
  }

  GetYears() {
    return this.years;
  }

  organizetable(TableData: any, requestBody: SurveyDataRequestBody, chartFilterObj?: ChartFilter): ConstructTableResult {
    if (chartFilterObj !== undefined) {
      this.chart = true;
      this.chartFilterObj = chartFilterObj;
    }
    this.Multiyear = false;
    this.TableOrganized = [];
    this.TableOrganized[0] = [];
    //if else no longer needed
   // if (this.chartFilterObj !== undefined && this.chartFilterObj.chartType === 'map') {
    if (this.chartFilterObj !== undefined && this.chartFilterObj.chartType === 'oldmap') {
      this.organizetableForMap(TableData, requestBody);
    } else {
      TableData.forEach(element => {
        //check against the row vars that were selected via filter or default. Will also handle the removal or addition of rows by default. Each distinct row var has its own array.
        // Checking for requestBody.rowvars in case they are missing (pie chart, for example)
        var rowindex = requestBody.rowvars === undefined ? -1 : requestBody.rowvars.indexOf(element.cde_desc);
        if (rowindex != -1) {
          if (this.TableOrganized[rowindex + 1] == undefined) {

            this.TableOrganized[rowindex + 1] = [];
          }
          this.TableOrganized[rowindex + 1].push(element);
        }
        // -1 is for All Households data
        else {
          this.TableOrganized[0].push(element);
        }
        for (var i = 0; i < this.TableOrganized.length; i++) {
          if (this.TableOrganized[i] == undefined) {
            continue;
          }
          this.TableOrganized[i].sort(function (a, b) {
            if (+a.code === +b.code) {
              // Price is only important when cities are the same
              return 0 - (+a.cde > +b.cde ? -1 : 1);
            }
            return 0 - (+a.code > +b.code ? -1 : 1);
          });
        }
      });
    }
    if(requestBody.rowvars!= null && requestBody.rowvars.indexOf("gestfips")!= -1)
    {
      this.CorrectStatecodes(requestBody.rowvars.indexOf("gestfips")+1);
    }
    //if (this.chartFilterObj !== undefined && (this.chartFilterObj.chartType === 'pchart' || this.chartFilterObj.chartType === 'map')) {
      if (this.chartFilterObj !== undefined && (this.chartFilterObj.chartType === 'pchart')) {
      return this.constructDataTableSingleRow();
    //} else if (this.chartFilterObj !== undefined && (this.chartFilterObj.chartType !== 'pchart' && this.chartFilterObj.chartType !== 'map')) {
    }else if (this.chartFilterObj !== undefined && (this.chartFilterObj.chartType == 'map' )) {
      return this.createFixedTableForChart(requestBody, chartFilterObj);
      //return this.Createfixedtable(requestBody,chartFilterObj);

    } 
    else if (this.chartFilterObj !== undefined && (this.chartFilterObj.chartType !== 'pchart' ) && (this.chartFilterObj.chartType !== 'map' )) {
      //doesnt seem to be any functional difference between fixed tables for charts and regular table.
      //return this.createFixedTableForChart(requestBody, chartFilterObj);
      return this.Createfixedtable(requestBody,chartFilterObj);

    }
    return this.Createfixedtable(requestBody);
  }

  //multiyear table [year][rowvar][value] everything should be the same as regular table just done 3 times.
  organizemultiyeartable(TableData: any, requestBody: SurveyDataRequestBody) {
    this.Multiyear = true;
    const yeararray = [this.years[0], this.years[1], this.years[2]];
    this.Multiyeartableorganize[0] = [];
    this.Multiyeartableorganize[1] = [];
    this.Multiyeartableorganize[2] = [];
    this.Multiyeartableorganize[0][0] = [];
    this.Multiyeartableorganize[1][0] = [];
    this.Multiyeartableorganize[2][0] = [];
    TableData.forEach(element => {
      //check against the row vars that were selected via filter or default. Will also handle the removal or addition of rows by default.
      var rowindex = requestBody.rowvars.indexOf(element.cde_desc);
      var yearindex = yeararray.indexOf(element.year.toString());
      if (rowindex != -1) {
        if (this.Multiyeartableorganize[yearindex][rowindex + 1] == undefined) {
          this.Multiyeartableorganize[yearindex][rowindex + 1] = [];
        }
        this.Multiyeartableorganize[yearindex][rowindex + 1].push(element);
      }
      else {
        this.Multiyeartableorganize[yearindex][0].push(element);
      }
    });
    for (var m = 0; m < 3; m++) {
      for (var i = 0; i < this.Multiyeartableorganize[m].length; i++) {
        if (this.Multiyeartableorganize[m][i] == undefined) {
          continue;
        }
        this.Multiyeartableorganize[m][i].sort(function (a, b) {
          if (a.code === b.code) {
            // Price is only important when cities are the same
            return 0 - (+a.cde > +b.cde ? -1 : 1);
          }
          return 0 - (a.code > b.code ? -1 : 1);
        });
      }
    }
    ;
    if(requestBody.rowvars != null && requestBody.rowvars.indexOf("gestfips")!= -1)
    {
      this.CorrectStatecodesMulti(requestBody.rowvars.indexOf("gestfips")+1);
    }
    return this.CreatefixedMultitable(requestBody)
  }
  CorrectStatecodesMulti(index:number)
  {
    var temp = this.filtervarvalues.filter(vr => vr.VariableName == "gestfips").sort( (a,b)=> +a.Code<+b.Code ? -1 : 1)
    var counter = 1;
    temp.forEach( x=> {x.Code = counter.toString(); counter++ });
    temp.forEach(x=> { this.filtervarvalues[this.filtervarvalues.findIndex(y=> (y.Value==x.Value && y.VariableName == x.VariableName))].Code= x.Code });

    for(var m=0; m<3;m++)
    {
    for(var j=0; j<this.Multiyeartableorganize[m][index].length; j++)
    {
      this.Multiyeartableorganize[m][index][j].code= +temp.find(x=> x.Value == this.Multiyeartableorganize[m][index][j].rowheader).Code;

      /*  if(this.Multiyeartableorganize[m][index][j].code >2 && this.Multiyeartableorganize[m][index][j].code<7)
        {
          this.Multiyeartableorganize[m][index][j].code= this.Multiyeartableorganize[m][index][j].code -1;
        }
        if(this.Multiyeartableorganize[m][index][j].code >7 && this.Multiyeartableorganize[m][index][j].code<14)
        {
          this.Multiyeartableorganize[m][index][j].code= this.Multiyeartableorganize[m][index][j].code -2;
        }
        if(this.Multiyeartableorganize[m][index][j].code >14 && this.Multiyeartableorganize[m][index][j].code<43)
        {
          this.Multiyeartableorganize[m][index][j].code= this.Multiyeartableorganize[m][index][j].code -3;
        }
        if(this.Multiyeartableorganize[m][index][j].code >43 && this.Multiyeartableorganize[m][index][j].code<52)
        {
          this.Multiyeartableorganize[m][index][j].code= this.Multiyeartableorganize[m][index][j].code -4;
        }
        if(this.Multiyeartableorganize[m][index][j].code >52 )
        {
          this.Multiyeartableorganize[m][index][j].code= this.Multiyeartableorganize[m][index][j].code -5;
        }*/
    }
  }

  }

  CorrectStatecodes(index: number)
  {
   var temp = this.filtervarvalues.filter(vr => vr.VariableName == "gestfips").sort( (a,b)=> +a.Code<+b.Code ? -1 : 1)
    var counter = 1;
    temp.forEach( x=> {x.Code = counter.toString(); counter++ });
    temp.forEach(x=> { this.filtervarvalues[this.filtervarvalues.findIndex(y=> (y.Value==x.Value && y.VariableName == x.VariableName))].Code= x.Code });

      for(var j=0; j<this.TableOrganized[index].length; j++)
      {
        this.TableOrganized[index][j].code= +temp.find(x=> x.Value == this.TableOrganized[index][j].rowheader).Code;
        /*  if(this.TableOrganized[index][j].code >2 && this.TableOrganized[index][j].code<7)
          {
            this.TableOrganized[index][j].code= this.TableOrganized[index][j].code -1;
          }
          if(this.TableOrganized[index][j].code >7 && this.TableOrganized[index][j].code<14)
          {
            this.TableOrganized[index][j].code= this.TableOrganized[index][j].code -2;
          }
          if(this.TableOrganized[index][j].code >14 && this.TableOrganized[index][j].code<43)
          {
            this.TableOrganized[index][j].code= this.TableOrganized[index][j].code -3;
          }
          if(this.TableOrganized[index][j].code >43 && this.TableOrganized[index][j].code<52)
          {
            this.TableOrganized[index][j].code= this.TableOrganized[index][j].code -4;
          }
          if(this.TableOrganized[index][j].code >52 )
          {
            this.TableOrganized[index][j].code= this.TableOrganized[index][j].code -5;
          }*/
      }
  }
  correctfiltervarstatecodes(requestBody: SurveyDataRequestBody, filternumber:number)
  { 
    if(filternumber==1)
    {
    for(var j=0; j<+requestBody.filterVarValue.length; j++)
    {
    if(+requestBody.filterVarValue[j] >2 && +requestBody.filterVarValue[j]<7)
    {
      requestBody.filterVarValue[j]= (+requestBody.filterVarValue[j] -1).toString();
    }
    if(+requestBody.filterVarValue[j] >7 && +requestBody.filterVarValue[j]<14)
    {
      requestBody.filterVarValue[j]= (+requestBody.filterVarValue[j] -2).toString();
    }
    if(+requestBody.filterVarValue[j] >14 && +requestBody.filterVarValue[j]<43)
    {
      requestBody.filterVarValue[j]= (+requestBody.filterVarValue[j] -3).toString();
    }
    if(+requestBody.filterVarValue[j] >43 && +requestBody.filterVarValue[j]<52)
    {
      requestBody.filterVarValue[j]= (+requestBody.filterVarValue[j] -4).toString();
    }
    if(+requestBody.filterVarValue[j] >52 )
    {
      requestBody.filterVarValue[j]= (+requestBody.filterVarValue[j] -5).toString();
    }
  }
}
else
  {
    for(var j=0; j<+requestBody.filterVarValue2.length; j++)
    {
    if(+requestBody.filterVarValue2[j] >2 && +requestBody.filterVarValue2[j]<7)
    {
      requestBody.filterVarValue2[j]= (+requestBody.filterVarValue2[j] -1).toString();
    }
    if(+requestBody.filterVarValue2[j] >7 && +requestBody.filterVarValue2[j]<14)
    {
      requestBody.filterVarValue2[j]= (+requestBody.filterVarValue2[j] -2).toString();
    }
    if(+requestBody.filterVarValue2[j] >14 && +requestBody.filterVarValue2[j]<43)
    {
      requestBody.filterVarValue2[j]= (+requestBody.filterVarValue2[j] -3).toString();
    }
    if(+requestBody.filterVarValue2[j] >43 && +requestBody.filterVarValue2[j]<52)
    {
      requestBody.filterVarValue2[j]= (+requestBody.filterVarValue2[j] -4).toString();
    }
    if(+requestBody.filterVarValue2[j] >52 )
    {
      requestBody.filterVarValue2[j]= (+requestBody.filterVarValue2[j] -5).toString();
    }
  }
}
  return requestBody;
  } 

  CreatefixedMultitable(requestBody: SurveyDataRequestBody): ConstructMultiyearTableResult {
    this.dataHasNA = false;
    this.dataHasDash = false;
    //get the number of subrowvars for the total number of rows the table should have. cde=column# code = row #
    let lengtharray: number[] = []
    const yeararray: string[] = [this.years[0], this.years[1], this.years[2]];
    let temparray: CustomDataModel[][] = []
    let counter = 0
    let allhouseholdsdcount: number[] = [0, 0, 0];

    if(this.Multiyeartableorganize==null || this.Multiyeartableorganize.length==0)
    {
      
      return {
        tablestring:"<tr> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" style=\"border-width:0;font-weight: bold\"> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </td> </tr>",
        yearHeaderString:null,
        multiHeaderRow:null,
      }
    }


    requestBody.rowvars.forEach((r) => {
      temparray.push(this.filtervarvalues.filter(vr => vr.VariableName == r && (+vr.Code <= this.unsupressedunkown && +vr.Code >= 0)));
      temparray[counter]= temparray[counter].map(s => Object.assign({}, s))

      temparray[counter].sort(function (a, b) {
         
        return 0 - (+a.Code > +b.Code ? -1 : 1);
      });
      lengtharray.push(temparray[counter].length);
      if (requestBody.filterVar != null) {
        if (requestBody.filterVar == r && requestBody.filterVarValue.length !=0 ) {
          if(r=="gestfips")
          {
            requestBody=this.correctfiltervarstatecodes(requestBody,1);
          }
          let temparray2:CustomDataModel[] =[];
        //loop through, if the value exists in the filter var value we need it so push it into the temparray2.
          temparray[counter].forEach(x=>
          {
          if((requestBody.filterVarValue as string[]).indexOf(x.Code) != -1)
            {
              temparray2.push(x)
            }
          });
          //renumber the codes otherwise the assignment wont work properly. may need to adjust this and asssignments in future.
          // if the real code is ever needed.
          let order=1;
          temparray2.forEach(x=> 
            {
              this.Multiyeartableorganize[0][counter+1].forEach(y=> {if(y.code == x.Code)   y.code= order;})
              this.Multiyeartableorganize[1][counter+1].forEach(y=> {if(y.code == x.Code)   y.code= order;})
              this.Multiyeartableorganize[2][counter+1].forEach(y=> {if(y.code == x.Code)   y.code= order;})

            x.Code = order.toString(); 
            
            order++;
            })
          temparray[counter]= temparray2.slice();
          lengtharray[counter] = requestBody.filterVarValue.length
         
        }
      }
      if (requestBody.filterVar2 != null) {
        if (requestBody.filterVar2 == r && requestBody.filterVarValue2.length !=0 ) {
          if(r=="gestfips")
          {
            requestBody=this.correctfiltervarstatecodes(requestBody,1);
          }
          let temparray2:CustomDataModel[] =[];
          //loop through, if the value exists in the filter var value we need it so push it into the temparray2.
            temparray[counter].forEach(x=>
            {
            if((requestBody.filterVarValue2 as string[]).indexOf(x.Code) != -1)
              {
                temparray2.push(JSON.parse(JSON.stringify(x)))
              }
            });
            //renumber the codes otherwise the assignment wont work properly. may need to adjust this and asssignments in future.
            // if the real code is ever needed.
            let order=1;
            temparray2.forEach(x=> {
              this.Multiyeartableorganize[0][counter+1].forEach(y=> {if(y.code == x.Code)   y.code= order;})
              this.Multiyeartableorganize[1][counter+1].forEach(y=> {if(y.code == x.Code)   y.code= order;})
              this.Multiyeartableorganize[2][counter+1].forEach(y=> {if(y.code == x.Code)   y.code= order;})

              x.Code = order.toString(); order++;})
            temparray[counter]= temparray2.slice();
            lengtharray[counter] = requestBody.filterVarValue2.length
           
        }
      }
      counter++
    })
    let fixedmultiyeartable: any[][][][] = [];
    let rowvarhasdata = false;

    for (var m = 0; m < 3; m++) {
      fixedmultiyeartable[m] = []
      fixedmultiyeartable[m][0] = []
      if(this.Multiyeartableorganize[m]==null || this.Multiyeartableorganize[m].length==0)
      {
        continue;
      }
      //rowvarlength
      for (var i = 0; i < requestBody.rowvars.length; i++) {
        fixedmultiyeartable[m][i + 1] = []
        //subrowvarlength
        for (var j = 0; j < lengtharray[i] + 1; j++) {
          fixedmultiyeartable[m][i + 1][j] = []
          //column length
          for (var k = 0; k < this.Multiyeartableorganize[m][0].length + 2; k++) {
            fixedmultiyeartable[m][i + 1][j].push("-")
          }
        }
      }

    }
    //assignment loop
    for (var m = 0; m < 3; m++) {
         //renumber the coulmns if it doesnt start at 1
 /*   if( this.Multiyeartableorganize[m][0][0].cde != 1 )
    {
     var diff = this.Multiyeartableorganize[m][0][0].cde -1; 
     this.Multiyeartableorganize[m].forEach( r => {
        r.forEach(cell => 
          {
            cell.cde -= diff;
          })
     })
    }*/
    if(this.Multiyeartableorganize[m]==null || this.Multiyeartableorganize[m][0].length==0)
    {
      continue;
    }
    var colmap: {[key: string]: number}={}
    var corder=1;
    this.Multiyeartableorganize[m][0].forEach(e => colmap[e.cde.toString()] = corder++ )
      for (var i = 1; i < this.Multiyeartableorganize[m].length; i++) {

        rowvarhasdata = false;
        if (this.Multiyeartableorganize[m][i] == null) {
          continue;
        }
        var diffc = 0; 
        var rowoffset=false;
        if(this.Multiyeartableorganize[m][i][0].code != 1)
        {
            //need to check the amount of unique codes vs how many rows we created
      // if they are equal then it starts from a different number. if not then data is just missing dont renumber
          var codes=  this.Multiyeartableorganize[m][i].map(item => item.code)
          .filter((value, index, self) => self.indexOf(value) === index)
          if(codes.length == temparray[i-1].length)
          {
            diffc = this.Multiyeartableorganize[m][i][0].code -1; 
            rowoffset = true;
          }
            
        }

        for (var j = 0; j < this.Multiyeartableorganize[m][i].length; j++) {

                //handle missing end coulmn
       /* if(this.Multiyeartableorganize[m][i][j].cde >this.Multiyeartableorganize[m][0].length )
        {
         // this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length-2].cde + 1
          this.Multiyeartableorganize[m][i][j].cde = this.Multiyeartableorganize[m][i][this.Multiyeartableorganize[m][0].length-2].cde + 1

        }
          if (this.Multiyeartableorganize[m][i][j].cde > 10) {
            this.Multiyeartableorganize[m][i][j].cde = this.Multiyeartableorganize[m][0][this.Multiyeartableorganize[m][0].length-2].cde + 1
          }*/
          this.Multiyeartableorganize[m][i][j].cde = colmap[this.Multiyeartableorganize[m][i][j].cde];
            //for now gestfips is the only one with more than 10 codes by default, probably neede to adjust this line for any future codes that contain more than 10
          if (this.Multiyeartableorganize[m][i][j].code > 10 && this.Multiyeartableorganize[m][i][j].cde_desc != "gestfips" ) {
            this.Multiyeartableorganize[m][i][j].code = this.Multiyeartableorganize[m][i][j - 1].code + 1
          }
          if(rowoffset)
          {
            this.Multiyeartableorganize[m][i][j].code -= diffc
          }
          try {
            if (this.Multiyeartableorganize[m][i][j].dcount >= this.supressioncount) {

              if (fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][this.Multiyeartableorganize[m][i][j].cde + 1] != null) {
                fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][this.Multiyeartableorganize[m][i][j].cde + 1] = this.Multiyeartableorganize[m][i][j];
                fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][0] = this.Multiyeartableorganize[m][i][j].total;
                fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][1] = 100;
                rowvarhasdata = true;
              }
            }
            else {
              if (fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][this.Multiyeartableorganize[m][i][j].cde + 1] != null) {
                fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][this.Multiyeartableorganize[m][i][j].cde + 1] = "NA";
              }
              if (fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][0] == "NA" || fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][0] == "-") {
                fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][0] = "NA";
              }
              fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][1] = 100;
            }
            if(fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][0] == "NA")
          {
            for(var c=2; c< fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code].length;c++)
            {
              fixedmultiyeartable[m][i][this.Multiyeartableorganize[m][i][j].code][c] = "NA";
            }
          } 

          }
          catch (e) {
            console.log("m:" + m + "i: " + i + "j: " + j);
            console.log(this.Multiyeartableorganize[m][i][j]);
            console.log(fixedmultiyeartable);
          }
        }

        // fixedmultiyeartable[m][i].splice(0,1);
      }

      //all households row
      if (fixedmultiyeartable[m][0][0] == null) {
        fixedmultiyeartable[m][0][0] = [];
      }
      
      if(this.Multiyeartableorganize[m][0][0].dcount< this.supressioncount)
      {
        fixedmultiyeartable[m][0][0].push("NA");
        fixedmultiyeartable[m][0][0].push("NA");
      }
      else
      {
      fixedmultiyeartable[m][0][0].push(this.Multiyeartableorganize[m][0][0].total);
      fixedmultiyeartable[m][0][0].push(100);
      }
      for (var n = 0; n < this.Multiyeartableorganize[m][0].length; n++) {
        if(this.Multiyeartableorganize[m][0][n].dcount< this.supressioncount)
        {
         //   fixedmultiyeartable[m][0][0].push("NA");
         this.Multiyeartableorganize[m][0][n].percent="NA";
        }
        
        fixedmultiyeartable[m][0][0].push(this.Multiyeartableorganize[m][0][n]);
        allhouseholdsdcount[m] = this.Multiyeartableorganize[m][0][n].dcount;
        


      }
      //end assignment loop
    }
    const multiHeaderRow: any[][] = [];
    var addRowHeaders = true;
    var baseRowHeaders = [
      { colheader: "Number of Households (1000s)" },
      { colheader: "Number of Households (PCT)" },
    ];
    var yearHeaderString = "<td></td>";
    for (m = 0; m < 3; m++) {
      if(this.Multiyeartableorganize[m]==null || this.Multiyeartableorganize[m][0].length==0)
      {
        continue;
      }
      yearHeaderString += '<td>' + yeararray[m] + '</td>';
      for (var col = 1; col < fixedmultiyeartable[m][0][0].length; col++) {
        yearHeaderString += '<td></td>';
      }
      multiHeaderRow[m] = [];
      multiHeaderRow[m] = baseRowHeaders.concat(fixedmultiyeartable[m][0][0].slice(2));// create header rows for each year


    }
    fixedmultiyeartable[0][0][0].unshift("All Households")
    for (var i = 0; i < requestBody.rowvars.length + 1; i++) {


      //subrowvarlength
      for (var j = 0; j < lengtharray[i]; j++) {
        //add the row header to the first year buy putting it at the front of the array.
        if (fixedmultiyeartable[0][i + 1][j + 1] == null) {
          continue;
        }
        fixedmultiyeartable[0][i + 1][j + 1].unshift(temparray[i][j].Value)
      }
      //add the row that contains just rowvar label by making a new row and putting just the value in it.
      if (i + 1 >= fixedmultiyeartable[0].length || fixedmultiyeartable[0] == null) {
        continue;
      }
      //fixedmultiyeartable[0][i+1].unshift([temparray[i][0].VarLabel])
      fixedmultiyeartable[0][i + 1][0] = [];
      fixedmultiyeartable[0][i + 1][0].push(temparray[i][0].VarLabel)

    }




    let tablestring = "";
    let tablestringarray: string[][] = [];

    //year, rowvar, subrowvar, column; construct the strings
    for (var m = 0; m < 3; m++) {
  
     
      for (var i = 0; i < fixedmultiyeartable[m].length; i++) {
        if (i >= 1 && m < 1) {

          if (tablestringarray[m] == null) {
            tablestringarray[m] = [];
          }
        
          //header row only one element happens once per i.
          tablestringarray[m].push("<tr id=\"" + fixedmultiyeartable[m][i][0][0] + "\" style=\"background:lightgrey\"> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" > <b>" + fixedmultiyeartable[m][i][0][0] + " (PCT) </b></td> </tr>")
        }
        if(this.Multiyeartableorganize[m]==null || this.Multiyeartableorganize[m][0].length==0)
        {
          if (tablestringarray[m] == null) {
            tablestringarray[m] = [];
          }
          for(var t=0; t<tablestringarray[0].length;t++)
          {
            tablestringarray[m][t]="";
          }
          break;
        }
        for (var j = 0; j < fixedmultiyeartable[m][i].length; j++) {
          //this row is used to store info in the other rows
          if (i > 0 && j == 0) {
            continue;
          }
          var totalperecent: number = 0;
          var aggregiatecols: string = "";
          var rowcells: string = "";
          //subrowheader
          if (m == 0) {
            aggregiatecols += "<tr " + ((j % 2 == 0 && j != 0) ? "style=\"background:rgb(249, 248, 245)\"" : "") + ">" + " <td tabindex=\"0\"> <b> " + fixedmultiyeartable[m][i][j][0] + "</b> </td>";

            //check if its na - or an acutal number
            if (isNaN(fixedmultiyeartable[m][i][j][1])) {
              if(fixedmultiyeartable[m][i][j][1]=="-")
              {
                for(var c = 1; c<fixedmultiyeartable[m][i][j].length;c++ )
                {
                  fixedmultiyeartable[m][i][j][c]="NA";
                }
              }
              aggregiatecols += "<td tabindex=\"0\"> " + fixedmultiyeartable[m][i][j][1] + "</td> ";
            }
            else {
              aggregiatecols += "<td tabindex=\"0\"> " + Math.round((fixedmultiyeartable[m][i][j][1] * 10) / 10) + "</td> ";
            }
          }
          //subsequent years are shifted by 1 due to the sub row header
          else {
            if (isNaN(fixedmultiyeartable[m][i][j][0])) {
              aggregiatecols += "<td tabindex=\"0\"> " + "NA" + "</td> ";
            }
            else {
              aggregiatecols += "<td tabindex=\"0\"> " + Math.round((fixedmultiyeartable[m][i][j][0] * 10) / 10) + "</td> ";
            }
          }
          if (fixedmultiyeartable[0][i][0][1] == null && i != 0) {
            fixedmultiyeartable[0][i][0].push(0);
          }
          for (var k = 0; k < fixedmultiyeartable[m][i][j].length; k++) {
            if (k < 2) {

              if (m > 0 && k == 0) {
                if (fixedmultiyeartable[m][i][j][0] == "-") {
                  for (var c = 0; c < fixedmultiyeartable[m][i][j].length; c++) {
                    fixedmultiyeartable[m][i][j][c] = "NA";
                  }
                }
              }
              //subsequent years are shifted by 1 due to the sub row header
              //this is handeld above
              continue;
            }
            if (m == 0 && k < 3) {
              //this is handeld above
              continue;
            }
            //either na - or a raw value
            if (fixedmultiyeartable[m][i][j][k].percent == null) {
              if (isNaN(fixedmultiyeartable[m][i][j][k])) {

                rowcells += "<td tabindex=\"0\"> " + fixedmultiyeartable[m][i][j][k] + "</td> ";
              }
                if(fixedmultiyeartable[m][i][j][k]=="-")
                {
                  this.dataHasDash = true;
                }
                if(fixedmultiyeartable[m][i][j][k]=="NA")
                {
                  this.dataHasNA = true;
                }
              //else
              //rowcells += "<td tabindex=\"0\"> " + (Math.round(fixedmultiyeartable[m][i][j][k] * 10) / 10) + "</td> ";
            }
            //an actual data point
            else {
              if ((Math.round(fixedmultiyeartable[m][i][j][k].percent * 10) / 10) > 0) {
                rowcells += "<td tabindex=\"0\"> " + (Math.round(fixedmultiyeartable[m][i][j][k].percent * 10) / 10).toFixed(1) + "</td> ";
              } else {
                if(fixedmultiyeartable[m][i][j][k].percent == "NA")
                {
                  rowcells += "<td tabindex=\"0\"> " + "NA" + "</td> ";
                }
                else
                rowcells += "<td tabindex=\"0\"> " + '-' + "</td> ";
                this.dataHasDash = true;
              }

              totalperecent += fixedmultiyeartable[m][i][j][k].percent;

              //hold how many records are in the row throughout the year. If its 0 we can replace it later

              if (i != 0)
                fixedmultiyeartable[0][i][0][1]++;
            }

          }
          if(i==0 && fixedmultiyeartable[m][i][0][1] == "NA")
          {
            aggregiatecols += "<td tabindex=\"0\"> NA </td>";

          }
          else
          aggregiatecols += "<td tabindex=\"0\">  " + (Math.round(totalperecent) == 0 ? 100 : Math.round(totalperecent)) + "</td>";
          if (tablestringarray[m] == null) {
            tablestringarray[m] = [];
          }
          tablestringarray[m].push(aggregiatecols + rowcells);

        }
      }
    }

    let tempstring = "";
    let r = 0;

    //loop through all the rows and combine the different years' rows. add the rowvar row based on how many rows we have written.
    // the first row var should be written after the first row for ex.


    for (var count = 0; count < tablestringarray[0].length; count++) {

      //tempstring += tablestringarray[m][count];
      //rowheader
      if (tablestringarray[0][count].includes("id=")) {
        tempstring += tablestringarray[0][count]
        r++
        //rowvar is empty
        if (fixedmultiyeartable[0][r][0][1] == 0) {
          for (i = 0; i < lengtharray[r - 1]; i++) {
            tablestringarray[0][count + i + 1] = ""
            tablestringarray[1][count + i - r] = ""
            tablestringarray[2][count + i - r] = ""
          }
          tablestringarray[0][count + 1] = "<tr >  <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\"> Not enough observations to provide estimates by " + fixedmultiyeartable[0][r][0][0] + " </td> </tr>";

        }
      }
      else {
        //fix row not being blanked out when it should be.
        if (tablestringarray[0][count] == "") {
          tablestringarray[1][count - r] = "";
          tablestringarray[2][count - r] = "";
        }
        tempstring += tablestringarray[0][count] + tablestringarray[1][count - r] + tablestringarray[2][count - r]
      }

      tempstring += " </tr>";


    }

    // update colspan for row header category row
    var re = /ROW_CATEGORY_COLSPAN/gi;
    const rowCategoryColspan = (yearHeaderString.match(/td/g) || []).length / 2;
    if (allhouseholdsdcount[0] < this.supressioncount && allhouseholdsdcount[1] < this.supressioncount && allhouseholdsdcount[2] < this.supressioncount) {
      tempstring = "<tr> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" style=\"border-width:0;font-weight: bold\"> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </td> </tr>"
      multiHeaderRow[0] = []
      multiHeaderRow[1] = []
      multiHeaderRow[2] = []
    }
    tablestring = tempstring.replace(re, rowCategoryColspan.toString());

    return {
      tablestring,
      yearHeaderString,
      multiHeaderRow,
      dataHasNA: this.dataHasNA,
      dataHasDash: this.dataHasDash
    }



  }

  CreatefixedMultitableForChart(chartFilterObj: ChartFilter): ConstructMultiyearTableResult {
    if (chartFilterObj !== undefined) {
      this.chart = true;
      this.chartFilterObj = chartFilterObj;
    }

    const yeararray: string[] = [this.years[0], this.years[1], this.years[2]];
    let tablestringarray: string[] = [];
    let yearHeaderString = "<tr><td></td>";
    const multiHeaderRow: any[][] = [];
    const baseRowHeaders = [
      { colheader: "Number of Households (1000s)" },
      { colheader: "Number of Households (PCT)" },
    ];
    // Go through each year, starting with earliest year
    for (var m = yeararray.length - 1; m >= 0; m--) {
      let yearString = "";
      const currentYear = this.Multiyeartableorganize[m];
      // If a year has no data, create a dummy record for each colVar
      if (currentYear[0].length === 0) {
        chartFilterObj.colVarValue.forEach((col, l) => {
          const dummyObj = {
            year: yeararray[m],
            dcount: "0",
            colheader: col,
          }
          currentYear[0][l] = dummyObj;
        });
      }
      yearHeaderString += '<td>' + currentYear[0][0].year + '</td><td></td>';
      // Filter out unrequested columns
      const filteredHeaders = this.Multiyeartableorganize[m][0].filter((header) => this.chartFilterObj.colVarValue.includes(header.colheader));
      multiHeaderRow.push(baseRowHeaders.concat(filteredHeaders));

      let aggregiatecols = m === yeararray.length - 1 ? "<tr> <td tabindex=\"0\"> <b> All Households </b> </td>" : "";
      for (var i = 0; i < currentYear.length; i++) {
        // Multiyear for chart should only have one array in each year
        const currentArrayInYear = currentYear[i];
        if (currentArrayInYear == undefined) {
          continue;
        }
        // Add each item to the string for that year
        for (var j = 0; j < currentArrayInYear.length; j++) {
          const currentItem = currentArrayInYear[j];
          // Number of Households total cell
          if (j === 0) {
            if (currentItem.dcount < this.supressioncount) {
              aggregiatecols += " <td tabindex=\"0\"> NA </td> ";
              aggregiatecols += " <td tabindex=\"0\"> NA </td> ";
            } else {
              aggregiatecols += "<td tabindex=\"0\"> " + Math.round(currentItem.total) + "</td> ";
              aggregiatecols += " <td tabindex=\"0\"> 100 </td> ";
            }
          }
          // Filter for Y Value here
          if (this.chartFilterObj.colVarValue.includes(currentItem.colheader)) {
            yearHeaderString += '<td></td>'
            if (currentItem.dcount < this.supressioncount) {
              yearString += "<td tabindex=\"0\"> NA </td> ";
            } else if (currentItem.percent === 0) {
              yearString += "<td tabindex=\"0\"> - </td> ";;
            } else {
              yearString += "<td tabindex=\"0\"> " + (Math.round(currentItem.percent * 10) / 10).toFixed(1) + "</td> ";
            }
          }
        }
        tablestringarray.push(aggregiatecols);
        tablestringarray.push(yearString);

        yearHeaderString += '</tr>'
      }
    }
    const tablestring = tablestringarray.join('') + '</tr>';
    return {
      tablestring,
      yearHeaderString,
      multiHeaderRow,
      tableAllRecords: this.Multiyeartableorganize
    }
  }

  organizeMultiyearTableForChart(TableData: any, requestBody: SurveyDataRequestBody, chartFilterObj: ChartFilter) {
    this.chart = true;
    this.chartFilterObj = chartFilterObj;
    this.Multiyear = true;
    let yeararray: string[] = [this.years[0], this.years[1], this.years[2]];
    this.Multiyeartableorganize[0] = [];
    this.Multiyeartableorganize[1] = [];
    this.Multiyeartableorganize[2] = [];
    this.Multiyeartableorganize[0][0] = [];
    this.Multiyeartableorganize[1][0] = [];
    this.Multiyeartableorganize[2][0] = [];

    // TableData retrieved for chart only includes All Households data. Separate into arrays based on years. Keeping same array structure as single year
    TableData.forEach(element => {
      var yearindex = yeararray.indexOf(element.year.toString());
      if (this.Multiyeartableorganize[yearindex] == undefined) {
        this.Multiyeartableorganize[yearindex][0] = [];
      }
      this.Multiyeartableorganize[yearindex][0].push(element);
    });
    // Sort each year (each year has only one array)
    for (var m = 0; m < 3; m++) {
      for (var i = 0; i < this.Multiyeartableorganize[m].length; i++) {
        if (this.Multiyeartableorganize[m][i] == undefined) {
          continue;
        }
        this.Multiyeartableorganize[m][i].sort(function (a, b) {
          if (a.code === b.code) {
            // Price is only important when cities are the same
            return 0 - (+a.cde > +b.cde ? -1 : 1);
          }
          return 0 - (a.code > b.code ? -1 : 1);
        });
      }
    }
    return this.CreatefixedMultitableForChart(chartFilterObj)
  }

  organizetableForMap(TableData: any, requestBody: SurveyDataRequestBody): void {
    this.TableOrganized[1] = [];
    TableData.forEach(element => {
      // Map data will have 2 arrays - All Households and all state data
      if (element.cde_desc === 'All Households') {
        this.TableOrganized[0].push(element);
      } else {
        this.TableOrganized[1].push(element);
      }
      // Sorting just the All Households data
      this.TableOrganized[0].sort(function (a, b) {
        if (+a.code === +b.code) {
          return 0 - (+a.cde > +b.cde ? -1 : 1);
        }
        return 0 - (+a.code > +b.code ? -1 : 1);
      });
    });
  }

  Createfixedtable(requestBody: SurveyDataRequestBody,chartFilterObj?: ChartFilter): ConstructTableResult {
    this.dataHasNA = false;
    this.dataHasDash = false;
    //get the number of subrowvars for the total number of rows the table should have. cde=column# code = row #
    let lengtharray: number[] = []
    let temparray: CustomDataModel[][] = []
    let counter = 0
    let allhouseholdsdcount = 0;
    
    if(this.TableOrganized == null || this.TableOrganized.length==0 || this.TableOrganized[0].length==0 || this.TableOrganized[0][0].dcount < this.supressioncount)
    {
    
      const dataObj: ConstructTableResult = {
       // tablestring:"<tr> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" style=\"border-width:0;font-weight: bold\"> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </td> </tr>",
       tablestring:"", 
       dataHasNA: true,
        dataHasDash: true,
        tableHeaderRow:null,
        tableAllRecords: null,
        showTable: false
        
      }
      return dataObj;
    }


    requestBody.rowvars.forEach((r) => {
      temparray.push(this.filtervarvalues.filter(vr => vr.VariableName == r && (+vr.Code <= this.unsupressedunkown && +vr.Code >= 0)));
 
      //use map here since typescript is only passing the reference around so if we change the .code later
      // it wont affect the filtervarvalues version.
      temparray[counter]= temparray[counter].map(s => Object.assign({}, s))
      temparray[counter].sort(function (a, b) {
         
        return 0 - (+a.Code > +b.Code ? -1 : 1);
      })
      /*if(+temparray[counter][0].Code == 0)
      {
          temparray[counter].forEach(e => ((e.Code as unknown ) as number)++);
      }*/
      lengtharray.push(temparray[counter].length);
      if (requestBody.filterVar != null) {
        if (requestBody.filterVar == r && requestBody.filterVarValue.length !=0 ) {
          if(r=="gestfips")
          {
            requestBody=this.correctfiltervarstatecodes(requestBody,1);
          }
          let temparray2:CustomDataModel[] =[];
        //loop through, if the value exists in the filter var value we need it so push it into the temparray2.
          temparray[counter].forEach(x=>
          {
          if((requestBody.filterVarValue as string[]).indexOf(x.Code) != -1)
            {
              temparray2.push(x)
            }
          });
          //renumber the codes otherwise the assignment wont work properly. may need to adjust this and asssignments in future.
          // if the real code is ever needed.
          let order=1;
          temparray2.forEach(x=> 
            {
              this.TableOrganized[counter+1].forEach(y=> {
                if(y.code == x.Code)
                 y.code= order;})
            x.Code = order.toString(); 
            
            order++;
            })
          temparray[counter]= temparray2.slice();
          lengtharray[counter] = requestBody.filterVarValue.length
         
        }
      }
      if (requestBody.filterVar2 != null) {
        if (requestBody.filterVar2 == r && requestBody.filterVarValue2.length !=0 ) {
          if(r=="gestfips")
          {
            requestBody=this.correctfiltervarstatecodes(requestBody,2);
          }
          let temparray2:CustomDataModel[] =[];
          //loop through, if the value exists in the filter var value we need it so push it into the temparray2.
            temparray[counter].forEach(x=>
            {
            if((requestBody.filterVarValue2 as string[]).indexOf(x.Code) != -1)
              {
                temparray2.push( JSON.parse(JSON.stringify(x)))
              }
            });

         
            //renumber the codes otherwise the assignment wont work properly. may need to adjust this and asssignments in future.
            // if the real code is ever needed.
            let order=1;
            temparray2.forEach(x=> {
              this.TableOrganized[counter+1].forEach(y=> {
                if(y.code == x.Code)
                 y.code= order;})

              x.Code = order.toString(); order++;})
            temparray[counter]= temparray2.slice();
            lengtharray[counter] = requestBody.filterVarValue2.length
           
        }
      }
      counter++
    })
    let fixedyeartable: any[][][] = [];


    fixedyeartable[0] = []
    fixedyeartable[0][0] = []
    //rowvarlength
    for (var i = 0; i < requestBody.rowvars.length; i++) {
      fixedyeartable[i + 1] = []
      //subrowvarlength
      for (var j = 0; j < lengtharray[i] + 1; j++) {
        fixedyeartable[i + 1][j] = []
        //column length
        for (var k = 0; k < this.TableOrganized[0].length + 2; k++) {
          fixedyeartable[i + 1][j].push("-")
        }
      }
    }

    //renumber the coulmns if it doesnt start at 1
    /*if( this.TableOrganized[0][0].cde != 1 )
        {
         var diff = this.TableOrganized[0][0].cde -1; 
         this.TableOrganized.forEach( r => {
            r.forEach(cell => 
              {
                cell.cde -= diff;
              })
         })
        }*/
        //to fix all all issues: just create a  key value map based on the all households row
        //have it increment based on order it appears then just set the cde to whatever it  corresponds to on the map.
        var colmap: {[key: string]: number}={}
        var corder=1;
        this.TableOrganized[0].forEach(e => colmap[e.cde.toString()] = corder++ )
    //assignment loop

    for (var i = 1; i < this.TableOrganized.length; i++) {
      if (this.TableOrganized[i] == null) {
        continue;
      }
           //renumber the rows if it doesnt start at 1 
      var diff = 0; 
      var rowoffset=false;
      if(this.TableOrganized[i][0].code != 1 )
      {
        
      //need to check the amount of unique codes vs how many rows we created
      // if they are equal then it starts from a different number. if not then data is just missing dont renumber
      var codes=  this.TableOrganized[i].map(item => item.code)
        .filter((value, index, self) => self.indexOf(value) === index)
        if(codes.length == temparray[i-1].length)
        {
            diff = this.TableOrganized[i][0].code -1; 
            rowoffset = true;
        }
      }

      for (var j = 0; j < this.TableOrganized[i].length; j++) {
        this.TableOrganized[i][j].cde = colmap[this.TableOrganized[i][j].cde.toString()]
        //handle missing end coulmn
        /*if(this.TableOrganized[i][j].cde >this.TableOrganized[0].length )
        {
         // this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length-2].cde + 1
         if(j==0 || this.TableOrganized[0].length == 1)
         {
           this.TableOrganized[i][j].cde = 1;
         }
        else if(j!=0)
          this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length-2].cde + 1
       
        }
        if (this.TableOrganized[i][j].cde > 10) {
          this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length-2].cde + 1
        }
        if(this.TableOrganized[i][j].cde == 0 )
        {
          for(var tempj =j; tempj< this.TableOrganized[0].length+j; tempj++)
          {
            this.TableOrganized[i][tempj].cde++;
          }
        }*/
        if (this.TableOrganized[i][j].code > 10 && this.TableOrganized[i][j].cde_desc != "gestfips") {
          if(this.TableOrganized[i][j].rowheader == this.TableOrganized[i][j - 1].rowheader)
          {
            this.TableOrganized[i][j].code = this.TableOrganized[i][j - 1].code
          }
          
          else
          this.TableOrganized[i][j].code = this.TableOrganized[i][j - 1].code + 1
        }
        //renumber the rows if it doesnt start at 1 
        if(rowoffset)
        {
          this.TableOrganized[i][j].code -= diff;
        }
        
        try {
          if (this.TableOrganized[i][j].dcount >= this.supressioncount) {
            if (fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] != null) {
              fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] = this.TableOrganized[i][j];
              fixedyeartable[i][this.TableOrganized[i][j].code][0] = this.TableOrganized[i][j].total;
              fixedyeartable[i][this.TableOrganized[i][j].code][1] = 100;
              

            }
          }
          else {
            if (fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] != null) {
              fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] = "NA";
            
            }
            //if total hasnt been set to an actual number then give it NA since data for it technically exists
            if (fixedyeartable[i][this.TableOrganized[i][j].code][0] == "-") {
              fixedyeartable[i][this.TableOrganized[i][j].code][0] = "NA";
             
            }
            fixedyeartable[i][this.TableOrganized[i][j].code][1] = 100;
        
          }
          if(fixedyeartable[i][this.TableOrganized[i][j].code][0] == "NA")
          {
            for(var c=2; c< fixedyeartable[i][this.TableOrganized[i][j].code].length;c++)
            {
              fixedyeartable[i][this.TableOrganized[i][j].code][c] = "NA";
            }
          }
          if(fixedyeartable[i][this.TableOrganized[i][j].code][0] == "NA")
          {
            for(var c=2; c< fixedyeartable[i][this.TableOrganized[i][j].code].length;c++)
            {
              fixedyeartable[i][this.TableOrganized[i][j].code][c] = "NA";
            }
          }

        }
        catch (e) {
          console.log("i: " + i + "j: " + j);
          console.log(this.TableOrganized[i][j]);
          console.log(fixedyeartable);
        }
      }

      // fixedyeartable[m][i].splice(0,1);
      //end assignment loop
    }
   // console.log(temparray)
//console.log(this.TableOrganized)
//console.log(fixedyeartable)
    //add the all households row seperatly
    if (fixedyeartable[0][0] == null) {
      fixedyeartable[0][0] = [];
    }
    fixedyeartable[0][0].push(this.TableOrganized[0][0].total);
    fixedyeartable[0][0].push(100);
    for (var n = 0; n < this.TableOrganized[0].length; n++) {

      fixedyeartable[0][0].push(this.TableOrganized[0][n]);
      allhouseholdsdcount = this.TableOrganized[0][n].dcount

    }



    fixedyeartable[0][0].unshift("All Households")
    //labels
    for (var i = 0; i < requestBody.rowvars.length + 1; i++) {

      //subrowvarlength no code begins  begins at 0 so index [n][0] is always empty
      for (var j = 0; j < lengtharray[i]; j++) {
        //add the row header to the first year buy putting it at the front of the array.

        fixedyeartable[i + 1][j + 1].unshift(temparray[i][j].Value)
      }
      //add the row that contains just rowvar label by making a new row and putting just the value in it.
      if (i + 1 >= fixedyeartable.length || fixedyeartable[0] == null  || temparray[i][0] == null ) {
        continue;
      }
      //fixedyeartable[0][i+1].unshift([temparray[i][0].VarLabel])
      fixedyeartable[i + 1][0] = [];
      fixedyeartable[i + 1][0].push(temparray[i][0].VarLabel)

    }




    let tablestring = "";
    let tablestringarray: string[] = [];
    /*if(chartFilterObj != null && chartFilterObj.chartType=='map')
    {
      
      chartFilterObj.rowvarsValues = [];
      for(i=1; i< fixedyeartable[1].length; i++)
      {
          chartFilterObj.rowvarsValues.push(fixedyeartable[1][i][0]);
      }
    }*/
    //year, rowvar, subrowvar, column; construct the strings

    for (var i = 0; i < fixedyeartable.length; i++) {


      //header row only one element happens once per i.
      if (i != 0)
        tablestringarray.push("<tr id=\"" + fixedyeartable[i][0][0] + "\" style=\"background:lightgrey\"> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" > <b>" + fixedyeartable[i][0][0] + " (PCT) </b></td> </tr>")

      for (var j = 0; j < fixedyeartable[i].length; j++) {
        //this row is used to store info in the other rows
        if (i > 0 && j == 0) {
          continue;
        }
        var totalperecent: number = 0;
        var aggregiatecols: string = "";
        var rowcells: string = "";

        //subrowheader
          aggregiatecols += "<tr " + ((j % 2 == 0 && j != 0) ? "style=\"background:rgb(249, 248, 245)\"" : "") + ">" + " <td tabindex=\"0\"> <b> " + fixedyeartable[i][j][0] + "</b> </td>";

          //check if its na - or an acutal number
          if (isNaN(fixedyeartable[i][j][1])) {
            if(fixedyeartable[i][j][1]=="-")
            {
              for(var c = 1; c<fixedyeartable[i][j].length;c++ )
              {
                fixedyeartable[i][j][c]="NA";
              }
            }
            aggregiatecols += "<td tabindex=\"0\"> " + fixedyeartable[i][j][1] + "</td> ";
            
          }
          else {
            aggregiatecols += "<td tabindex=\"0\"> " + Math.round((fixedyeartable[i][j][1] * 10) / 10) + "</td> ";
          }
          if (fixedyeartable[i][0][1] == null && i != 0) {
            fixedyeartable[i][0].push(0);
          }
          // k 0-2  is handled above (subrowheader,total col, % col)

          for (var k = 3; k < fixedyeartable[i][j].length; k++) {
            totalperecent += fixedyeartable[i][j][k].percent;
            //either na - or a raw value
              if (fixedyeartable[i][j][k].percent == null) {
                if (isNaN(fixedyeartable[i][j][k])){
                

                  rowcells += "<td tabindex=\"0\"> " + fixedyeartable[i][j][k] + "</td> ";
                  if(fixedyeartable[i][j][k]=="-")
                  {
                    this.dataHasDash=true;
                  }
                  if(fixedyeartable[i][j][k]=='NA')
                  {
                    this.dataHasNA=true;
                  }
                }
                

              }
              //an actual data point 
              else {
                if ((Math.round(fixedyeartable[i][j][k].percent * 10) / 10) > 0) {
                  rowcells += "<td tabindex=\"0\"> " + (Math.round(fixedyeartable[i][j][k].percent * 10) / 10).toFixed(1) + "</td> ";
                } else {
                  rowcells += "<td tabindex=\"0\"> " + '-' + "</td> ";
                  this.dataHasDash = true;
                }
             //hold how many records are in the row throughout the year. If its 0 we can replace it later
                
            if (i != 0)
            fixedyeartable[i][0][1]++;
              }



          }
          aggregiatecols += "<td tabindex=\"0\">  " + ((Math.round(totalperecent) == 0) || (isNaN(Math.round(totalperecent))) ? 100 : Math.round(totalperecent)) + "</td>";

          tablestringarray.push(aggregiatecols + rowcells);


      }
    }


    let tempstring = "";
    let r = 0;

    //loop through all the rows and combine the different years' rows. add the rowvar row based on how many rows we have written.
    // the first row var should be written after the first row for ex.


    for (var count = 0; count < tablestringarray.length; count++) {

      //rowheader
      if (tablestringarray[count].includes("id=")) {
        tempstring += tablestringarray[count]
        r++
        //rowvar is empty
        if (fixedyeartable[r][0][1] == 0) {
          for (i = 0; i < lengtharray[r - 1]; i++) {
            tablestringarray[count + i + 1] = ""

          }
          tablestringarray[count + 1] = "<tr >  <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\"> Not enough observations to provide estimates by " + fixedyeartable[r][0][0] + " </td> </tr>";

        }
      }
      else {
        //fix row not being blanked out when it should be.

        tempstring += tablestringarray[count]

        tempstring += " </tr>";


      }
    }
    let tableHeaderRow = fixedyeartable[0][0].slice(3);
    let rowCategoryColspan2 = tableHeaderRow.length + 3;
    tablestring = tablestring.replace(
      re,
      rowCategoryColspan2.toString()
    );

    // update colspan for row header category row
    var re = /ROW_CATEGORY_COLSPAN/gi;
    const rowCategoryColspan = tableHeaderRow.length + 3;
    if (allhouseholdsdcount < this.supressioncount) {
      tempstring = "<tr> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" style=\"border-width:0;font-weight: bold\"> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </td> </tr>"
      tableHeaderRow = []
    }
    tablestring = tempstring.replace(re, rowCategoryColspan.toString());
    const dataObj: ConstructTableResult = {
      tablestring,
      dataHasNA: this.dataHasNA,
      dataHasDash: this.dataHasDash,
      tableHeaderRow,
      tableAllRecords: fixedyeartable,
      showTable: allhouseholdsdcount == 0? false: true
    }
    return dataObj;
  }

  // Copying Createfixedtable but some changes needed to exclude data below supression count
  createFixedTableForChart(requestBody: SurveyDataRequestBody, chartFilterObj: ChartFilter): ConstructTableResult {
    this.dataHasNA = false;
    this.dataHasDash = false;
    this.chartFilterObj = chartFilterObj;

    // Array needed for filtering columns
    let columnsToIncludeIndexes = this.findChartColumnIndexes();
    //get the number of subrowvars for the total number of rows the table should have. cde=column# code = row #
    let lengtharray: number[] = []
    let temparray: CustomDataModel[][] = []
    let counter = 0
    let allhouseholdsdcount = 0;

    if (this.TableOrganized == null || this.TableOrganized.length == 0) {

      const dataObj: ConstructTableResult = {
        tablestring: "<tr> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" style=\"border-width:0;font-weight: bold\"> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </td> </tr>",
        dataHasNA: true,
        dataHasDash: true,
        tableHeaderRow: null,
        tableAllRecords: null,

      }
      return dataObj;
    }

    requestBody.rowvars.forEach((r) => {
      temparray.push(this.filtervarvalues.filter(vr => vr.VariableName == r && (+vr.Code <= this.unsupressedunkown && +vr.Code >= 0)));
      //use map here since typescript is only passing the reference around so if we change the .code later
      // it wont affect the filtervarvalues version.
      temparray[counter] = temparray[counter].map(s => Object.assign({}, s))
      temparray[counter].sort(function (a, b) {
        return 0 - (+a.Code > +b.Code ? -1 : 1);
      });
      lengtharray.push(temparray[counter].length);
      if (requestBody.filterVar != null) {
        if (requestBody.filterVar == r && requestBody.filterVarValue.length != 0) {
          let temparray2: CustomDataModel[] = [];
          //loop through, if the value exists in the filter var value we need it so push it into the temparray2.
          temparray[counter].forEach(x => {
            if ((requestBody.filterVarValue as string[]).indexOf(x.Code) != -1) {
              temparray2.push(JSON.parse(JSON.stringify(x)))
            }
          });
          //renumber the codes otherwise the assignment wont work properly. may need to adjust this and asssignments in future.
          // if the real code is ever needed.
          let order = 1;
          temparray2.forEach(x => {
            this.TableOrganized[counter + 1].forEach(y => {
              if (y.code == x.Code)
                y.code = order;
            })
            x.Code = order.toString();

            order++;
          })
          temparray[counter] = temparray2.slice();
          lengtharray[counter] = requestBody.filterVarValue.length

        }
      }
      if (requestBody.filterVar2 != null) {
        if (requestBody.filterVar2 == r && requestBody.filterVarValue2.length != 0) {
          let temparray2: CustomDataModel[] = [];
          //loop through, if the value exists in the filter var value we need it so push it into the temparray2.
          temparray[counter + 1].forEach(x => {
            if ((requestBody.filterVarValue2 as string[]).indexOf(x.Code) != -1) {
              temparray2.push(x)
            }
          });
          //renumber the codes otherwise the assignment wont work properly. may need to adjust this and asssignments in future.
          // if the real code is ever needed.
          let order = 1;
          temparray2.forEach(x => {
            this.TableOrganized[counter].forEach(y => {
              if (y.code == x.Code)
                y.code = order;
            })

            x.Code = order.toString(); order++;
          })
          temparray[counter] = temparray2.slice();
          lengtharray[counter] = requestBody.filterVarValue2.length

        }
      }
      counter++
    })
    let fixedyeartable: any[][][] = [];
    fixedyeartable[0] = []
    fixedyeartable[0][0] = []
    //rowvarlength
    for (var i = 0; i < requestBody.rowvars.length; i++) {
      fixedyeartable[i + 1] = []
      //subrowvarlength
      for (var j = 0; j < lengtharray[i] + 1; j++) {
        fixedyeartable[i + 1][j] = []
        //column length
        for (var k = 0; k < this.TableOrganized[0].length + 2; k++) {
          fixedyeartable[i + 1][j].push("-")
        }
      }
    }
    //renumber the coulmns if it doesnt start at 1
    // if (this.TableOrganized[0][0].cde != 1) {
    //   var diff = this.TableOrganized[0][0].cde - 1;
    //   this.TableOrganized.forEach(r => {
    //     r.forEach(cell => {
    //       cell.cde -= diff;
    //     })
    //   })
    // }
    //to fix all all issues: just create a  key value map based on the all households row
    //have it increment based on order it appears then just set the cde to whatever it  corresponds to on the map.
    var colmap: { [key: string]: number } = {}
    var corder = 1;
    this.TableOrganized[0].forEach(e => colmap[e.cde.toString()] = corder++)
    //assignment loop
    for (var i = 1; i < this.TableOrganized.length; i++) {
      if (this.TableOrganized[i] == null) {
        continue;
      }
      //renumber the rows if it doesnt start at 1 
      var diff = 0;
      var rowoffset = false;
      if (this.TableOrganized[i][0].code != 1) {
        //need to check the amount of unique codes vs how many rows we created
        // if they are equal then it starts from a different number. if not then data is just missing dont renumber
        var codes = this.TableOrganized[i].map(item => item.code)
          .filter((value, index, self) => self.indexOf(value) === index)
        if (codes.length == temparray[i - 1].length) {
          diff = this.TableOrganized[i][0].code - 1;
          rowoffset = true;
      }
      }
      for (var j = 0; j < this.TableOrganized[i].length; j++) {
        this.TableOrganized[i][j].cde = colmap[this.TableOrganized[i][j].cde.toString()]
        //handle missing end coulmn
        // if (this.TableOrganized[i][j].cde > this.TableOrganized[0].length) {
        //   // this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length-2].cde + 1
        //   if (j == 0 || this.TableOrganized[0].length == 1) {
        //     this.TableOrganized[i][j].cde = 1;
        //   }
        //   else if (j != 0)
        //     this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length - 2].cde + 1

        // }
        // if (this.TableOrganized[i][j].cde > 10) {
        //   this.TableOrganized[i][j].cde = this.TableOrganized[0][this.TableOrganized[0].length - 2].cde + 1
        // }
        // if (this.TableOrganized[i][j].cde == 0) {
        //   for (var tempj = j; tempj < this.TableOrganized[0].length + j; tempj++) {
        //     this.TableOrganized[i][tempj].cde++;
        //   }
        // }
        if (this.TableOrganized[i][j].code > 10) {
          if (this.TableOrganized[i][j].rowheader == this.TableOrganized[i][j - 1].rowheader) {
            this.TableOrganized[i][j].code = this.TableOrganized[i][j - 1].code
          }

          else
          this.TableOrganized[i][j].code = this.TableOrganized[i][j - 1].code + 1
        }
        //renumber the rows if it doesnt start at 1 
        if (rowoffset) {
          this.TableOrganized[i][j].code -= diff;
        }
        try {
          if (this.TableOrganized[i][j].dcount >= this.supressioncount) {
            if (fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] != null) {
              fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] = this.TableOrganized[i][j];
              fixedyeartable[i][this.TableOrganized[i][j].code][0] = this.TableOrganized[i][j].total;
              fixedyeartable[i][this.TableOrganized[i][j].code][1] = 100;

            }
          }
          else {
            if (fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] != null) {
              fixedyeartable[i][this.TableOrganized[i][j].code][this.TableOrganized[i][j].cde + 1] = "NA";
            }
            //if total hasnt been set to an actual number then give it NA since data for it technically exists
            if (fixedyeartable[i][this.TableOrganized[i][j].code][0] == "-") {
              fixedyeartable[i][this.TableOrganized[i][j].code][0] = "NA";
            }
            fixedyeartable[i][this.TableOrganized[i][j].code][1] = 100;
          }
          if (fixedyeartable[i][this.TableOrganized[i][j].code][0] == "NA") {
            for (var c = 2; c < fixedyeartable[i][this.TableOrganized[i][j].code].length; c++) {
              fixedyeartable[i][this.TableOrganized[i][j].code][c] = "NA";
            }
          }
        }
        catch (e) {
          console.log("i: " + i + "j: " + j);
          console.log(this.TableOrganized[i][j]);
          console.log(fixedyeartable);
        }
      }
      //end assignment loop
    }

    //add the all households row seperatly
    if (fixedyeartable[0][0] == null) {
      fixedyeartable[0][0] = [];
    }
    fixedyeartable[0][0].push(this.TableOrganized[0][0].total);
    fixedyeartable[0][0].push(100);
    for (var n = 0; n < this.TableOrganized[0].length; n++) {

      fixedyeartable[0][0].push(this.TableOrganized[0][n]);
      allhouseholdsdcount = this.TableOrganized[0][n].dcount
    }

    fixedyeartable[0][0].unshift("All Households")
    //labels
    for (var i = 0; i < requestBody.rowvars.length + 1; i++) {
      //subrowvarlength no code begins  begins at 0 so index [n][0] is always empty
      for (var j = 0; j < lengtharray[i]; j++) {
        //add the row header to the first year buy putting it at the front of the array.
        fixedyeartable[i + 1][j + 1].unshift(temparray[i][j].Value)
      }
      //add the row that contains just rowvar label by making a new row and putting just the value in it.
      if (i + 1 >= fixedyeartable.length || fixedyeartable[0] == null || temparray[i][0] == null) {
        continue;
      }
      //fixedyeartable[0][i+1].unshift([temparray[i][0].VarLabel])
      fixedyeartable[i + 1][0] = [];
      fixedyeartable[i + 1][0].push(temparray[i][0].VarLabel)
    }

    let tablestring = "";
    let tablestringarray: string[] = [];
    let showRowVarData = false;
    //year, rowvar, subrowvar, column; construct the strings
    if(this.chartFilterObj.chartType=='map')
        {
          this.chartFilterObj.rowvarsValues = [];
          for(i=1; i< fixedyeartable[1].length; i++)
          {
              this.chartFilterObj.rowvarsValues.push(fixedyeartable[1][i][0]);
          }
        }
    for (var i = 0; i < fixedyeartable.length; i++) {
      //header row only one element happens once per i.
      if (i != 0) {
        tablestringarray.push("<tr id=\"" + fixedyeartable[i][0][0] + "\" style=\"background:lightgrey\"> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" > <b>" + fixedyeartable[i][0][0] + " (PCT) </b></td> </tr>")
      }
      for (var j = 0; j < fixedyeartable[i].length; j++) {
        //this row is used to store info in the other rows
        if (i > 0 && j == 0) {
          continue;
        }
        var totalperecent: number = 0;
        var aggregiatecols: string = "";
        var rowcells: string = "";
    
        //subrowheader
        // Chart filters by X Value here - custom-data not filtered
        if (this.chartFilterObj.rowvarsValues.includes(fixedyeartable[i][j][0]) || fixedyeartable[i][j][0] === 'All Households') {
          aggregiatecols += "<tr " + ((j % 2 == 0 && j != 0) ? "style=\"background:rgb(249, 248, 245)\"" : "") + ">" + " <td tabindex=\"0\"> <b> " + fixedyeartable[i][j][0] + "</b> </td>";
          //check if its na - or an acutal number
          if (isNaN(fixedyeartable[i][j][1])) {
            if (fixedyeartable[i][j][1] == "-") {
              for (var c = 1; c < fixedyeartable[i][j].length; c++) {
                fixedyeartable[i][j][c] = "NA";
              }
            }
            aggregiatecols += "<td tabindex=\"0\"> " + fixedyeartable[i][j][1] + "</td> ";
          }
          else {
            aggregiatecols += "<td tabindex=\"0\"> " + Math.round((fixedyeartable[i][j][1] * 10) / 10) + "</td> ";
          }
          if (fixedyeartable[i][0][1] == null && i != 0) {
            fixedyeartable[i][0].push(0);
          }
          // k 0-2  is handled above (subrowheader,total col, % col)
          for (var k = 3; k < fixedyeartable[i][j].length; k++) {
            totalperecent += fixedyeartable[i][j][k].percent;
            // Chart filters for Y Value here
            if (columnsToIncludeIndexes.includes(k)) {
              //either na - or a raw value
              if (fixedyeartable[i][j][k].percent == null) {
                if (isNaN(fixedyeartable[i][j][k])) {
                  if (!this.dataHasNA && fixedyeartable[i][j][k] === 'NA') {
                    this.dataHasNA = true;
                  } else if (!this.dataHasDash && fixedyeartable[i][j][k] === '-') {
                    this.dataHasDash = true;
                  }
                  rowcells += "<td tabindex=\"0\"> " + fixedyeartable[i][j][k] + "</td> ";
                }
              }
              //an actual data point
              else {
                if ((Math.round(fixedyeartable[i][j][k].percent * 10) / 10) > 0) {
                  rowcells += "<td tabindex=\"0\"> " + (Math.round(fixedyeartable[i][j][k].percent * 10) / 10).toFixed(1) + "</td> ";
                } else {
                  rowcells += "<td tabindex=\"0\"> " + '-' + "</td> ";
                  this.dataHasDash = true;
                }
                // If data point exists that's not All Households, then row data will be displayed
                if (i !== 0) {
                  showRowVarData = true;
                }
                //hold how many records are in the row throughout the year. If its 0 we can replace it later
              }
            }
            if (i != 0)
              fixedyeartable[i][0][1]++;


          }
          aggregiatecols += "<td tabindex=\"0\">  " + ((Math.round(totalperecent) == 0) || (isNaN(Math.round(totalperecent))) ? 100 : Math.round(totalperecent)) + "</td>";
          tablestringarray.push(aggregiatecols + rowcells);

        }
      }
    }
    // If row var data should not be shown, trim down to All Households data. Don't show NA footnote
    if (!showRowVarData) {
      tablestringarray = tablestringarray.slice(0, 2);
      tablestringarray.push("<tr >  <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\"> Not enough observations to provide estimates by " + fixedyeartable[1][0][0] + " </td> </tr>");
      this.dataHasNA = false;
    }
    let tempstring = "";
    let r = 0;

    //loop through all the rows and combine the different years' rows. add the rowvar row based on how many rows we have written.
    // the first row var should be written after the first row for ex.


    for (var count = 0; count < tablestringarray.length; count++) {

      //rowheader
      if (tablestringarray[count].includes("id=")) {
        tempstring += tablestringarray[count]
        r++
        //rowvar is empty
        if (fixedyeartable[r][0][1] == 0) {
          for (i = 0; i < lengtharray[r - 1]; i++) {
            tablestringarray[count + i + 1] = ""

          }
          tablestringarray[count + 1] = "<tr >  <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\"> Not enough observations to provide estimates by " + fixedyeartable[r][0][0] + " </td> </tr>";

        }
      }
      else {
        //fix row not being blanked out when it should be.

        tempstring += tablestringarray[count]

        tempstring += " </tr>";


      }
    }
    let tableHeaderRow = fixedyeartable[0][0].slice(3);
    // Additional filtering needed for tableHeaderRow if data is for chart
    tableHeaderRow = tableHeaderRow.filter((header) => this.chartFilterObj.colVarValue.includes(header.colheader));
    let rowCategoryColspan2 = tableHeaderRow.length + 3;
    tablestring = tablestring.replace(
      re,
      rowCategoryColspan2.toString()
    );

    // update colspan for row header category row
    var re = /ROW_CATEGORY_COLSPAN/gi;
    const rowCategoryColspan = tableHeaderRow.length + 3;
    let showTable = true;
    if (allhouseholdsdcount < this.supressioncount) {
      tempstring = "<tr> <td colspan=ROW_CATEGORY_COLSPAN tabindex=\"0\" style=\"border-width:0;font-weight: bold\"> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </td> </tr>"
      tableHeaderRow = [];
      showTable = false;
    }
    tablestring = tempstring.replace(re, rowCategoryColspan.toString());
    const dataObj: ConstructTableResult = {
      tablestring,
      dataHasNA: this.dataHasNA,
      dataHasDash: this.dataHasDash,
      tableHeaderRow,
      tableAllRecords: fixedyeartable,
      showTable,
    }
    return dataObj;
  }

  constructDataTableSingleRow(chartFilterObj?: ChartFilter): ConstructTableResult {
    if (chartFilterObj !== undefined) {
      this.chartFilterObj = chartFilterObj;
    }
    let showTable = false;
    let tablestring = "";
    let aggregiatecols = "";
    let rowcells = "";
    let tableHeaderRow = [];
    // No filtering needed on pie chart - all records included. For map table, only use first array
    for (let i = 0; i < this.TableOrganized[0].length; i++) {
      const currentItem = this.TableOrganized[0][i];
      //the first 3 columns are the label, total number (present on each row), total percent (needs to be totaled by each cell)
      if (i === 0) {
        aggregiatecols = "<tr>" + " <td tabindex=\"0\"> <b> " + currentItem.cde_desc + "</b> </td>";
        if (currentItem.dcount < this.supressioncount) {
          aggregiatecols += " <td tabindex=\"0\"> NA </td> ";
          this.dataHasNA = true;
        } else {
          aggregiatecols += "<td tabindex=\"0\"> " + Math.round(currentItem.total) + "</td> ";
        }
      }
      // Map filters for Y Value here - pie does not
      if (this.chartFilterObj.chartType === 'pchart' || this.chartFilterObj.colVarValue.includes(currentItem.colheader)) {
        if (currentItem.dcount < this.supressioncount) {
          rowcells += "<td tabindex=\"0\"> NA </td> ";
          this.dataHasNA = true;
        } else if (currentItem.percent === 0) {
          rowcells += "<td tabindex=\"0\"> - </td> ";
          this.dataHasDash = true;
        }
        else {
          rowcells += "<td tabindex=\"0\"> " + (Math.round(currentItem.percent * 10) / 10).toFixed(1) + "</td> ";
          showTable = true;
        }
        tableHeaderRow.push(currentItem);
      }
    }
    aggregiatecols += "<td tabindex=\"0\"> 100 </td>";
    tablestring += aggregiatecols + rowcells + "</tr>";

    const dataObj: ConstructTableResult = {
      tablestring,
      tableHeaderRow,
      tableAllRecords: this.TableOrganized,
      showTable,
    }
    return dataObj;
  }

  findChartColumnIndexes(): number[] {
    // Push index number from All Households data that match requested columns. Index is +3 to match array structure
    const filteredIndexes: number[] = [];
    this.TableOrganized[0].map((item, i) => {
      if (this.chartFilterObj.colVarValue.includes(item.colheader)) {
        filteredIndexes.push(i + 3);
      }
    });
    return filteredIndexes;
  }
  createSubtitle(surveyDataRequestBodyObject:SurveyDataRequestBody) {
    var subtitle = "";

    if (surveyDataRequestBodyObject.colVar) {
      let title = this.SubtitleData.find(
        (o) => o.VariableName === surveyDataRequestBodyObject.colVar
      );
      if (title != null && title.Notes != null) subtitle = title.Notes;
    }
    if (subtitle == "") {
      subtitle = "All households";
    }
    if (surveyDataRequestBodyObject.filterVar) {
      var element = this.filtervarvalues.find(
        (x) => x.VariableName == surveyDataRequestBodyObject.filterVar
      );
      subtitle += ", " + element.VarLabel + " is ";
      surveyDataRequestBodyObject.filterVarValue.forEach((ele) => {
        var value = this.filtervarvalues.find(
          (x) =>
            x.Code == ele &&
            x.VariableName == surveyDataRequestBodyObject.filterVar
        );
        subtitle += value.Value + ", ";
      });
      subtitle = subtitle.substring(
        0,
        subtitle.lastIndexOf(",")
      );
      //var value = FilterVarsValues.find(x=> x.Code == surveyDataRequestBodyObject.filterVarValue &&  x.VariableName==surveyDataRequestBodyObject.filterVar)
    }
    if (surveyDataRequestBodyObject.filterVar2) {
      var element = this.filtervarvalues.find(
        (x) => x.VariableName == surveyDataRequestBodyObject.filterVar2
      );
      // var value = FilterVarsValues.find(x=> x.Code == surveyDataRequestBodyObject.filterVarValue2 &&  x.VariableName==surveyDataRequestBodyObject.filterVar2)
      subtitle += ", " + element.VarLabel + " is ";
      surveyDataRequestBodyObject.filterVarValue2.forEach((ele) => {
        var value = this.filtervarvalues.find(
          (x) =>
            x.Code == ele &&
            x.VariableName == surveyDataRequestBodyObject.filterVar2
        );
        subtitle += value.Value + ", ";
      });
      subtitle = subtitle.substring(
        0,
        subtitle.lastIndexOf(",")
      );
    }
    return subtitle;
  }
  
}
