import { Pipe, PipeTransform } from '@angular/core';
import { GenericModel } from 'src/app/models/GenericModel.model';


@Pipe({
    name: 'YearFilter',
    pure: false
})
export class GenericYearPipe implements PipeTransform {
    transform(items: GenericModel[], filter: string): any {
        let yeararray: string[] = ["2019", "2017", "2015"];
        if (!items || !filter) {
            return items;
        }
        if(filter=="multi")
        {
            return items.filter(item =>(item.EndYear == null || +item.EndYear >= +yeararray[2]) );

        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        // compares the start year and year to the selected year as the param.
        return items.filter(item => +item.StartYear <= +filter && (item.EndYear == null || +item.EndYear >= +filter) );
    }
}