import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import Swal from "sweetalert2";
import { CustomDataModel } from "src/app/models/CustomDataModel";
import {
  SurveyDataMetadata,
  SurveyDataRequestBody,
  SurveyEvent,
  SurveyValidation,
} from "src/app/models/surveyData.model";
import { ApiService } from "src/app/services/api.service";
import { FormValidationService } from "src/app/services/form-validation.service";
import { msaHasTilde } from "src/app/common/shared";
import { DropdownData } from "src/app/models/chartTool.model";
import { DataTableService } from "src/app/services/data-table.service";

@Component({
  selector: "app-chart-criteria-selection",
  templateUrl: "./chart-criteria-selection.component.html",
  styleUrls: ["./chart-criteria-selection.component.scss"],
})
export class ChartCriteriaSelectionComponent implements OnInit {
  statedata: any[];
  msadata: any[];
  regiondata: any[];
  years: string[];
  selectedState = 0;
  selectedMSA = 0;
  selectedYear: string;
  selectedArea: string = "National";
  selectedRegion = 0;
  selectedYTopic: string = "";
  selectedYVariable: string = "";
  selectedYValue: string[];
  selectedXTopic: string = "";
  selectedXVariable: string = "";
  selectedXValue: string[];
  selectedFilter1Topic: string = "";
  selectedFilter1Variable: string = "";
  selectedFilter1Value: string[] = [];
  selectedFilter2Topic: string = "";
  selectedFilter2Variable: string = "";
  selectedFilter2Value: string[] = [];
  xTopics: any[];
  yTopics: any[];
  filterTopics: any[];
  VariableData: CustomDataModel[];
  VariableDataDistinct: CustomDataModel[];
  xVariableData: CustomDataModel[];
  xVariableDataValues: CustomDataModel[];
  yVariableData: CustomDataModel[];
  yVariableDataValues: CustomDataModel[];
  requestBodyDefault: SurveyDataRequestBody;
  requestMetadataDefault: SurveyDataMetadata;
  allHouseholds = false;
  yTopicLabel: string = "Y Topic";
  yVariableLabel: string = "Y Variable";
  yValueLabel: string = "Y Value:";
  displayXCriteria: boolean = true;
  displayAllHH: boolean = true;
  displayYValue: boolean = true;
  displayMultiYear: boolean = false;
  displayMapConfig: boolean = false;
  msanote: string = "";
  previousGeoSelection: string = "";
  mapDefault: boolean = false;

  @Input() chartTypeForQuery: string;

  @Input() requestBody: SurveyDataRequestBody;

  @Input() requestMetadata: SurveyDataMetadata;

  @Output() updateChartEvent = new EventEmitter<SurveyEvent>();

  @Output() dropdownDataEvent = new EventEmitter<DropdownData>();

  filter1VariableData: CustomDataModel[];
  filter1VariableDataValues: CustomDataModel[];
  filter2VariableData: CustomDataModel[];
  filter2VariableDataValues: CustomDataModel[];

  constructor(
    private apiService: ApiService,
    private dataTableService: DataTableService,
    private formValidationService: FormValidationService
  ) {}

  async ngOnInit(): Promise<void> {
    // Need to run years first to run the rest of the dropdown queries
    this.years = this.dataTableService.GetYears();
    this.selectedYear = this.years[0];
    this.requestBodyDefault = Object.assign({}, this.requestBody);
    this.requestMetadataDefault = Object.assign({}, this.requestMetadata);
    if ("map" === this.chartTypeForQuery) {
      this.selectedArea = "State";
    }
    this.setDefaultVariables();
    this.GetStates();
    this.GetMsas();
    this.GetRegions();
    this.GetXTopics(this.selectedYear);
    this.getChartFilterTopics(this.selectedYear, "n");
    this.getDisplayCriteria();
    this.previousGeoSelection = this.selectedArea;
  }

  setDefaultVariables(): void {
    this.selectedYTopic = this.requestMetadataDefault.yVariableTopic;
    this.selectedYVariable = this.requestBodyDefault.colVar;
    this.selectedXTopic =
      this.selectedYear === "multi"
        ? ""
        : this.requestMetadataDefault.xVariableTopic;
    if (
      this.requestBodyDefault.rowvars === undefined ||
      this.selectedXVariable === "multi"
    ) {
      this.selectedXVariable = "";
    } else {
      this.selectedXVariable = this.requestBodyDefault.rowvars[0];
    }
    if (this.chartTypeForQuery === "map") {
      this.mapDefault = true;
      this.selectedYValue = this.requestMetadataDefault.colVarValue;
    }
  }

  GetStates() {
    this.apiService.getStateNames().then((x) => {
      this.statedata = x;
      // When states are received, emit states and years to main component
      this.dropdownDataEvent.emit({
        statedata: this.statedata,
        years: this.years,
      });
    });
  }

  GetMsas() {
    this.apiService.getMsas().then((x) => (this.msadata = x));
  }

  GetRegions() {
    this.apiService.getRegions().then((x) => (this.regiondata = x));
  }

  GetVariableDataDistinct() {
    this.apiService.getCustomDataDistinct().then((x) => {
      this.VariableDataDistinct = x;
      this.getXVariables(this.selectedXTopic);
      this.getYVariables(this.selectedYTopic);
      this.GetVariableData();
    });
  }

  GetVariableData() {
    this.apiService.getCustomData().then((x) => {
      this.VariableData = x;
      this.getXVariableValues(this.selectedXVariable);
      this.getYVariableValues(this.selectedYVariable);
    });
  }

  GetXTopics(year: string) {
    this.xTopics = [];
    this.yTopics = [];
    this.xVariableData = [];
    this.yVariableData = [];
    this.xVariableDataValues = [];
    this.yVariableDataValues = [];
    this.apiService.getXTopics(this.selectedYear).then((x) => {
      this.xTopics = x;
      this.apiService.getYTopics(this.selectedYear).then((x) => {
        this.yTopics = x;
        this.GetVariableDataDistinct();
      });
    });
  }

  getXVariables(topic: string) {
    this.xVariableData = [];
    this.xVariableDataValues = [];
    this.VariableDataDistinct.forEach((element) => {
      if (element.Topic == topic) {
        if (   element.ValidYears.includes(this.selectedYear) && "y" == element.RowVariable) {
        if(element.VarLabel != "State")
        {
          this.xVariableData.push(element);
        }
          else  if(element.VarLabel == "State" && (this.selectedArea == "National" || this.selectedArea =="Region"))
          {
            this.xVariableData.push(element);
          }
        }
      }
    });
  }

  getXVariablesChange(topic: string) {
    this.selectedXVariable = "";
    this.selectedXValue = [];
    this.xVariableDataValues = [];
    this.getXVariables(topic);
  }

  getXVariableValues(variable: string) {
    this.xVariableDataValues = [];
    this.VariableData.forEach((element) => {
      if (element.VariableName == variable && element.Code != "-1") {
        if (element.ValidYears.includes(this.selectedYear)) {
          this.xVariableDataValues.push(element);
          this.xVariableDataValues.sort((a, b) => a.Code.localeCompare(b.Code));
        }
      }
      if(element.VariableName == variable && variable=="gestfips")
      {
        this.xVariableDataValues.sort((a, b) =>
        a.Value.localeCompare(b.Value));
      }
    });
    this.selectedXValue = this.xVariableDataValues.map((item) => item.Value);
  }

  GetYTopics(year: string) {
    this.apiService
      .getYTopics(this.selectedYear)
      .then((x) => (this.yTopics = x));
  }

  getYVariables(topic: string) {
    this.yVariableData = [];
    this.VariableDataDistinct.forEach((element) => {
      if (element.Topic == topic) {
        if (
          element.ValidYears.includes(this.selectedYear) &&
          "y" == element.ColVariable
        ) {
          this.yVariableData.push(element);
        }
      }
    });
  }

  getYVariablesChange(topic: string) {
    this.selectedYVariable = "";
    this.selectedYValue = [];
    this.yVariableDataValues = [];
    this.getYVariables(topic);
  }

  getYVariableValues(variable: string) {
    this.yVariableDataValues = [];
    this.VariableData.forEach((element) => {
      if (element.VariableName == variable && element.Code != "-1") {
        if (element.ValidYears.includes(this.selectedYear)) {
          this.yVariableDataValues.push(element);
          this.yVariableDataValues.sort((a, b) => a.Code.localeCompare(b.Code));
        }
      }
    });
    // Map YValue is just one item - all other charts is entire array
    if (this.chartTypeForQuery !== "map") {
      this.selectedYValue = this.yVariableDataValues.map((item) => item.Value);
    } else {
      if (this.mapDefault) {
        this.mapDefault = false;
      } else {
        this.selectedYValue = [];
      }
    }
  }

  getChartFilterTopics(year: string, nation: string) {
    if ("map" === this.chartTypeForQuery) {
      nation = "y";
    }
    this.apiService
      .getChartFilterTopics(this.selectedYear, nation)
      .then((x) => {
        this.filterTopics = x;
      });
  }

  getFilter1Variables(topic: string) {
    if (topic === "") {
      this.selectedFilter1Variable = "";
      this.selectedFilter1Value = [];
    } else {
      this.filter1VariableData = [];
      this.getFilterVariables(topic, "filter1");
    }
  }

  getFilter1VariablesChange(topic: string) {
    this.selectedFilter1Variable = "";
    this.selectedFilter1Value = [];
    this.selectedFilter2Topic = "";
    this.selectedFilter2Variable = "";
    this.selectedFilter2Value = [];
    this.getFilter1Variables(topic);
  }

  getFilter2Variables(topic: string) {
    if (topic === "") {
      this.selectedFilter2Variable = "";
      this.selectedFilter2Value = [];
    } else {
      this.filter2VariableData = [];
      this.getFilterVariables(topic, "filter2");
    }
  }

  getFilterVariables(topic: string, filterType: string) {
    this.VariableDataDistinct.forEach((element) => {
      //check if topic matches, then chheck if its a filter variable, if or if National is selected as Area
      if ("National" === this.selectedArea) {
        if (
          element.Topic === topic &&
          (element.Filter === "y" || element.DisableFilterBelowNational === "y")
        ) {
          if (element.ValidYears.includes(this.selectedYear)) {
            if ("filter1" === filterType) {
              this.filter1VariableData.push(element);
            } else {
              this.filter2VariableData.push(element);
            }
          }
        }
      } else {
        // If anything other than National
        if (
          element.Topic === topic &&
          element.Filter === "y" &&
          element.DisableFilterBelowNational !== "y"
        ) {
          if (element.ValidYears.includes(this.selectedYear)) {
            if ("filter1" === filterType) {
              this.filter1VariableData.push(element);
            } else {
              this.filter2VariableData.push(element);
            }
          }
        }
      }
    });
  }

  getFilter2VariablesChange(topic: string) {
    this.selectedFilter2Variable = "";
    this.selectedFilter2Value = [];
    this.getFilter2Variables(topic);
  }

  getFilter1VariableValues(variable: string) {
    if (variable === "") {
      this.selectedFilter1Value = [];
      this.selectedFilter2Topic = "";
      this.selectedFilter2Variable = "";
      this.selectedFilter2Value = [];
    }
    this.filter1VariableDataValues = [];
    this.VariableData.forEach((element) => {
      if (element.VariableName == variable && element.Code != "-1") {
        if (element.ValidYears.includes(this.selectedYear)) {
          this.filter1VariableDataValues.push(element);
          this.filter1VariableDataValues.sort((a, b) =>
            a.Code.localeCompare(b.Code)
          );
        }
      }
      if(element.VariableName == variable && variable=="gestfips")
      {
        this.filter1VariableDataValues.sort((a, b) =>
        a.Value.localeCompare(b.Value));
      }
    });
  }

  getFilter2VariableValues(variable: string) {
    if (variable === "") {
      this.selectedFilter2Value = [];
    }
    this.filter2VariableDataValues = [];
    this.VariableData.forEach((element) => {
      if (element.VariableName == variable && element.Code != "-1") {
        if (element.ValidYears.includes(this.selectedYear)) {
          this.filter2VariableDataValues.push(element);
          this.filter2VariableDataValues.sort((a, b) =>
            a.Code.localeCompare(b.Code)
          );
        }
      }
      if(element.VariableName == variable && variable=="gestfips")
      {
        this.filter2VariableDataValues.sort((a, b) =>
        a.Value.localeCompare(b.Value));
      }
    });
  }

  resetTopicData(year: string) {
    this.GetXTopics(year);
    this.resetFilterTopicData(year);
    this.setDefaultVariables();
    this.resetFilterDisplay();
    this.resetMultiYear(year);
  }

  resetFilterTopicData(year: string) {
    let nation = "n";
    if ("National" !== this.selectedArea) {
      nation = "y";
    }
    this.getChartFilterTopics(year, nation);
  }

  resetFilterTopicForGeo() {
    let belowNational = ["State", "MSA", "Region"];
    if (
      (this.selectedArea === "National" &&
        belowNational.includes(this.previousGeoSelection)) ||
      (belowNational.includes(this.selectedArea) &&
        this.previousGeoSelection === "National")
    ) {
      this.resetFilterTopicData(this.selectedYear);
      this.resetFilterDisplay();
    
    }
    this.getXVariablesChange(this.selectedXTopic);
    this.previousGeoSelection = this.selectedArea;
  }

  resetFilterDisplay(): void {
    this.selectedFilter1Topic = "";
    this.selectedFilter2Topic = "";
    this.selectedFilter1Variable = "";
    this.selectedFilter2Variable = "";
    this.selectedFilter1Value = [];
    this.selectedFilter2Value = [];
  }

  updateChart(): void {
    const validationSuccess = this.handleValidation();
    if (validationSuccess) {
      // Set variables needed
      const filterVarValue: string[] = this.selectedFilter1Value.map(
        (item) =>
          this.filter1VariableDataValues.find((data) => data.Value === item)
            .Code
      );
      const filterVarValue2: string[] = this.selectedFilter2Value.map(
        (item) =>
          this.filter2VariableDataValues.find((data) => data.Value === item)
            .Code
      );
      const area = this.setAreaVariableForTitle();
      const filterText: string =
        filterVarValue.length > 0
          ? this.filter1VariableData.find(
              (item) => item.VariableName === this.selectedFilter1Variable
            ).VarLabel
          : "";
      const filterText2: string =
        filterVarValue2.length > 0
          ? this.filter2VariableData.find(
              (item) => item.VariableName === this.selectedFilter2Variable
            ).VarLabel
          : "";
      let xVariableText = "";
      if (this.selectedXVariable.length > 0) {
        const matchedItem = this.VariableDataDistinct.find(
          (item) => item.VariableName === this.selectedXVariable
        );
        if (matchedItem !== undefined) {
          xVariableText = matchedItem.VarLabel;
        }
      }
      const rowvars: string[] =
        this.chartTypeForQuery === "map"
          ? ["gestfips"]
          : [this.selectedXVariable];
      const colVarValue: string[] =
        typeof this.selectedYValue === "string"
          ? [this.selectedYValue]
          : this.selectedYValue;
      this.requestBody = {
        colVar: this.selectedYVariable,
        rowvars,
        chartType: this.chartTypeForQuery,
        year: this.selectedYear,
        region: this.selectedRegion,
        state: this.selectedState,
        msa: this.selectedMSA,
        filterVar: this.selectedFilter1Variable,
        filterVarValue,
        filterVar2: this.selectedFilter2Variable,
        filterVarValue2,
      };
      this.requestMetadata = {
        year: this.selectedYear,
        area,
        yVariableText: this.VariableDataDistinct.find(
          (item) => item.VariableName === this.selectedYVariable
        ).VarLabel,
        xVariableText,
        filterText,
        filterText2,
        filterVarValue: this.selectedFilter1Value,
        filterVarValue2: this.selectedFilter2Value,
        // colVarValue used to filter data on front end
        colVarValue,
        // rowvarsValues used to filter data on front end
        rowvarsValues: this.selectedXValue,
        allHouseholds: this.allHouseholds,
      };
      // Pie chart should not have rowvars
      if (this.chartTypeForQuery === "pchart") {
        delete this.requestBody.rowvars;
      }
      this.updateChartEvent.emit({
        requestBody: this.requestBody,
        requestMetadata: this.requestMetadata,
        msadata: this.msadata,
        filter1VariableData: this.filter1VariableData,
        columnData: this.yVariableData,
        rowData: this.xVariableData,
      });
    }
  }

  handleValidation(): boolean {
    this.convertStringsToNumbers();
    const validationObj: SurveyValidation = {
      validationFields:
        "map" === this.chartTypeForQuery
          ? ["yItems", "xItems"]
          : ["geography", "yItems", "xItems"],
      area: this.selectedArea,
      region: this.selectedRegion,
      state: this.selectedState,
      msa: this.selectedMSA,
      yTopic: this.selectedYTopic,
      yVariable: this.selectedYVariable,
      yValue: this.selectedYValue,
      xTopic: this.selectedXTopic,
      xVariable: this.selectedXVariable,
      xValue: this.selectedXValue,
      year: this.selectedYear,
      chartType: this.chartTypeForQuery,
    };
    return this.formValidationService.startValidation(validationObj);
  }

  convertStringsToNumbers(): void {
    if (typeof this.selectedRegion === "string") {
      this.selectedRegion = parseInt(this.selectedRegion);
    }
    if (typeof this.selectedState === "string") {
      this.selectedState = parseInt(this.selectedState);
    }
    if (typeof this.selectedMSA === "string") {
      this.selectedMSA = parseInt(this.selectedMSA);
    }
  }

  setAreaVariableForTitle(): string {
    if ("map" === this.chartTypeForQuery) {
      return;
    }
    switch (this.selectedArea) {
      case "State":
        return this.statedata.find(
          (state) => state.Code === this.selectedState.toString()
        ).FullText;
      case "MSA":
        return this.msadata.find(
          (msa) => msa.Code === this.selectedMSA.toString()
        ).Value;
      case "Region":
        return this.regiondata.find(
          (region) => region.Code === this.selectedRegion.toString()
        ).Value;
      default:
        return "Nation";
    }
  }

  alertInvalidFormOnSubmit(errorString: string): void {
    Swal.fire("", errorString, "error");
  }

  getDisplayCriteria() {
    switch (this.chartTypeForQuery) {
      case "pchart":
        this.yTopicLabel = "Topic";
        this.yVariableLabel = "Variable";
        this.yValueLabel = "Value:";
        this.displayXCriteria = false;
        this.displayAllHH = false;
        this.displayYValue = false;
        break;
      case "map":
        this.yTopicLabel = "Topic";
        this.yVariableLabel = "Variable";
        this.yValueLabel = "Value:";
        this.displayXCriteria = false;
        this.displayAllHH = false;
        this.displayMapConfig = true;
        break;
      case "sbar":
        this.displayMultiYear = true;
        this.displayYValue = false;
        break;
      case "vbar":
        this.displayMultiYear = true;
        break;
      default:
    }
  }

  checkForTilde() {
    this.msanote = msaHasTilde(
      "code",
      this.selectedMSA.toString(),
      this.msadata
    );
  }

  clearGeoSelections() {
    this.selectedState = 0;
    this.selectedMSA = 0;
    this.selectedRegion = 0;
  }

  resetMultiYear(year: string) {
    if ("multi" === year) {
      this.displayXCriteria = false;
      this.selectedXTopic = "";
      this.selectedXVariable = "";
      this.selectedXValue = [];
      this.displayAllHH = false;
    } else if (
      "pchart" !== this.chartTypeForQuery &&
      "map" !== this.chartTypeForQuery
    ) {
      this.displayXCriteria = true;
      this.displayAllHH = true;
    }
  }
}
