import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from 'src/app/services/api.service';
import { GenericModel } from 'src/app/models/GenericModel.model';
import { msaHasTilde } from 'src/app/common/shared';
import { Router } from '@angular/router';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { SurveyValidation } from 'src/app/models/surveyData.model';

@Component({
  selector: 'app-compare-areas-dropdown',
  templateUrl: './compare-areas-dropdown.component.html',
  styleUrls: ['./compare-areas-dropdown.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(0)" })),
      state("rotated", style({ transform: "rotate(90deg)" })),
      transition("rotated => default", animate("300ms ease-out")),
      transition("default => rotated", animate("300ms ease-in")),
    ]),
  ],
})
export class CompareAreasDropdownComponent implements OnInit {

  @Input() latestSurveyYear: number;
  state: string = "default";
  Api: ApiService;
  @Input() States: any[];
  @Input() MSAS: GenericModel[];
  @Input() Regions: GenericModel[];
  msanote1: string;
  msanote2: string;
  selectedArea: string = "Nation";

  selectedState: string = "";
  selectedMSA: string = "";
  selectedRegion: string = "";
  selectedArea2: string = "Nation";

  selectedState2: string = "";
  selectedMSA2: string = "";
  selectedRegion2: string = "";
  constructor(private apiService: ApiService, private formValidationService: FormValidationService, private router: Router) { this.Api = apiService; }

  ngOnInit(): void {
  }

  checkForTilde() {
    if (this.selectedMSA.length > 0) {
      this.msanote1 = msaHasTilde(
        "code",
        this.selectedMSA.toString(),
        this.MSAS
      );
    }

    if (this.selectedMSA2.length > 0) {
      this.msanote2 = msaHasTilde(
        "code",
        this.selectedMSA2.toString(),
        this.MSAS
      );
    }
  }

  rotate(event: any) {
    this.state = this.state === "default" ? "rotated" : "default";
  }

  submit() {

    const validationSuccess = this.handleValidation();
    if (validationSuccess) {

    const route = '/place-compare';
      let area = this.selectedArea;
    let location = 'Nation';
      switch (this.selectedArea) {
      case 'Region': location = this.selectedRegion; break;
      case 'State': location = this.selectedState; break;
        case 'MSA': location = this.selectedMSA; break;
    }

    let area2 = this.selectedArea2;
    let location2 = 'Nation';

    switch (this.selectedArea2) {
      case 'Region': location2 = this.selectedRegion2; break;
      case 'State': location2 = this.selectedState2; break;
      case 'MSA': location2 = this.selectedMSA2; break;
    }
    return this.router.navigate([`${route}`], { queryParams: { area, location, area2, location2 } });
    } else {
      console.log('invalid selections')
    }
  }

  handleValidation(): boolean {
    let valid1: boolean = true;
    let valid2: boolean = true;
    if ((this.selectedArea == 'State' && this.selectedState.length == 0) || (this.selectedArea == 'Region' && this.selectedRegion.length == 0) || (this.selectedArea == 'MSA' && this.selectedMSA.length == 0)) {
      valid1 = false;
    }
    if ((this.selectedArea2 == 'State' && this.selectedState2.length == 0) || (this.selectedArea2 == 'Region' && this.selectedRegion2.length == 0) || (this.selectedArea2 == 'MSA' && this.selectedMSA2.length == 0)) {
      valid2 = false;
    }

    const validationObj: SurveyValidation = {
      validationFields: ["place-compare"],
    };

    if (!(valid1 && valid2))
      return this.formValidationService.startValidation(validationObj);
    else
      return valid1 && valid2;
  }


}
