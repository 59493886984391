import { Component, Input, OnInit } from '@angular/core';
import { PlaceDataService } from 'src/app/services/place-data.service';

@Component({
  selector: 'app-state-data-dropdown',
  templateUrl: './state-data-dropdown.component.html',
  styleUrls: ['./state-data-dropdown.component.scss']
})
export class StateDataDropdownComponent {

  constructor(public placedataservice: PlaceDataService) {
  }

  @Input() latestSurveyYear: number;

  selectedState: string = "";

  @Input() States: any[];
}
