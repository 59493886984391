import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartDataSource } from 'src/app/models/chartTool.model';

@Component({
  selector: 'app-chart-export',
  templateUrl: './chart-export.component.html',
  styleUrls: ['./chart-export.component.scss']
})
export class ChartExportComponent implements OnInit {

  @Output() exportEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  // Handling exportChart in main component because updates weren't being passed in this component
  exportChart(format: string): void {
    this.exportEvent.emit(format);
  }
}
