import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { placeChartConfig } from 'src/app/common/Chart';
import { msaHasTilde } from 'src/app/common/shared';
import { ChartCategory, ChartDataset, ChartDataSource, TableDataForChart } from 'src/app/models/chartTool.model';
import { GenericModel } from 'src/app/models/GenericModel.model';
import { SurveyDataRequestBody, SurveyDataMetadata, SurveyEvent, GeographyFilterModel, SurveyValidation } from 'src/app/models/surveyData.model';
import { ApiService } from 'src/app/services/api.service';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-compare-tool-criteria',
  templateUrl: './compare-tool-criteria.component.html',
  styleUrls: ['./compare-tool-criteria.component.scss']
})
export class CompareToolCriteriaComponent implements OnInit {

  @Input() latestSurveyYear: number;
  statedata: any[];
  msadata: any[];
  regiondata: any[];
  selectedState1 = 0;
  selectedMSA1 = 0;
  selectedArea1: string = "National";
  selectedRegion1 = 0;
  selectedState2 = 0;
  selectedMSA2 = 0;
  selectedArea2: string = "National";
  selectedRegion2 = 0;
  msanote1: string = "";
  msanote2: string = "";
  dataTitle: string;
  dataSubtitle: string;
  toolTypeTitle: string;
  selectedMSA = 0;
  originalCategories: ChartCategory[] = [
    {
      category: [],
    },
  ];
  originalDataset: ChartDataset[];
  dataSource1: ChartDataSource = {
    chart: {}
  };

  dataSource2: ChartDataSource = {
    chart: {}
  };

  type: string;
  columnHeaders: GenericModel[];
  tableData: any[][];
  chartTypeForQuery = "vbar";
  requestBody1: SurveyDataRequestBody;
  requestMetadata1: SurveyDataMetadata;
  requestBody2: SurveyDataRequestBody;
  requestMetadata2: SurveyDataMetadata;
  tableHeaderRow: any[] = [];
  showChart = true;
  loading = true;
  msaCode: number = 0;

  title = "Unbanked Rates by Year for ";

  geographyFilterObject: GeographyFilterModel;

  @Input() compareAreaObject: GeographyFilterModel;

  @Output() updateChartEvents = new EventEmitter<SurveyEvent[]>();

  @Output() chartTitleEvents = new EventEmitter<string[]>();

  @Output() compareAreaEvents = new EventEmitter<SurveyEvent[]>();

  constructor(private apiService: ApiService, private formValidationService: FormValidationService) {
    this.setChartConfigDefault();
  }

  async ngOnInit(): Promise<void> {
    this.getDropdownValues();
  }

  async getDropdownValues() {

    await this.apiService.getStateNames().then((x) => {
      this.statedata = x;
    });

    await this.apiService.getMsas().then((x) => (this.msadata = x));

    await this.apiService.getRegions().then((x) => (this.regiondata = x));

    this.setCompareAreaRequestBody();
  }

  async getStates() {
    await this.apiService.getStateNames().then((x) => {
      this.statedata = x;
    });
  }

  async getMsas() {
    await this.apiService.getMsas().then((x) => (this.msadata = x));
  }

  async getRegions() {
    await this.apiService.getRegions().then((x) => (this.regiondata = x));
  }

  setCompareAreaRequestBody() {
    this.geographyFilterObject = {
      area1: this.compareAreaObject.area1,
      locationCode1: this.compareAreaObject.locationCode1,
      area2: this.compareAreaObject.area2,
      locationCode2: this.compareAreaObject.locationCode2,
    }

    this.setChartTitles(this.geographyFilterObject);

    this.requestBody1 = {
      chartType: this.chartTypeForQuery,
      year: "multi",
      colVar: "hunbnk",
      rowvars: ["hhincome"],
      needNewData: true,
      filterVar: "",
      filterVar2: "",
      region: 0,
      state: 0,
      msa: 0,
    };
    this.requestMetadata1 = {
      yVariableTopic: "Bank Account Ownership",
      yVariableText: "Unbanked",
      area: this.compareAreaObject.area1,
      year: "multi",
      colVarValue: ["Unbanked"],
      filterText: "",
      filterText2: "",
    };

    this.requestBody2 = {
      chartType: this.chartTypeForQuery,
      year: "multi",
      colVar: "hunbnk",
      rowvars: ["hhincome"],
      needNewData: true,
      filterVar: "",
      filterVar2: "",
      region: 0,
      state: 0,
      msa: 0,
    };
    this.requestMetadata2 = this.requestMetadata1;
    this.requestMetadata2.area = this.compareAreaObject.area2;

    //set place compare page dropdowns also
    this.selectedArea1 = this.compareAreaObject.area1;
    this.selectedArea2 = this.compareAreaObject.area2;

    if ('Nation' != this.compareAreaObject.area1) {
      if ("Region" === this.compareAreaObject.area1) {
        this.requestBody1.region = this.compareAreaObject.locationCode1;
        this.selectedRegion1 = this.compareAreaObject.locationCode1;
      } else
        if ("MSA" === this.compareAreaObject.area1) {
          this.requestBody1.msa = this.compareAreaObject.locationCode1;
          this.selectedMSA1 = this.compareAreaObject.locationCode1;
        } else if ("State" === this.compareAreaObject.area1) {
          this.requestBody1.state = this.compareAreaObject.locationCode1;
          this.selectedState1 = this.compareAreaObject.locationCode1;
        }
    }

    if ('Nation' != this.compareAreaObject.area2) {
      if ("Region" === this.compareAreaObject.area2) {
        this.requestBody2.region = this.compareAreaObject.locationCode2;
        this.selectedRegion2 = this.compareAreaObject.locationCode2;
      } else
        if ("MSA" === this.compareAreaObject.area2) {
          this.requestBody2.msa = this.compareAreaObject.locationCode2;
          this.selectedMSA2 = this.compareAreaObject.locationCode2;
        } else if ("State" === this.compareAreaObject.area2) {
          this.requestBody2.state = this.compareAreaObject.locationCode2;
          this.selectedState2 = this.compareAreaObject.locationCode2;
        }
    }

    this.compareAreaEvents.emit([{
      requestBody: this.requestBody1,
      requestMetadata: this.requestMetadata1,
    }, {
      requestBody: this.requestBody2,
      requestMetadata: this.requestMetadata2,
    }]);
  }

  clearAreaSelections(areaBlock: number): void {
    if (areaBlock === 1) {
      this.selectedRegion1 = 0;
      this.selectedState1 = 0;
      this.selectedMSA1 = 0;
    } else {
      this.selectedRegion2 = 0;
      this.selectedState2 = 0;
      this.selectedMSA2 = 0;
    }
  }

  checkForTilde() {
    if (this.selectedMSA1 > 0) {
      this.msanote1 = msaHasTilde(
      "code",
      this.selectedMSA1.toString(),
      this.msadata
    );
    }

    if (this.selectedMSA2 > 0) {
      this.msanote2 = msaHasTilde(
        "code",
        this.selectedMSA2.toString(),
        this.msadata
      );
    }
  }

  updateChart(): void {
    let locationCode1 = 0;
    let locationCode2 = 0;

    if ('Nation' != this.selectedArea1) {
      if ("Region" === this.selectedArea1) {
        locationCode1 = this.selectedRegion1;
      } else
        if ("MSA" === this.selectedArea1) {
          locationCode1 = this.selectedMSA1;
        } else if ("State" === this.selectedArea1) {
          locationCode1 = this.selectedState1;
        }
    }

    if ('Nation' != this.selectedArea2) {
      if ("Region" === this.selectedArea2) {
        locationCode2 = this.selectedRegion2;
      } else
        if ("MSA" === this.selectedArea2) {
          locationCode2 = this.selectedMSA2;
        } else if ("State" === this.selectedArea2) {
          locationCode2 = this.selectedState2;
        }
    }

    this.geographyFilterObject = {
      area1: this.selectedArea1,
      locationCode1: locationCode1,
      area2: this.selectedArea2,
      locationCode2: locationCode2,
    }


    const validationSuccess = this.handleValidation();

    if (validationSuccess) {
      this.setChartTitles(this.geographyFilterObject);
      // Set variables needed

      this.requestBody1 = {
        chartType: this.chartTypeForQuery,
        year: "multi",
        colVar: "hunbnk",
        rowvars: ["hhincome"],
        needNewData: true,
        filterVar: "",
        filterVar2: "",
        region: this.selectedRegion1,
        state: this.selectedState1,
        msa: this.selectedMSA1,
      };
      this.requestMetadata1 = {
        yVariableTopic: "Bank Account Ownership",
        yVariableText: "Unbanked",
        area: this.selectedArea1,
        year: "multi",
        colVarValue: ["Unbanked"],
        filterText: "",
        filterText2: "",
      };

      this.requestBody2 = {
        chartType: this.chartTypeForQuery,
        year: "multi",
        colVar: "hunbnk",
        rowvars: ["hhincome"],
        needNewData: true,
        filterVar: "",
        filterVar2: "",
        region: this.selectedRegion2,
        state: this.selectedState2,
        msa: this.selectedMSA2,
      };
      this.requestMetadata2 = this.requestMetadata1;

      this.updateChartEvents.emit([{
        requestBody: this.requestBody1,
        requestMetadata: this.requestMetadata1,
      }, {
        requestBody: this.requestBody2,
        requestMetadata: this.requestMetadata2,
      }]
      );


    }
  }

  handleValidation(): boolean {
    let valid1: boolean = true;
    let valid2: boolean = true;
    if ((this.selectedArea1 == 'State' && this.selectedState1 === 0) || (this.selectedArea1 == 'Region' && this.selectedRegion1 === 0) || (this.selectedArea1 == 'MSA' && this.selectedMSA1 === 0)) {
      valid1 = false;
    }
    if ((this.selectedArea2 == 'State' && this.selectedState2 === 0) || (this.selectedArea2 == 'Region' && this.selectedRegion2 === 0) || (this.selectedArea2 == 'MSA' && this.selectedMSA2 === 0)) {
      valid2 = false;
    }

    const validationObj: SurveyValidation = {
      validationFields: ["place-compare"],
    };

    if (!(valid1 && valid2))
      return this.formValidationService.startValidation(validationObj);
    else
      return valid1 && valid2;
  }

  setChartTitles(geographyFilter: GeographyFilterModel): void {

    let title1: string = '';
    let title2: string = '';

    title1 = this.title + 'US';
    title2 = this.title + 'US';

    if ("Region" === geographyFilter.area1) {
      let regiondesc = this.regiondata.find(
        (o) => o.Code === geographyFilter.locationCode1
      );
      title1 = this.title + regiondesc.Value;
    } else
      if ("MSA" === geographyFilter.area1) {
        let msadesc = this.msadata.find(
          (o) => o.Code === geographyFilter.locationCode1
        );
        title1 = this.title + msadesc.Value;
      } else if ("State" === geographyFilter.area1) {
        let statedesc = this.statedata.find(
          (o) => o.Code === geographyFilter.locationCode1
        );
        title1 = this.title + statedesc.FullText;
      }

    if ("Region" === geographyFilter.area2) {
      let regiondesc = this.regiondata.find(
        (o) => o.Code === geographyFilter.locationCode2
      );
      title2 = this.title + regiondesc.Value;
    } else
      if ("MSA" === geographyFilter.area2) {
        let msadesc = this.msadata.find(
          (o) => o.Code === geographyFilter.locationCode2
        );
        title2 = this.title + msadesc.Value;
      } else if ("State" === geographyFilter.area2) {
        let statedesc = this.statedata.find(
          (o) => o.Code === geographyFilter.locationCode2
        );
        title2 = this.title + statedesc.FullText;
      }


    this.chartTitleEvents.emit([title1, title2]);
  }


  setChartConfigDefault(): void {

    this.type = "mscolumn2d";
    // this.toolTypeTitle = "Vertical";
    this.chartTypeForQuery = "vbar";
    this.requestBody1 = {
      chartType: this.chartTypeForQuery,
      year: "multi",
      colVar: "hunbnk",
      rowvars: ["hhincome"],
      needNewData: true,
      filterVar: "",
      filterVar2: "",
      region: 1,
      state: 0,
      msa: 0,
    };

    this.requestBody2 = this.requestBody1;

    this.requestMetadata1 = {
      yVariableTopic: "Bank Account Ownership",
      yVariableText: "Unbanked",
      area: "", //this.placeDataService.curTypeTitle,
      year: "multi",
      colVarValue: ["Unbanked"],

      filterText: "",
      filterText2: "",
    };

    this.requestMetadata2 = this.requestMetadata1;

    this.dataSource1.chart = placeChartConfig;

    this.dataSource2.chart = placeChartConfig;
  }


}
