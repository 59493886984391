import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { SurveyValidation } from '../models/surveyData.model';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  validationObj: SurveyValidation

  constructor() { }

  startValidation(validationObj: SurveyValidation): boolean {
    this.validationObj = validationObj;
    return this.validationChecker();
  }

  // Check to make sure each input is valid. If not, return error string
  validationChecker(): boolean {
    for (let i = 0; i < this.validationObj.validationFields.length; i++) {
      const currentField = this.validationObj.validationFields[i];
      const result = this.validateInputs(currentField);
      if (result !== 'success') {
        this.alertInvalidFormOnSubmit(result);
        return false;
      }
    }
    return true;
  }

  validateInputs(field: string): string {
    const { area, region, state, msa, yTopic, yVariable, yValue, xTopic, xVariable, xValue, topic, variable, year, chartType } = this.validationObj;

    if (field === 'geography' && (area !== 'National' && region === 0 && state === 0 && msa === 0)) {
      return 'Please finish selecting a geography.'
    }
    if (field === 'yItems' && (yTopic === '' || yVariable === '' || yValue.length === 0)) {
      if (chartType === 'pchart') {
        return 'Please choose a topic and variable.';
      }
      if (chartType === 'map') {
        return 'Please choose a topic, variable, and value.'
      }
      return 'Please choose a Y topic, Y variable, and Y value.'
    }
    // Pie chart and multiyear for chart do not need X items
    if (field === 'xItems' && year !== 'multi' && chartType !== 'pchart' && chartType !== 'map' && (xTopic === '' || xVariable === '' || xValue.length === 0)) {
      return 'Please choose an X topic, X variable, and X value.'
    }
    if (field === 'items' && (topic === '' || variable === '')) {
      return 'Please choose a topic and a variable.'
    }
    if (field === 'five-year-state' && state === 0) {
      return 'Please select a state.'
    }
    if (field === 'five-year-msa' && msa === 0) {
      return 'Please select an MSA.'
    }
    if (field === 'place-compare') {
      return 'Please finish selecting areas for comparison.'
    }
    return 'success';
  }

  alertInvalidFormOnSubmit(errorString: string): void {
    Swal.fire({
      text: errorString,
      icon: 'error',
      heightAuto: false
    })
  }
}
