import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from '../../services/api.service';
import { GenericModel } from 'src/app/models/GenericModel.model';
import { CustomDataModel } from 'src/app/models/CustomDataModel';
import { SurveyDataRequestBody, SurveyValidation } from 'src/app/models/surveyData.model';
import { ScriptsService } from 'src/app/services/scripts.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { msaHasTilde } from "src/app/common/shared";
import { ExcelExportService } from "src/app/services/excel-export.service";
import { PdfExportService } from "src/app/services/pdf-export.service";
import { DataTableService } from "src/app/services/data-table.service";

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.scss"],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(90deg)" })),
      state("rotated", style({ transform: "rotate(0)" })),
      transition("rotated => default", animate("300ms ease-out")),
      transition("default => rotated", animate("300ms ease-in")),
    ]),
  ],
  //encapsulation: ViewEncapsulation.Nonenpm
})
export class CustomTableComponent implements OnInit {
  Api: ApiService;
  AreaSelection: string = "Nation";
  differ: any;
  TopicSelection: string = "";
  state: string = "default";
  VariableSelection: string = "";
  StateSelection: string = "";
  MSASelection: string = "";
  RegionSelection: string = "";
  YearSelection: string;
  States: any[];
  MSAS: GenericModel[];
  Regions: GenericModel[];
  years: string[];
  CustomData: CustomDataModel[];
  SubtitleData: any[];
  Topics: CustomDataModel[];
  CustomDataFiltered: CustomDataModel[];
  ShowCommonvariables: string = "yes";
  VariableSelectionobj: CustomDataModel;
  CustomDataDistinct: CustomDataModel[];
  TableData: any;
  tableHeaderRow: any[] = [];
  multiHeaderRow: any[][] = [];
  Math = Math;
  TableReady: boolean = false;
  FilterHeaderText: string = "";
  subtitle: string = "";
  TopicsnotBasic: CustomDataModel[] = [];
  CustomDataRowVars: CustomDataModel[];
  DefualtRowVar: string[] = [
    "praceeth3",
    "pagegrp",
    "peducgrp",
    "pempstat",
    "hhincome",
    "pdisabl_age25to64",
    "gtmetsta",
  ];
  DefaultRowVarDisplay: string[] = [
    "Race/Ethnicity",
    "Age group",
    "Education",
    "Employment status",
    "Family income",
    "Disability status",
    "Metropolitan and nonmetropolitan status",
  ];
  RowVars: CustomDataModel[][] = [];
  FilterVars: CustomDataModel[];
  FilterVars2: CustomDataModel[];
  FilterVarsValues: CustomDataModel[];
  FilterVarsValuesFiltered1: CustomDataModel[];
  FilterVarsValuesFiltered2: CustomDataModel[];
  FilterVarSelection1: string = "";
  FilterVarSelection2: string = "";
  Filtervardata1: CustomDataModel[] = [];
  Filtervardata2: CustomDataModel[] = [];

  filterTopics: CustomDataModel[] = [];

  DefaultrowVarselectionlist: string[] = [
    "praceeth3",
    "pagegrp",
    "peducgrp",
    "pempstat",
    "hhincome",
    "pdisabl_age25to64",
    "gtmetsta",
  ];
  Additonalrowvarselctionlist: string[] = [];
  Multiyear: boolean = false;

  TopicFilter1: string = "";
  TopicFilter2: string = "";

  regionText: string = "";

  dataHasNA: boolean = false;
  dataHasDash: boolean = false;
  colVarNote: string = "";
  rowVarNotes: string[] = [];
  filterVarNotes: string[] = [];
  msanote: string = "";

  loading: boolean;

  footnotes: string[] = [];
  dataTableType: string = "";

  previousGeoSelection: string = "";

  surveyDataRequestBodyObject: SurveyDataRequestBody = {
    colVar: "",
    colVarValue: [],
    rowvars: [],
    chartType: "",
    year: "",
    region: 0,
    state: 0,
    msa: 0,
    filterVar: "",
    filterVarValue: [],
    filterVar2: "",
    filterVarValue2: [],
  };

  constructor(
    private apiService: ApiService,
    private dataTableService: DataTableService,
    private scripts: ScriptsService,
    private formValidationService: FormValidationService,
    private excelExportService: ExcelExportService,
    private pdfExportService: PdfExportService
  ) {
    this.Api = apiService;
  }

  async ngOnInit(): Promise<void> {
    this.scripts.loadHeaderFooterScript();
    // Need to run years first to run the rest of the dropdown queries
    this.years = this.dataTableService.GetYears();
    this.YearSelection = this.years[0];
    console.log(this.YearSelection);
    this.GetStates();
    this.GetMSAS();
    this.GetRegions();
    this.GetCustomData();
    this.GetCustomDataDistinct();
    this.GetTopics(this.YearSelection);
    this.getAdditionalFilterTopics(this.YearSelection, "n");
    this.GetDataForSubTitle();
    this.previousGeoSelection = this.AreaSelection;
  }

  prepareFootnotes() {
    this.footnotes = [];
    this.filterVarNotes = [];
    //note for data source: "Source: 2017 FDIC National Survey of Unbanked and Underbanked Households."
    var year = this.YearSelection;
    if (this.YearSelection == "multi") year = "Multiyear";
    if (year === "2023" ) {
      this.footnotes.push(
        "Source: 2023 FDIC National Survey of Unbanked and Underbanked Households."
      );
    } 
    if (year === "2021" ) {
      this.footnotes.push(
        "Source: 2021 FDIC National Survey of Unbanked and Underbanked Households."
      );
    } 
    if(year=="2019")
    {
      this.footnotes.push("Source: 2019 FDIC Survey of Household Use of Banking and Financial Services.")
    }
    if( (+year >= 2013 && +year <=2017))
    {
      this.footnotes.push(`Source: ${year} Survey of Unbanked and Underbanked Households`)
      this.footnotes.push(`Estimates may not match previously published estimates because missing values in the ${year} data have been replaced with imputed data.`)

    }
    if(+year<2013)
    {
      this.footnotes.push(`Source: ${year} Survey of Unbanked and Underbanked Households`)

    }
    if(year == "Multiyear")
    {
      this.footnotes.push("Source: Multiyear FDIC National Surveys of Unbanked and Underbanked Households.")
      this.footnotes.push("2017 estimates may not match previously published estimates because missing values in the 2017 data have been replaced with imputed data.")
    }
    //note if MSA with ~ is selected
    if (
      this.AreaSelection == "MSA" &&
      this.MSASelection.length > 0 &&
      this.MSAS[
        this.MSAS.findIndex((x) => x.Code == this.MSASelection)
      ].Value.includes("~")
    ) {
      this.footnotes.push(
        "~ indicates areas with geographic boundary changes between the 2013 and 2015 surveys."
      );
    }
    //note if there's NA in data: "NA indicates that the sample size is too small to produce a precise estimate."
    if (this.dataHasNA) {
      this.footnotes.push(
        "NA indicates that the sample size is too small to produce a precise estimate."
      );
    }
    //note if there's - in data: " - indicates an estimate of zero. The population proportion may be slightly greater than zero."
    if (this.dataHasDash) {
      this.footnotes.push(
        " - indicates an estimate of zero. The population proportion may be slightly greater than zero."
      );
    }

    //note for filter var
    if (
      this.surveyDataRequestBodyObject.filterVar &&
      this.surveyDataRequestBodyObject.filterVarValue.length > 0
    ) {
      var index = this.FilterVars.findIndex(
        (x) => x.VariableName == this.surveyDataRequestBodyObject.filterVar
      );
      if (index > -1 && this.FilterVars[index].Notes != null)
        this.filterVarNotes.push(this.FilterVars[index].Notes);

      if (
        this.surveyDataRequestBodyObject.filterVar2 &&
        this.surveyDataRequestBodyObject.filterVarValue2.length > 0
      ) {
        var index = this.FilterVars2.findIndex(
          (x) => x.VariableName == this.surveyDataRequestBodyObject.filterVar2
        );
        if (index > -1 && this.FilterVars2[index].Notes != null)
          this.filterVarNotes.push(this.FilterVars2[index].Notes);
      }

      if (this.filterVarNotes.length > 0) {
        for (var i = 0; i < this.filterVarNotes.length; i++) {
          this.footnotes.push(this.filterVarNotes[i]);
        }
      }
    }

    //note for selected colvar
    if (this.surveyDataRequestBodyObject.colVar) {
      let colVarObj = this.CustomDataFiltered.find(
        (o) => o.VariableName === this.VariableSelection
      );
      this.colVarNote = colVarObj.Notes;

      if (this.colVarNote) this.footnotes.push(this.colVarNote);
    }
    //note for selected rowvars
    if (this.rowVarNotes != undefined && this.footnotes != undefined) {
      if (
        this.surveyDataRequestBodyObject.rowvars &&
        this.rowVarNotes.length > 0
      ) {
        for (var i = 0; i < this.rowVarNotes.length; i++) {
          this.footnotes.push(this.rowVarNotes[i]);
        }
      }
    }
    // Need to filter out any duplicate footnotes (if topics, filters are same)
    this.footnotes = this.footnotes.filter(
      (val, idx, self) => self.indexOf(val) === idx
    );
    for (var i = 0; i< this.footnotes.length;i++)
    {
      if(this.footnotes[i].length==0)
      {
       this.footnotes.splice(i,1);
      }
    }
    
  }

  checkForTilde() {
    this.msanote = msaHasTilde("code", this.MSASelection, this.MSAS);
  }

  GetStates() {
    this.Api.getStateNames().then((x) => (this.States = x));
  }
  GetMSAS() {
    this.Api.getMsas().then((x) => (this.MSAS = x));
  }
  GetRegions() {
    this.Api.getRegions().then((x) => (this.Regions = x));
  }
  GetCustomData() {
    this.Api.getCustomData().then((x) => (this.CustomData = x));
  }
  GetDataForSubTitle() {
    this.Api.getDataForSubTitle().then((x) => (this.SubtitleData = x));
  }
  GetCustomDataDistinct() {
    this.Api.getCustomDataDistinct().then((x) => (this.CustomDataDistinct = x));
  }
  GetTopics(year: string) {
    this.Api.getTopics(this.YearSelection).then((x) => (this.Topics = x));
    this.TopicSelection = "";
    this.MSASelection = "";
    this.ShowCommonvariables =  'yes';
    this.resetFilterTopicData(this.YearSelection);
  }

  getFilterVariables(topic: string) {
    this.FilterVarSelection1 = "";
    this.Filtervardata1 = [];
    this.FilterVars = [];
    this.FilterVarsValuesFiltered1 = [];
    this.CustomDataDistinct.forEach((element) => {
      if (
        element.Topic == topic &&
        element.Filter == "y" &&
        element.ValidYears.includes(this.YearSelection)
      ) {
        if (this.AreaSelection == "Nation") {
          this.CustomDataRowVars.push(element);
          this.FilterVars.push(element);
        } else {
          if (element.DisableFilterBelowNational != "y") {
            this.CustomDataRowVars.push(element);
            this.FilterVars.push(element);
          }
        }
      }
    });
  }
  getFilterVariablesSecondary(topic: string) {
    this.FilterVarSelection2 = "";
    this.Filtervardata2 = [];
    this.FilterVars2 = [];
    this.FilterVarsValuesFiltered2 = [];
    this.CustomDataDistinct.forEach((element) => {
      if (
        element.Topic == topic &&
        element.Filter == "y" &&
        element.ValidYears.includes(this.YearSelection)
      ) {
        if (this.AreaSelection == "Nation") {
          this.CustomDataRowVars.push(element);
          this.FilterVars2.push(element);
        } else {
          if (element.DisableFilterBelowNational != "y") {
            this.CustomDataRowVars.push(element);
            this.FilterVars2.push(element);
          }
        }
      }
    });
  }
  FilterVarValues(varselection: string) {
    this.FilterVarsValuesFiltered1 = [];
    this.FilterVarsValues.forEach((element) => {
      if (element.VariableName == varselection && element.Code != "-1") {
        this.FilterVarsValuesFiltered1.push(element);
        this.FilterVarsValuesFiltered1.sort((a, b) =>
          a.Code.localeCompare(b.Code)
        );
      }
      if(element.VariableName == varselection && varselection=="gestfips")
      {
        this.FilterVarsValuesFiltered1.sort((a, b) =>
        a.Value.localeCompare(b.Value));
      }
    });
  }
  FilterVarValuesSecondary(varselection: string) {
    this.FilterVarsValuesFiltered2 = [];
    this.FilterVarsValues.forEach((element) => {
      if (element.VariableName == varselection && element.Code != "-1") {
        this.FilterVarsValuesFiltered2.push(element);
        this.FilterVarsValuesFiltered2.sort((a, b) =>
          a.Code.localeCompare(b.Code)
        );
      }
      if(element.VariableName == varselection && varselection=="gestfips")
      {
        this.FilterVarsValuesFiltered2.sort((a, b) =>
        a.Value.localeCompare(b.Value));
      }
    });
  }
  FilterrowVariables() {
    this.CustomDataRowVars = [];
    var notes: string[] = [];
    this.CustomDataDistinct.forEach((element) => {
      //check if topic matches, then chheck if its a filter variable,

      if (
        this.TopicsnotBasic.findIndex((x) => x.Topic == element.Topic) != -1
      ) {
        //check the valid years
        if (
          element.ValidYears.includes(this.YearSelection) &&
          element.RowVariable == "y" &&
          element.DefaultRowVariable != "y"
        ) {
          this.CustomDataRowVars.push(element);
        }
      }
      //loop to get notes for selected rowvars if any exist
      this.surveyDataRequestBodyObject.rowvars.forEach(function (rowVar) {
        if (element.VariableName == rowVar) {
          if (element.Notes != null) {
            if (notes.findIndex((note) => note == element.Notes) < 0) {
              notes.push(element.Notes);
            }
          }
        }
      });
    });

    /*if (notes.length > 0) {
      this.rowVarNotes = notes;
    }*/
    this.rowVarNotes = notes;
    this.RowVars = [];
    this.CustomDataRowVars.forEach((ele) => {
      let index = this.TopicsnotBasic.findIndex((x) => x.Topic == ele.Topic);
      if (index != -1) {
        if (this.RowVars[index] == undefined) {
          this.RowVars[index] = [];
        }
        if(ele.VarLabel!= "State")
        this.RowVars[index].push(ele);
        else if (ele.VarLabel=="State"  && (this.AreaSelection == "Nation" || this.AreaSelection =="Region") )
        {
          this.RowVars[index].push(ele);
        }
      }
    });
    for (var i = 0; i < this.RowVars.length; i++) {
      if (this.RowVars[i] == undefined) {
        // this.TopicsnotBasic.splice(i,1);
        continue;
      }
      this.RowVars[i].sort(function (a, b) {
        return a.VarLabel < b.VarLabel ? -1 : 1;
      });
    }
    var topicslength = this.TopicsnotBasic.length + 0;
    for (var i = 0; i < topicslength; i++) {
      if (this.RowVars[i] == undefined) {
        this.TopicsnotBasic[i] = null
      }
    }
  }

  onCheckboxDefaultRowChange(option, event) {
    if (event.target.checked) {
      this.DefaultrowVarselectionlist.push(option);
    } else {
      for (var i = 0; i < this.DefaultrowVarselectionlist.length; i++) {
        if (this.DefaultrowVarselectionlist[i] == option) {
          this.DefaultrowVarselectionlist.splice(i, 1);
        }
      }
    }
  }
  SelectallDefaultRowVarList() {
    var checkboxes = document.getElementsByName("DefaultRowVarCheckBox");
    checkboxes.forEach((x) => ((<HTMLInputElement>x).checked = true));
    this.DefualtRowVar.forEach((x) => {
      if (!this.DefaultrowVarselectionlist.includes(x))
        this.DefaultrowVarselectionlist.push(x);
    });
  }
  ClearDefaultRowVarList() {
    var checkboxes = document.getElementsByName("DefaultRowVarCheckBox");
    checkboxes.forEach((x) => ((<HTMLInputElement>x).checked = false));

    this.DefaultrowVarselectionlist = [];
  }
  ClearAdditonalRowVars() {
    var checkboxes = document.getElementsByName("AdditonalRowVarCheckBox");
    checkboxes.forEach((x) => ((<HTMLInputElement>x).checked = false));
    this.Additonalrowvarselctionlist = [];
  }
  onCheckboxRowChange(option, event) {
    if (event.target.checked) {
      this.Additonalrowvarselctionlist.push(option);
    } else {
      for (var i = 0; i < this.Additonalrowvarselctionlist.length; i++) {
        if (this.Additonalrowvarselctionlist[i] == option) {
          this.Additonalrowvarselctionlist.splice(i, 1);
        }
      }
    }
  }

  FilterVariablesDistinct(topic: string, commonvariable: string = this.ShowCommonvariables) {
    if (topic === ""){
      this.TopicSelection = "";
    }
    this.CustomDataFiltered = [];
    this.VariableSelection = "";
    if (commonvariable == "yes") {
      this.Api.getTopics(this.YearSelection).then((x) => (this.Topics = x));
    }
    else {
      this.Api.getTopics(this.YearSelection, 'no').then((x) => (this.Topics = x));
    }
    this.CustomDataDistinct.forEach((element) => {
      //check if topic matches, then chheck if its a filter variable, if or if it can display despite it being a filter
      if (element.Topic == this.TopicSelection && element.ColVariable == "y") {
        //check for the radio buttons, common=yes see all = no
        if (commonvariable == "yes") {
          if (element.BasicList == "y") {
            //check the valid years
            if (element.ValidYears.includes(this.YearSelection)) {
              this.CustomDataFiltered.push(element);
            }
          }
        }
        //its not common push it only if the the radio button is no
        else {
          if (element.ValidYears.includes(this.YearSelection)) {
            this.CustomDataFiltered.push(element);
          }
        }
      }
    });
    // Check if topic exists on Topic list, if not reset Topic List
    const topicExists = this.CustomDataFiltered.some((element) => element.Topic === this.TopicSelection );
    if (!topicExists){
      this.TopicSelection = "";
    }
  }

  setSurveyDataRequestBody(): void {
    this.surveyDataRequestBodyObject = {
      colVar: "",
      colVarValue: [],
      rowvars: [],
      chartType: "",
      year: "",
      region: 0,
      state: 0,
      msa: 0,
      filterVar: "",
      filterVarValue: [],
      filterVar2: "",
      filterVarValue2: [],
    };
    this.surveyDataRequestBodyObject.year = this.YearSelection;
    this.surveyDataRequestBodyObject.colVar = this.VariableSelection;
    this.surveyDataRequestBodyObject.state = +this.StateSelection;
    this.surveyDataRequestBodyObject.msa = +this.MSASelection;
    this.surveyDataRequestBodyObject.region = +this.RegionSelection;
    this.surveyDataRequestBodyObject.rowvars = [
      "praceeth3",
      "pagegrp",
      "peducgrp",
      "pempstat",
      "hhincome",
      "pdisabl_age25to64",
      "gtmetsta",
    ];
    if (this.YearSelection == "2009") {
      this.surveyDataRequestBodyObject.rowvars[4] = "hhincome2";
    }
  }

  setSurveyDataRequestBodyFilters(): void {
    this.surveyDataRequestBodyObject = {
      colVar: "",
      colVarValue: [],
      rowvars: [],
      chartType: "",
      year: "",
      region: 0,
      state: 0,
      msa: 0,
      filterVar: "",
      filterVarValue: [],
      filterVar2: "",
      filterVarValue2: [],
    };

    this.surveyDataRequestBodyObject.year = this.YearSelection;
    this.surveyDataRequestBodyObject.colVar = this.VariableSelection;
    this.surveyDataRequestBodyObject.state = +this.StateSelection;
    this.surveyDataRequestBodyObject.msa = +this.MSASelection;
    this.surveyDataRequestBodyObject.region = +this.RegionSelection;
    this.surveyDataRequestBodyObject.rowvars =
      this.DefaultrowVarselectionlist.concat(this.Additonalrowvarselctionlist);
    if (
      this.surveyDataRequestBodyObject.rowvars.indexOf("hhincome") != -1 &&
      this.YearSelection == "2009"
    ) {
      this.surveyDataRequestBodyObject.rowvars[
        this.surveyDataRequestBodyObject.rowvars.indexOf("hhincome")
      ] = "hhincome2";
    }
    this.surveyDataRequestBodyObject.filterVar = this.FilterVarSelection1;
    let codearray1: string[] = [];
    this.Filtervardata1.forEach((e) => codearray1.push(e.Code));
    this.surveyDataRequestBodyObject.filterVarValue = codearray1; //this.Filtervardata1;
    this.surveyDataRequestBodyObject.filterVar2 = this.FilterVarSelection2;
    let codearray2: string[] = [];
    this.Filtervardata2.forEach((e) => codearray2.push(e.Code));
    this.surveyDataRequestBodyObject.filterVarValue2 = codearray2; //this.Filtervardata2;
  }

  getData(update: boolean): void {
    this.loading = true;
    this.TableReady = false;
    var accord = document.getElementById("collapserow");
    if (accord.classList.contains("show")) {
      document.getElementById("changevariable").click();
    }

    if (!update) {
      this.setSurveyDataRequestBody();
    } else {
      this.setSurveyDataRequestBodyFilters();
    }
    // this.apiService.getCustomTableData(this.surveyDataRequestBodyObject).then((response: any) => {
    this.apiService
      .getSurveyData(this.surveyDataRequestBodyObject)
      .then((response: any) => {
        if (response) {
        
            this.Api.getFilterData().then((y) => {
              this.FilterVarsValues = y;
              this.TableData = response;
              if (!this.Multiyear) {
                this.organizetable();
              } else {
                this.organizemultiyeartable();
              }
            });
          
        
        }
        else
        {
          this.createTableWithNoData();
        }
      });
    this.Api.getTopics(this.YearSelection, "no").then((x) => {
      this.TopicsnotBasic = x;

      this.FilterrowVariables();
    });
  }
  createTableWithNoData() {
    //this.showExport = false;
    //this.TableAllRecords = [];
    this.tableHeaderRow = [];
    this.multiHeaderRow = [];
    const tablestring = '<tr> <td style="border: none; padding:0"> <b> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </b></td></tr>';
    const tbodySingleYear = document.getElementsByTagName('tbody')[0];
    const tbodyMultiYear = document.getElementsByTagName('tbody')[1];
    if (this.Multiyear) {
      tbodySingleYear.innerHTML = '';
      tbodyMultiYear.innerHTML = tablestring;
      let yearHeaderRow = document.getElementById("yearHeaderRow");
      yearHeaderRow.innerHTML = '';
    } else {
      tbodySingleYear.innerHTML = tablestring;
      tbodyMultiYear.innerHTML = '';
    }

    this.TableReady = true;
  }

  organizetable() {
    let { tablestring, dataHasNA, tableHeaderRow, dataHasDash } =
      this.dataTableService.organizetable(
        this.TableData,
        this.surveyDataRequestBodyObject
      );
      if(tablestring == "")
      {
        this.createTableWithNoData();
        this.loading = false;
        this.TableReady = true;
        this.createSubtitle();
        return;
      }
    this.dataHasNA = dataHasNA;
    this.tableHeaderRow = tableHeaderRow;
    this.dataHasDash = dataHasDash;

    let tbody = document.getElementsByTagName("tbody")[0];
    tbody.innerHTML = tablestring;
    this.loading = false;
    this.TableReady = true;
    this.getFilterHeaderText();
    this.createSubtitle();
    this.prepareFootnotes();
  }

  submit(update: boolean = false) {
    if (false === this.validateSelectionCriteria()) {
      return;
    }

    if (!update) {
      //reset the filter vars if you arent updating so the preivous filter vars dont affect anything.
      this.FilterVars = [];
      this.FilterVars2 = [];
      this.FilterVarsValues = [];
      this.FilterVarsValuesFiltered1 = [];
      this.FilterVarsValuesFiltered2 = [];
      this.FilterVarSelection1 = "";
      this.FilterVarSelection2 = "";
      this.Filtervardata1 = [];
      this.Filtervardata2 = [];
      this.DefaultrowVarselectionlist = [
        "praceeth3",
        "pagegrp",
        "peducgrp",
        "pempstat",
        "hhincome",
        "pdisabl_age25to64",
        "gtmetsta",
      ];
      this.Additonalrowvarselctionlist = [];

      this.TopicFilter1 = "";
      this.TopicFilter2 = "";
      this.ClearAdditonalRowVars();
      this.SelectallDefaultRowVarList();
      //area selection isnt being reset properly
      this.StateSelection =
        this.AreaSelection == "State" ? this.StateSelection : "";
      this.MSASelection = this.AreaSelection == "MSA" ? this.MSASelection : "";
      this.RegionSelection =
        this.AreaSelection == "Region" ? this.RegionSelection : "";
        this.resetFilterTopicData(this.YearSelection);
    }
    if (this.YearSelection == "multi") {
      this.Multiyear = true;
    } else {
      this.Multiyear = false;
    }
    this.getData(update);

  }

  clearSelections() {
    this.YearSelection = this.years[0];
    this.AreaSelection = "Nation";
    this.TopicSelection = "";
    this.VariableSelection = "";
    this.StateSelection = "";
    this.MSASelection = "";
    this.RegionSelection = "";
    this.FilterVars = [];
    this.FilterVars2 = [];
    this.FilterVarsValues = [];
    this.FilterVarsValuesFiltered1 = [];
    this.FilterVarsValuesFiltered2 = [];
    this.FilterVarSelection1 = "";
    this.FilterVarSelection2 = "";
    this.Filtervardata1 = [];
    this.Filtervardata2 = [];
    this.DefaultrowVarselectionlist = [
      "praceeth3",
      "pagegrp",
      "peducgrp",
      "pempstat",
      "hhincome",
      "pdisabl_age25to64",
      "gtmetsta",
    ];
    this.Additonalrowvarselctionlist = [];
    this.TopicFilter1 = "";
    this.TopicFilter2 = "";
    this.TableReady = false;
   
  }

  validateSelectionCriteria(): boolean {
    if (
      this.AreaSelection === "State" ||
      this.AreaSelection === "MSA" ||
      this.AreaSelection === "Region"
    ) {
      const stateNumber =
        this.StateSelection === "" ? 0 : parseInt(this.StateSelection);
      const msaNumber =
        this.MSASelection === "" ? 0 : parseInt(this.MSASelection);
      const regionNumber =
        this.RegionSelection === "" ? 0 : parseInt(this.RegionSelection);
      const validationFields = ["geography", "items"];
      const validationObj: SurveyValidation = {
        validationFields,
        area: this.AreaSelection,
        state: stateNumber,
        msa: msaNumber,
        region: regionNumber,
        topic: this.TopicSelection,
        variable: this.VariableSelection,
      };
      return this.formValidationService.startValidation(validationObj);
    } else {
      const validationFields = ["items"];
      const validationObj: SurveyValidation = {
        validationFields,
        topic: this.TopicSelection,
        variable: this.VariableSelection,
      };
      return this.formValidationService.startValidation(validationObj);
    }
  }

  //multiyear table [year][rowvar][value] everything should be the same as regular table just done 3 times.
  organizemultiyeartable() {
    let { tablestring, yearHeaderString, multiHeaderRow, dataHasNA, dataHasDash } =
      this.dataTableService.organizemultiyeartable(
        this.TableData,
        this.surveyDataRequestBodyObject
      );
    this.dataHasNA = dataHasNA;
    this.dataHasDash = dataHasDash;
    this.multiHeaderRow = multiHeaderRow;

    let tbody = document.getElementsByTagName("tbody")[1];
    tbody.innerHTML = tablestring;
    let yearHeaderRow = document.getElementById("yearHeaderRow");
    yearHeaderRow.innerHTML = yearHeaderString;
    this.loading = false;
    this.TableReady = true;
    this.getFilterHeaderText();
    this.createSubtitle();
    this.prepareFootnotes();
  }

  rotate(event: any) {
    this.state = this.state === "default" ? "rotated" : "default";
  }

  getFilterHeaderText() {
    let region = this.CustomDataFiltered.find(
      (o) => o.VariableName === this.VariableSelection
    );
    this.FilterHeaderText = region.VarLabel.trimEnd();

    this.regionText = "Nation";

    if (this.AreaSelection == "State") {
      let state = this.States.find((o) => o.Code === this.StateSelection);
      this.regionText = state.FullText.trimEnd();
    } else if (this.AreaSelection == "MSA") {
      let msa = this.MSAS.find((o) => o.Code === this.MSASelection);
      this.regionText = msa.Value.trimEnd();
    } else if (this.AreaSelection == "Region") {
      let region = this.Regions.find((o) => o.Code === this.RegionSelection);
      this.regionText = region.Value.trimEnd();
    }

    var year = this.YearSelection;
    if (this.YearSelection == "multi") year = "MultiYear";

    this.FilterHeaderText +=
      " for " +
      this.regionText +
      ", " +
      year +
      " by Selected Household Characteristics ";
  }

  createSubtitle() {
    this.subtitle = this.dataTableService.createSubtitle(this.surveyDataRequestBodyObject);
   /* this.subtitle = "";

    if (this.surveyDataRequestBodyObject.colVar) {
      let title = this.SubtitleData.find(
        (o) => o.VariableName === this.surveyDataRequestBodyObject.colVar
      );
      if (title != null && title.Notes != null) this.subtitle = title.Notes;
    }
    if (this.subtitle == "") {
      this.subtitle = "All households";
    }
    if (this.surveyDataRequestBodyObject.filterVar) {
      var element = this.FilterVarsValues.find(
        (x) => x.VariableName == this.surveyDataRequestBodyObject.filterVar
      );
      this.subtitle += ", " + element.VarLabel + " is ";
      this.surveyDataRequestBodyObject.filterVarValue.forEach((ele) => {
        var value = this.FilterVarsValues.find(
          (x) =>
            x.Code == ele &&
            x.VariableName == this.surveyDataRequestBodyObject.filterVar
        );
        this.subtitle += value.Value + ", ";
      });
      this.subtitle = this.subtitle.substring(
        0,
        this.subtitle.lastIndexOf(",")
      );
      //var value = this.FilterVarsValues.find(x=> x.Code == this.surveyDataRequestBodyObject.filterVarValue &&  x.VariableName==this.surveyDataRequestBodyObject.filterVar)
    }
    if (this.surveyDataRequestBodyObject.filterVar2) {
      var element = this.FilterVarsValues.find(
        (x) => x.VariableName == this.surveyDataRequestBodyObject.filterVar2
      );
      // var value = this.FilterVarsValues.find(x=> x.Code == this.surveyDataRequestBodyObject.filterVarValue2 &&  x.VariableName==this.surveyDataRequestBodyObject.filterVar2)
      this.subtitle += ", " + element.VarLabel + " is ";
      this.surveyDataRequestBodyObject.filterVarValue2.forEach((ele) => {
        var value = this.FilterVarsValues.find(
          (x) =>
            x.Code == ele &&
            x.VariableName == this.surveyDataRequestBodyObject.filterVar2
        );
        this.subtitle += value.Value + ", ";
      });
      this.subtitle = this.subtitle.substring(
        0,
        this.subtitle.lastIndexOf(",")
      );
    }*/
  }

  exportexcel(): void {
    // Identify the file name to use
    let excelFileName =
      this.FilterHeaderText.trimEnd()
        .replace(/[,|~|.]/g, "")
        .replace(/\s/g, "_");

     if (excelFileName.length > 100) {
       excelFileName = excelFileName.slice(0, 99);
     }

     excelFileName = excelFileName + ".csv";

    // Get the table id to export
    let element =
      "multi" === this.dataTableType
        ? document.getElementById("DataTableMulti")
        : document.getElementById("DataTable");

    let footnotes = document.getElementById("footnotes").querySelectorAll("li");
    let headnote =
      '"' +
      this.FilterHeaderText.trimEnd() +
      '"' +
      "\n" +
      '"' +
      this.subtitle +
      '"' +
      "\n\n";

    // Call Excel Service
    this.excelExportService.exportToExcel(
      excelFileName,
      element,
      headnote,
      footnotes
    );
  }

  public exportpdf() {
    let pdfFileName = this.FilterHeaderText.replace(/[,|~|.]/g, "").replace(
      /\s/g,
      "_"
    );
    let element =
      "multi" === this.dataTableType
        ? document.getElementById("DataTableMulti")
        : document.getElementById("DataTable");
    let footnote = document.getElementById("footnotes");
    
    let pdfParams = {
      fileName: pdfFileName,
      dataTable: element,
      title: this.FilterHeaderText,
      subTitle: this.subtitle,
      footNote: footnote,
    };
    
    this.pdfExportService.downloadPDF(pdfParams);
  }

  export(event, tableType) {
    this.dataTableType = tableType || "";
    if ("downloadcsv" === event.srcElement.classList[1]) {
      this.exportexcel();
    } else if ("downloadpdf" === event.srcElement.classList[1]) {
      this.exportpdf();
    }
  }

  getAdditionalFilterTopics(year: string, nation: string) {
    this.apiService
      .getChartFilterTopics(this.YearSelection, nation)
      .then((x) => {
        this.filterTopics = x;
      });
  }

  getAdditionalFilterVariables(topic: string, filterType: string) {
    this.CustomDataDistinct.forEach((element) => {
      //check if topic matches, then chheck if its a filter variable, if or if National is selected as Area
      if ("Nation" === this.AreaSelection) {
        if (
          element.Topic === topic &&
          (element.Filter === "y" || element.DisableFilterBelowNational === "y")
        ) {
          if (element.ValidYears.includes(this.YearSelection)) {
            if ("filter1" === filterType) {
              this.FilterVars.push(element);
            } else {
              this.FilterVars2.push(element);
            }
          }
        }
      } else {
        // If anything other than National
        if (
          element.Topic === topic &&
          element.Filter === "y" &&
          element.DisableFilterBelowNational !== "y"
        ) {
          if (element.ValidYears.includes(this.YearSelection)) {
            if ("filter1" === filterType) {
              this.FilterVars.push(element);
            } else {
              this.FilterVars2.push(element);
            }
          }
        }
      }
    });
  }

  resetFilterTopicData(year: string) {
    let nation = "n";
    if ("Nation" !== this.AreaSelection) {
      nation = "y";
    }
    this.resetFilterDisplay();
    this.getAdditionalFilterTopics(year, nation);
  }

  resetFilterTopicForGeo() {
    let belowNational = ["State", "MSA", "Region"];
    if (
      (this.AreaSelection === "Nation" &&
        belowNational.includes(this.previousGeoSelection)) ||
      (belowNational.includes(this.AreaSelection) &&
        this.previousGeoSelection === "Nation")
    ) {
      this.resetFilterTopicData(this.YearSelection);

    }
    this.previousGeoSelection = this.AreaSelection;
    this.FilterrowVariables();
    this.resetFilterDisplay();
  }

  resetFilterDisplay(): void {
    this.TopicFilter1 = "";
    this.FilterVarSelection1 = "";
    this.Filtervardata1 = [];
    this.FilterVars = [];
    this.FilterVarsValuesFiltered1 = [];
    this.TopicFilter2 = "";
    this.FilterVarSelection2 = "";
    this.Filtervardata2 = [];
    this.FilterVars2 = [];
    this.FilterVarsValuesFiltered2 = [];
  }

  getFilter1Variables(topic: string) {
    if (topic === "") {
      this.FilterVarSelection1 = "";
      this.Filtervardata1 = [];
    } else {
      this.FilterVars = [];
      this.getAdditionalFilterVariables(topic, "filter1");
    }
  }

  getFilter1VariablesChange(topic: string) {
    this.FilterVarSelection1 = "";
    this.Filtervardata1 = [];
    this.FilterVars = [];
    this.FilterVarsValuesFiltered1 = [];
    this.getFilter1Variables(topic);
  }

  getFilter2Variables(topic: string) {
    if (topic === "") {
      this.FilterVarSelection2 = "";
      this.Filtervardata2 = [];
    } else {
      this.FilterVars2 = [];
      this.getAdditionalFilterVariables(topic, "filter2");
    }
  }

  getFilter2VariablesChange(topic: string) {
    this.FilterVarSelection2 = "";
    this.Filtervardata2 = [];
    this.getFilter2Variables(topic);
  }

  clearGeoSelections() {
    this.StateSelection = "";
    this.MSASelection = "";
    this.RegionSelection = "";
    this.resetFilterTopicForGeo();
  }
}
