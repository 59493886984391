import { Component, OnInit, Input } from '@angular/core';
import { PlaceDataService } from 'src/app/services/place-data.service';

@Component({
  selector: 'app-national-data-dropdown',
  templateUrl: './national-data-dropdown.component.html',
  styleUrls: ['./national-data-dropdown.component.scss']
})
export class NationalDataDropdownComponent {

  @Input() latestSurveyYear: number;

  selectedNation: string = "";

  constructor(public placedataservice: PlaceDataService) {
  }
}
