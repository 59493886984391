import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericModel } from 'src/app/models/GenericModel.model';
import { ApiService } from 'src/app/services/api.service';
import { DataTableService } from 'src/app/services/data-table.service';
import { ScriptsService } from 'src/app/services/scripts.service';

@Component({
  selector: "app-map-page",
  templateUrl: "./map-page.component.html",
  styleUrls: ["./map-page.component.scss"],
})
export class MapPageComponent implements OnInit {
  data: Object;
  mapyear: number;
  dataSource: any = {};
  chartdata: any = [];
  Api: ApiService;
  States: any[];
  MSAS: GenericModel[];
  Regions: GenericModel[];
  mapSurveyData: any;
  regionsObj: any = {};
  markersObj: any = {};
  latestSurveyYear: number;
  allowDrillDown: boolean = true;
  loading = true;
  selectedgeo: string = "state";
  years: string[];

  mapGeoConfig = {
    chart: {
      showLabels: "0",
      fillColor: "#0075c2",
      showBorder: "1",
      borderColor: "#eeeeee",
    },
  };

  constructor(
    private scripts: ScriptsService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private dataTableService: DataTableService
  ) {
    this.Api = apiService;
  }

  getLatestSurveyYear(): void {
    this.years = this.dataTableService.GetYears();
    this.latestSurveyYear = parseInt(this.years[0]);
  }

  GeoChangeHandler(geo: string) {
    this.selectedgeo = geo;
    //console.log(geo);
  }
  GetStates() {
    this.Api.getStateNames().then((x) => (this.States = x));
  }
  GetMSAS() {
    this.Api.getMsas().then((x) => (this.MSAS = x));
  }
  GetRegions() {
    this.Api.getRegions().then((x) => (this.Regions = x));
  }

  setMapChartProperties() {
    const { year } = this.route.snapshot.queryParams;
    const numericYear = !isNaN(parseInt(year))
      ? parseInt(year)
      : this.latestSurveyYear;
    this.mapyear = this.latestSurveyYear;
    if (numericYear < this.latestSurveyYear && this.years.includes(year)) {
      this.mapyear = numericYear;
      this.allowDrillDown = false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.scripts.loadHeaderFooterScript();
    this.GetStates();
    this.GetMSAS();
    this.GetRegions();
    // setMapChartProperties is dependent on getting the year first
    this.getLatestSurveyYear();
    this.setMapChartProperties();

    this.loadMapPageScripts();
    document.addEventListener("DOMContentLoaded", function (event) {
      var ww = document.body.clientWidth;
      if (ww < 720) {
        let a = document.getElementsByClassName("col-8");
        a[0].className += " col";
        a[0].classList.remove("col-8");

        let b = document.getElementsByClassName("ml-5");
        b[0].classList.remove("ml-5");
      }
    });

    this.loading = false;

  }

  onResize($event) {
    var ww = document.body.clientWidth;
    if (ww < 720) {
      let a = document.getElementsByClassName("col-8");
      if (a.length > 0) {
        a[0].className += " col";
        a[0].classList.remove("col-8");
      }
      let b = document.getElementsByClassName("ml-5");
      if (b.length > 0) {
        b[0].classList.remove("ml-5");
      }
    } else {
      let a = document.getElementsByClassName("mapcol");
      if (a.length > 0) {
        a[0].className += " col-8";
        a[0].classList.remove("col");
      }
      let b = document.getElementsByClassName("maprow");
      if (b.length > 0) {
        b[0].className += " ml-5";
      }
    }
  }

  loadMapPageScripts() {
    const body = <HTMLDivElement>document.body;
    const script1 = document.createElement("script");
    script1.innerHTML = "";
    script1.src = "src/assets/mapdata/usmapsvg.js";
    script1.async = false;
    script1.defer = true;
    body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = "";
    script2.src = "src/assets/mapdata/global.js";
    script2.async = false;
    script2.defer = true;
    body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.innerHTML = "";
    script3.src = "src/assets/mapdata/inclusion.js";
    script3.async = false;
    script3.defer = true;
    body.appendChild(script3);
  }
}
