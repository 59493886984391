const masterConfig = {
  "caption": "",
  "subCaption": "",
  "baseFontSize": "13",
  "bgColor": "ffffff",
  "bgalpha": "100",
  "captionFont": "Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif",
  "baseFont": "Helvetica,Arial,sans-serif",
  "baseFontColor": "#003256",
  "valueFontColor": "#003256",
  "rotateValues": "0",
  "captionFontSize": "1.125rem",
  "captionFontBold": "1",
  "subCaptionFontSize": "1rem",
  "subCaptionFontBold": "0",
  "paletteColors": "#003256, #B7AC83, #38B6FF,#C8326C,#20509E,#A48923,#00908C",
  "decimals": "1",
  "forceYAxisValueDecimals": "0",
  "forceXAxisValueDecimals": "0",
  "drawCustomLegendIcon": "1",
  "legendIconSides": "4",
  "showvalues": "1",
  "showBorder": "0",
  "showLabels": "1",
  "adjustDiv": "0",
  "drawanchors": "0",
  "labelpadding": "10",
  "useroundedges": "0",
  "legendIconScale": "2",
  "legendborderalpha": "0",
  "legendShadow": "0",
  "legendBorderColor": "ffffff",
  "legendIconBorderColor": "000000",
  "legendIconBorderThickness": "1",
  "legendNumColumns": "4",
  "canvasBorderThickness": "0",
  "canvasBorderColor": "ffffff",
  "showAlternateHGridColor": "0",
  "showAlternateVGridColor": "0",
  "showShadow": "0",
  "showPlotBorder": "1",
  "plotBorderColor": "000000",
  "plotGradientColor": " ",
  "exportEnabled": "1",
  "exportMode": "server",
  "exportShowMenuItem": "0",
}

const vbarSpecifics = {
  "valueposition": "outside",
  "yAxisMaxvalue": "10",
  "yAxisMinValue": "0",
  "numDivLines": "4",
  "divlinealpha": "70",
  "yaxisvaluespadding": "10",
  "placeValuesInside": "0",
  "forceDecimals": "1",
  "numberSuffix": "%",
  "canvasTopPadding": "30",
  "canvasRightPadding": "0",
}

export const vbarChartConfig = Object.assign({}, masterConfig, vbarSpecifics);

const hbarSpecifics = {
  "valueposition": "outside",
  "yAxisMaxvalue": "10",
  "yAxisMinValue": "0",
  "numDivLines": "4",
  "divlinealpha": "70",
  "yaxisvaluespadding": "10",
  "placeValuesInside": "0",
  "forceDecimals": "1",
  "numberSuffix": "%",
  "canvasRightPadding": "40",
  "canvasTopPadding": "0",
}

export const hbarChartConfig = Object.assign({}, masterConfig, hbarSpecifics);

const sbarVertSpecifics = {
  "valueFontColor": "#ffffff",
  "yAxisMaxvalue": "100",
  "yAxisMinValue": "0",
  "numDivLines": "4",
  "divlinealpha": "70",
  "forceDecimals": "1",
  "numberSuffix": "%",
  "canvasTopPadding": "20",
  "canvasRightPadding": "0",
}

export const sbarVertChartConfig = Object.assign({}, masterConfig, sbarVertSpecifics);

const sbarHorSpecifics = {
  "valueFontColor": "#ffffff",
  "yAxisMaxvalue": "100",
  "yAxisMinValue": "0",
  "numDivLines": "4",
  "divlinealpha": "70",
  "yaxisvaluespadding": "10",
  "forceDecimals": "1",
  "numberSuffix": "%",
  "canvasRightPadding": "40",
  "canvasTopPadding": "0",
}

export const sbarHorChartConfig = Object.assign({}, masterConfig, sbarHorSpecifics);

export const pieSpecifics = {
  "showpercentageinlabel": "1",
  "showlabels": "0",
  "showlegend": "1",
  "use3dlighting": "0",
  "showshadow": "0",
  "radius3D": "0",
  "showTickMarks": "1",
  "showTickValues": "1",
  "showLimits": "1",
  "forceDecimals": "1",
  "numberSuffix": "%",
}

export const pieChartConfig = Object.assign({}, masterConfig, pieSpecifics);

const mapSpecifics = {
  "valueposition": "outside",
  "entityFillHoverColor": "#B7AC83",
  "nullEntityColor": "#eeeeee",
  "numberScaleValue": "100",
  "numberScaleUnit": "K,M,B",
  "numberPrefix": "",
  "showLegend": "1",
  "legendPosition": "bottom",
  "legendBgColor": "#ffffff",
  "showBorder": "1",
  "borderColor": "ffffff",
  "borderThickness": "1",
  "showshadow": "0",
  "forceDecimals": "0",
}

export const mapChartConfig = Object.assign({}, masterConfig, mapSpecifics);

export const mapPlaceDataConfig = {
  caption: '',
  subcaption: "",
  showPlotBorder: "0",
  palettecolors: "#003256",
  numbersuffix: "%",
  includevalueinlabels: "1",
  labelsepchar: ": ",
  toolTipBgColor: "#FFFFFF",
  toolTipBorderColor: "#CCCCCC",
  showentitytooltip: "1",
  showentityhovereffect: "1",
  showlabels: "0",
  entityfillhovercolor: "#B7AC83",
  entityfillcolor: "#003256",
  nullentitycolor: "#003256",
  nullEntityAlpha: "100",
  theme: "fusion",
  fillColor: '#003256',
  borderColor: '#FFFFFF',
  borderThickness: "1",
  showLegend: "0",
  entityToolText: '<div class="map-infobox">$value</div>'
}

export const placeChartConfig = {
  "width": "75%",
  "height": "100%",
  "caption": "",
  "subCaption": "",
  "baseFontSize": "13",
  "bgColor": "ffffff",
  "bgalpha": "100",
  "captionFont": "Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif",
  "captionFontSize": "15",
  "captionFontBold": "1",
  "baseFont": "Helvetica,Arial,sans-serif",
  "baseFontColor": "#0C4D81",
  "valueFontColor": "#0C4D81",
  "subCaptionFontSize": "13",
  "subCaptionFontBold": "0",
  "paletteColors": "#003256, #B7AC83, #38B6FF,#C8326C,#20509E,#A48923,#00908C",
  // "palette": "3",
  "decimals": "1",
  "forceDecimals": "1",
  "numberSuffix": "%",
  "forceYAxisValueDecimals": "0",
  "forceXAxisValueDecimals": "0",
  "drawCustomLegendIcon": "1",
  "legendIconSides": "4",
  "showvalues": "1",
  "showBorder": "0",
  "adjustDiv": "0",
  "yAxisMaxvalue": "10",
  "yAxisMinValue": "0",
  "numDivLines": "4",
  "divlinealpha": "70",
  "drawanchors": "0",
  "labelpadding": "10",
  "yaxisvaluespadding": "10",
  "useroundedges": "0",
  "legendIconScale": "2",
  "legendborderalpha": "0",
  "legendShadow": "0",
  "legendBorderColor": "ffffff",
  "legendIconBorderColor": "000000",
  "legendIconBorderThickness": "1",
  "canvasBorderThickness": "0",
  "canvasBorderColor": "ffffff",
  "canvasTopPadding": "20",
  "showAlternateHGridColor": "0",
  "showShadow": "0",
  "showPlotBorder": "1",
  "plotBorderColor": "000000",
  "plotGradientColor": " ",
  "placeValuesInside": "0",
}