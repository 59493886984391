import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlaceDataService } from 'src/app/services/place-data.service';
import { ActivatedRoute } from "@angular/router";
import { getContentURL } from 'src/app/util/config';
import { DataTableService } from "../../services/data-table.service";

@Component({
  selector: "app-place-data-header",
  templateUrl: "./place-data-header.component.html",
  styleUrls: ["./place-data-header.component.scss"],
})
export class PlaceDataHeaderComponent implements OnInit {
  serverAppendUrl = getContentURL();
  data: Object;
  @Input() latestSurveyYear: number;
  placeTitle: string;
  typeTttle: string;
  pageTitle: string;
  msaNumber: number;
  actualPlaceTitle: string;

  constructor(
    private placeDataService: PlaceDataService,
    private route: ActivatedRoute,
    private dataTableService: DataTableService
  ) {
    this.setComponentProperties();
  }

  ngOnInit(): void {
    this.latestSurveyYear = parseInt(this.dataTableService.GetYears()[0]);
  }
  setComponentProperties(): void {
    const { type, area } = this.route.snapshot.queryParams;

    this.placeTitle = area;

    this.typeTttle = type;

    this.placeDataService.typeName = this.typeTttle;
    this.placeDataService.placeName = this.placeTitle;

    this.placeDataService.areaName = this.placeTitle;
    this.actualPlaceTitle = this.placeDataService.placeName;
  }
}
