import { Component, EventEmitter, OnInit,Output } from '@angular/core';

@Component({
  selector: 'app-geography-options-bar',
  templateUrl: './geography-options-bar.component.html',
  styleUrls: ['./geography-options-bar.component.scss']
})
export class GeographyOptionsBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Output() GeoSelect : EventEmitter<number> = new EventEmitter();
  public showError(txt) {
    $('#place').empty();
    var myparent = document.getElementById('place');
    var myalert = document.createElement('p');
    myalert.setAttribute('role', 'alert');
    myalert.setAttribute('class', 'screen_readers_only');
    myalert.appendChild(document.createTextNode('The map has reloaded with the view by ' + txt + '.'));
    //myparent.appendChild(myalert);
    this.GeoSelect.emit(txt);
  }

}
