import { Component, Input, OnInit } from '@angular/core';
import { PlaceDataService } from 'src/app/services/place-data.service';

@Component({
  selector: 'app-msa-data-dropdown',
  templateUrl: './msa-data-dropdown.component.html',
  styleUrls: ['./msa-data-dropdown.component.scss']
})
export class MsaDataDropdownComponent {

  constructor(public placedataservice: PlaceDataService) {
  }

  @Input() latestSurveyYear: number;

  selectedMSA: string = "";

  @Input() MSAS: any[];
}

