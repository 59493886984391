export const checkEnv = (): string => {
  let currentEnv = 'prod';
  if (window.location.origin.includes('-d.fdic.gov')) {
    currentEnv = 'dev';
  } else if (window.location.origin.includes('-s.fdic.gov')) {
    currentEnv = 'systest';
  } else if (window.location.origin.includes('-q.fdic.gov')) {
    currentEnv = 'qa';
  } else if (window.location.origin.includes('localhost')) {
    currentEnv = 'local';
  }

  return currentEnv;
}

export const getAPIURL = (): string => {

  const currentEnv = checkEnv();
  let apiURL = 'https://ei-app.app.cloud.gov'; //for prod by default
  console.log('Current environment', currentEnv);

  if (currentEnv === 'qa') {
    apiURL = 'https://ei-app-q.app.cloud.gov';
  } else if (currentEnv === 'systest') {
    apiURL = 'https://ei-app-s.app.cloud.gov';
  } else if (currentEnv === 'dev' || currentEnv === 'local') {
    apiURL = 'https://ei-app-d.app.cloud.gov';
  }
  // console.log('api url:: ', apiURL)
  return apiURL;
}

export const getContentURL = (): string => {

  const currentEnv = checkEnv();
  let contentUrl = 'https://www.fdic.gov'; //for prod by default
  console.log('Current environment', currentEnv);

  // if (currentEnv != 'prod') {
  //   contentUrl = 'https://fdic-wwwdev.app.cloud.gov';
  // }
  console.log('content url:: ', contentUrl)
  return contentUrl;
}