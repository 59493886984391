import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as rowdata from 'src/assets/mapdata/mapdata.json';
import { StringDecoder } from 'node:string_decoder';
import { ApiService } from "src/app/services/api.service";
import { getContentURL } from 'src/app/util/config';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { DataTableService } from './data-table.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceDataService {
  SurveyYear: number;
  curPlaceTitle: string;
  curTypeTitle: string;
  curAreaTitle: string;
  stateAbbr: string;
  msadata: any[];
  curRegion = 0;
  curState = 0;
  curMSA: 0;
  curSlug: string;
  curYoy: string;
  cRange: number;
  rowdata: Object;
  urlfileName: string;

  firstSurveyYear = 2009;
  fRange: number;
  fstRangeYear: string;
  curRangeYear: string;

  constructor(private apiService: ApiService, private formValidationService: FormValidationService, private dataTableService: DataTableService) {
    this.SurveyYear = parseInt(this.dataTableService.GetYears()[0]);
    this.cRange = this.SurveyYear - 4;
    this.curRangeYear = this.cRange + '-' + this.SurveyYear;
    this.fRange = this.firstSurveyYear + 4;
    this.fstRangeYear = this.firstSurveyYear + '-' + this.fRange;
  }
  get data(): any {
    return this.SurveyYear;
  }

  get placeName(): any {
    return this.curPlaceTitle
  }

  set placeName(val: any) {
    this.loadPlaceData(val)
  }

  get areaName(): any {
    return this.curAreaTitle
  }

  set areaName(valArea: any) {
    this.curAreaTitle = valArea;
  }

  get typeName(): any {
    return this.curTypeTitle
  }

  set typeName(valGeo: any) {
    this.curTypeTitle = valGeo;
  }

  get yoyName(): any {

    if (this.curTypeTitle == "msa" || this.curTypeTitle == "MSA") {
      this.curYoy = this.curPlaceTitle.replace(/[,|~|.]/g, "").replace(/[\s|_|/]/g, "-");    
      this.curYoy = this.curYoy.toLocaleLowerCase();
    }
    else {
      this.curYoy = this.stateAbbr.toLowerCase();
    }
    return this.curYoy;
  }

  loadPlaceData(area: any) {

    let mapdata = rowdata;

    for (var i = 0, len = mapdata.length; i < len; i++) {
      if (mapdata[i].Slug == area) {
        if (this.curTypeTitle == "state" || this.curTypeTitle == "region") {
          this.setAreaCode(mapdata[i].Code);
          this.stateAbbr = mapdata[i].Abbreviation;
        }
        this.curPlaceTitle = mapdata[i].Geography;
      }
    }
  }

  setAreaCode(codeValue: any) {
    if (this.curTypeTitle == "state") {
      this.curState = codeValue;
    }
    else if (this.curTypeTitle == 'region') {
      this.curRegion = codeValue;
    }
  }

  openPlaceDataPage(type: string, selection: any) {
    if (selection !== '') {
      let placeDataPageURLPrefix = 'place-data?type=' + type + '&area=';
      if (type.toLowerCase() === 'nation') {
        window.location.href = getContentURL() + '/analysis/household-survey/';
      } else if (type.toLowerCase() === 'msa') {
        window.location.href = `${window.location.origin}/${selection}`;
      } else {
        window.location.href = `${window.location.origin}/${placeDataPageURLPrefix}${selection.replace(/ /g, '_')}`;
      }
    } else {
      this.formValidationService.alertInvalidFormOnSubmit('Please select area');
    }
  }

}

