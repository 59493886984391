export function msaHasTilde(
  valueType: string,
  selectedValue: string,
  msadata: any[]
): string {
  let msa;
  if ("value" === valueType && selectedValue.includes("~")) {
    msa = msadata.find((o) => o.MsaSelect.trim() === selectedValue.trim());
    return msa.Notes;
  } else if ("code" === valueType) {
    msa = msadata.find((o) => o.Code === selectedValue);
    if (msa && msa.Value.includes("~")) return msa.Notes;
  } else {
    return "";
  }
}
