import { Component, Input, OnInit } from '@angular/core';
import { getContentURL } from 'src/app/util/config';

@Component({
  selector: 'app-place-page-header',
  templateUrl: './place-page-header.component.html',
  styleUrls: ['./place-page-header.component.scss']
})
export class PlacePageHeaderComponent {

  serverAppendUrl = getContentURL();

  @Input() mapyearTitle: string;
}
