import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomTableComponent } from "./custom-table/custom-table/custom-table.component";
import { TestapiComponent } from "./testapi/testapi.component";
import { FiveYearEstimateComponent } from "./five-year-estimate/five-year-estimate.component";
import { MainComponent } from "./main/main.component";
import { VerticalBarComponent } from "./chart-tool/vertical-bar/vertical-bar.component";
import { PlaceDataPageComponent } from "./place-data-page/place-data-page.component";
import { YoyAnalysisComponent } from "./place-data-page/yoy-analysis/yoy-analysis.component";
import { MapPageComponent } from './survey-map/map-page/map-page.component';
import { CompareToolComponent } from "./compare-tool/compare-tool.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

const routes: Routes = [
  { path: "", component: MapPageComponent },
  { path: "home", component: MapPageComponent },
  { path: "custom-data", component: CustomTableComponent },
  { path: "testapi", component: TestapiComponent },
  { path: "five-year", component: FiveYearEstimateComponent },
  { path: "custom-chart", component: VerticalBarComponent },
  { path: "place-data", component: PlaceDataPageComponent },
  { path: "yoy-analysis", component: YoyAnalysisComponent },
  { path: 'survey-map', component: MapPageComponent },
  { path: 'place-compare', component: CompareToolComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
