import { Injectable, OnInit } from "@angular/core";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class PdfExportService implements OnInit {
  docDefinition: any;

  constructor() {
    this.docDefinition = {
      content: [
        {
          text: "actual report title will be extracted from report",
          style: "title",
        },
        {
          text: "",
          style: "subtitle",
        },
        // Draw medium blue horizontal rule - two options, both with compromises:
        //   1) use canvas with hardcoded line width:
        //       {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 595-2*40, y2: 3, lineWidth: 2, lineColor: '#3f9ecd' }], style: 'subtitle'},
        //   2) use top border of a table - no hard coded numbers, but kind of a hack
        {
          table: {
            widths: ["*"],
            margin: [0, 0, 0, 0],
            body: [
              [
                {
                  text: "",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#3f9ecd";
            },
            hLineWidth: function (i, node) {
              return 3;
            },
          },
        },
        {
          // Placeholder for report data table - code in parseReport() below overwrites this object
          style: "tableMain",
          table: {
            headerRows: 1,
            dontBreakRows: true,
            body: [],
          },
          layout: "noBorders",
        },
      ],
      styles: {
        title: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 2],
        },
        subtitle: {
          fontSize: 10,
          bold: false,
          margin: [0, 1, 0, 6],
        },
        tableMain: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 8.5,
        },
        tableSubheader: {
          bold: true,
          fontSize: 8.5,
          margin: [0, 1, 0, 1],
        },
        tableRowFirstColumn: {
          bold: true,
          margin: [0, 1, 0, 1],
        },
        tableRow: {
          margin: [0, 1, 0, 1],
        },
      },
      defaultStyle: {
        fontSize: 8.5,
      },
      footer: function (currentPage, pageCount) {
        return {
          text: "Page " + currentPage.toString() + " of " + pageCount,
          alignment: "right",
          margin: [0, 5, 20, 0],
        };
      },
    };
  }

  ngOnInit() {}

  public downloadPDF(params: any) {
    //html to pdf format
    this.parseReport(params);
  
    pdfMake.createPdf(this.docDefinition).download(params.fileName);
  }

  parseReport(params: any) {
    this.docDefinition.content[0].text = params.title; //title;
    this.docDefinition.content[1].text = params.subTitle || ""; //subtitle;

    let table: any = params.dataTable;
    // pdfmake requires an entry for every column; get maxcols for the report
    let maxcols = 0;
    for (let i = 0; i < table.rows.length; i++) {
      if (table.rows[i].cells.length > maxcols) {
        maxcols = table.rows[i].cells.length;
      }
    }

    let pc = this.determinePageColumnPlacement(table, maxcols);
    let pagesWide = pc[pc.length - 1].page + 1;

    // Create empty tables for each page in docDefintion
    for (let p = 0; p < pagesWide; p++) {
      this.docDefinition.content[3 + p] = {
        style: "tableMain",
        table: {
          headerRows: 1,
          dontBreakRows: true,
          body: [],
        },
        layout: "noBorders",
        pageBreak: p < pagesWide - 1 ? "after" : "",
      };
    }

    var headerRows = 1;
    console.log(table.rows);
    for (var i = 0; i < table.rows.length; i++) {
      // Keep a default fg, bg and style for the row (to handle missing cells in the report)
      var fg = "#000000";
      var bg = "#ffffff";
      var style = "";
      const bgColor = window.getComputedStyle(
        table.rows[i],
        null
      ).backgroundColor;
      // Allocate row arrays for each page
      for (let p = 0; p < pagesWide; p++) {
        this.docDefinition.content[3 + p].table.body[i] = [];
      }

      for (let j = 0; j < maxcols; j++) {
        let txt = "";
        // Extract data from report cell if it exists
        if (table.rows[i].cells[j]) {
          txt = table.rows[i].cells[j].textContent;
          // console.log(window.getComputedStyle(table.rows[i].cells[j], null));
          fg = this.rgb2hex(
            window.getComputedStyle(table.rows[i].cells[j], null).color
          );
          bg =
            window.location.href.indexOf("five-year") > -1
              ? this.rgb2hex(
                  window.getComputedStyle(table.rows[i].cells[j], null)
                    .backgroundColor
                )
              : this.rgb2hex(bgColor);
          let className = table.rows[i].cells[j].className;
          if (className === "th") {
            style = "tableHeader";
            headerRows = i + 1;
          } else if (className === "separator") {
            style = "tableSubheader";
          } else if (j === 0) {
            style = "tableRowFirstColumn";
          } else {
            style = "tableRow";
          }
        }
        // Create cell in PDF doc definition using report data or defaults
        let page = pc[j].page;
        let col = pc[j].col;
        this.docDefinition.content[3 + page].table.body[i][col] = {
          text: txt,
          color: fg,
          fillColor: bg,
          style: style,
        };
        this.docDefinition.content[3 + page].table.headerRows = headerRows;
      }

      // Duplicate row label from first page on all subsequent pages (object clone is required by pdfmake)
      for (let p = 1; p < pagesWide; p++) {
        this.docDefinition.content[3 + p].table.body[i][0] = {
          text: this.docDefinition.content[3].table.body[i][0].text,
          color: this.docDefinition.content[3].table.body[i][0].color,
          fillColor: this.docDefinition.content[3].table.body[i][0].fillColor,
          style: this.docDefinition.content[3].table.body[i][0].style,
        };
      }
    }

    let listNotes;
    if (params.footNote.getElementsByTagName("li").length > 0) {
      listNotes = params.footNote.getElementsByTagName("li");
    } else if (params.footNote.getElementsByTagName("em").length > 0) {
      listNotes = params.footNote.getElementsByTagName("em");
    }
    this.docDefinition.content.splice(3+pagesWide+listNotes.length);
    if (listNotes.length > 0) {
      let noteDot = "• ";
      for (var i = 0; i < listNotes.length; i++) {
        this.docDefinition.content[3 + pagesWide + i] = {
          text: "",
        };
        this.docDefinition.content[3 + pagesWide + i] = {
          text: noteDot + " " + listNotes[i].innerText,
        };
      }
    } else {
      this.docDefinition.content[3 + pagesWide + i] = {
        text: "",
      };
    }
  }

  rgb2hex(rgb) {
    if (rgb.search("rgb") == -1) {
      return "#ffffff"; // assume transparent and return white
    } else {
      rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);

      if (rgb[4] && rgb[4] == "0") {
        return "#ffffff"; // color is transparent, assume white
      } else {
        return "#" + this.hex(rgb[1]) + this.hex(rgb[2]) + this.hex(rgb[3]);
      }
    }
  }

  hex(x) {
    return ("0" + parseInt(x).toString(16)).slice(-2);
  }

  determinePageColumnPlacement(table, maxcols) {
    let minColsPerPage = 3;
    let maxColsPerPage = 8;
    if (window.location.href.indexOf("five-year") > -1) {
      // Force CI columns to second page for Five Year Estimates
      maxColsPerPage = 6;
    }
    let pc = [];
    let col = 0;
    let page = 0;
    let prevHeaderCellEmpty = true;
    for (let j = 0; j < maxcols; j++) {
      // Use presence of text in the first row of the header as an indication of column grouping on wide reports
      let headerCellEmpty = true;
      if (table.rows[0].cells[j]) {
        if (table.rows[0].cells[j].textContent.length > 0) {
          headerCellEmpty = false;
        }
      }
      // Do we need a new page?
      if (
        col > maxColsPerPage ||
        (prevHeaderCellEmpty && !headerCellEmpty && col > minColsPerPage)
      ) {
        page++;
        col = 1; // reserve col 0 for repeated row label
      }
      pc[j] = { page: page, col: col };
      col++;
      prevHeaderCellEmpty = headerCellEmpty;
    }
    return pc;
  }
}
