import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { GenericModel } from "src/app/models/GenericModel.model";
import { ScriptsService } from "src/app/services/scripts.service";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DataTableService } from '../services/data-table.service';


@Component({
  selector: 'app-place-data-page',
  templateUrl: './place-data-page.component.html',
  styleUrls: ['./place-data-page.component.scss']
})
export class PlaceDataPageComponent implements OnInit {

  latestSurveyYear: number;
  breakpoint: number;
  public isMobile: boolean = false;
  constructor(private scripts: ScriptsService, private route: ActivatedRoute, private breakpointObserver: BreakpointObserver, private dataTableService: DataTableService) {
    breakpointObserver.observe([
      '(max-width: 599px)'
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

  }

  async ngOnInit(): Promise<void> {
    this.scripts.loadHeaderFooterScript();
    this.latestSurveyYear = parseInt(this.dataTableService.GetYears()[0]);

    this.breakpoint = (window.innerWidth <= 600) ? 1 : 2;


  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 600) ? 1 : 2;
  }
}
