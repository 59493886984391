import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../core/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  logging: boolean = true;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private idle: Idle, private keepalive: Keepalive,
    private router: Router, private modalService: BsModalService, public utils: UtilsService) {

  }

  ngOnInit(): void {
    console.log('');
  }


  onActivate(event) {
    window.scroll(0, 0);
  }

}
