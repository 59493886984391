// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // URL of development API
  apiUrl: 'https://ei-app-d.app.cloud.gov',
  contentUrl: 'https://fdic-wwwdev.app.cloud.gov',
  // devContentUrl: 'https://fdic-wwwdev-d.app.cloud.gov',
  // sysTestContentUrl: 'https://fdic-wwwdev-s.app.cloud.gov',
  prodContentUrl: 'https://fdic.gov',
  // s3URL: 'https://www-d.fdic.gov',
};