import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ScriptsService } from '../services/scripts.service';
import { SurveyDataRequestBody, SurveyDataMetadata, SurveyEvent, GeographyFilterModel } from '../models/surveyData.model';
import { placeChartConfig } from '../common/Chart';
import { ChartCategory, ChartDataset, ChartDataSource, ChartFilter } from '../models/chartTool.model';
import { DataTableService } from 'src/app/services/data-table.service';

@Component({
  selector: 'app-compare-tool',
  templateUrl: './compare-tool.component.html',
  styleUrls: ['./compare-tool.component.scss']
})
export class CompareToolComponent implements OnInit {

  latestSurveyYear: number;
  requestBody1: SurveyDataRequestBody;
  requestMetadata1: SurveyDataMetadata;
  loading = true;
  requestBody2: SurveyDataRequestBody;
  requestMetadata2: SurveyDataMetadata;
  loading2 = true;

  type = "mscolumn2d";

  dataSource1: ChartDataSource = {
    chart: {}
  };

  dataSource2: ChartDataSource = {
    chart: {}
  };

  chart1: any;
  chart2: any;

  chartTitle1: string = '';
  chartTitle2: string = '';

  compareAreaObj: GeographyFilterModel;

  constructor(private scripts: ScriptsService, private route: ActivatedRoute, private apiService: ApiService, private dataTableService: DataTableService) {
    this.setChartConfigDefault();
    this.latestSurveyYear = parseInt(this.dataTableService.GetYears()[0]);
  }

  ngOnInit(): void {
    this.scripts.loadHeaderFooterScript();
    if (this.route.snapshot.queryParams) {
      this.setChartConfigRequestBody();
    }
  }

  setChartConfigDefault(): void {

    this.requestBody1 = {
      chartType: "vbar",
      year: "multi",
      colVar: "hunbnk",
      rowvars: ["hhincome"],
      needNewData: true,
      filterVar: "",
      filterVar2: "",
      region: 0,
      state: 0,
      msa: 0,
    };

    this.requestBody2 = this.requestBody1;

    this.requestMetadata1 = {
      yVariableTopic: "Bank Account Ownership",
      yVariableText: "Unbanked",
      area: "", //this.placeDataService.curTypeTitle,
      year: "multi",
      colVarValue: ["Unbanked"],

      filterText: "",
      filterText2: "",
    };

    this.requestMetadata2 = this.requestMetadata1;

    this.dataSource1.chart = placeChartConfig;

    this.dataSource2.chart = placeChartConfig;
  }

  async setChartConfigRequestBody(): Promise<void> {
    const { area, location, area2, location2 } = this.route.snapshot.queryParams;

    this.compareAreaObj = {
      area1: area,
      locationCode1: location,
      area2: area2,
      locationCode2: location2,
    }
  }



  initialized1($event) {
    // Capture chart instance
    this.chart1 = $event.chart;
  }

  renderComplete($event) {
  }

  initialized2($event) {
    // Capture chart instance
    this.chart2 = $event.chart;
  }

  async receiveChartTitles($event: string[]) {
    this.chartTitle1 = $event[0];
    this.chartTitle2 = $event[1];
  }

  async receiveRequestBody($event: SurveyEvent[]) {
    this.loading = true;

    // const needNewData1 = this.compareRequestBody(this.requestBody1, $event[0].requestBody);
    // const needNewData2 = this.compareRequestBody(this.requestBody2, $event[1].requestBody);
    this.requestBody1 = $event[0].requestBody;
    this.requestMetadata1 = $event[0].requestMetadata;
    // this.requestBody1.needNewData = needNewData1 ? true : false;
    this.dataSource1 = await this.getData($event[0]);
    // this.chart1.setChartData(this.dataSource1);
    // console.log(this.dataSource1)


    this.requestBody2 = $event[1].requestBody;
    this.requestMetadata2 = $event[1].requestMetadata;
    // this.requestBody2.needNewData = needNewData2 ? true : false;
    this.dataSource2 = await this.getData($event[1]);
    // console.log(this.dataSource2)
    this.loading = false;
  }

  compareRequestBody(requestBody: SurveyDataRequestBody, newRequestBody: SurveyDataRequestBody): boolean {
    const fieldsToCompare = ['area', 'region', 'state', 'msa'];
    for (let i = 0; i < fieldsToCompare.length; i++) {
      const field = fieldsToCompare[i];

      if (requestBody[field] !== newRequestBody[field]) {
        return true;
      }

    }
    return false;
  }

  async getData(requestObj: SurveyEvent): Promise<ChartDataSource> {

    let tableData: any;
    let tableAllRecords: any;
    let chartDataSource: ChartDataSource;

    // Only fetch data if certain variables changed
    // if (requestObj.requestBody.needNewData) {
    const response = await this.apiService.getSurveyData(requestObj.requestBody);
    if (response) {
      if (response.length > 0) {
        tableData = response;
        tableAllRecords = await this.organizemultiyeartable(requestObj, tableData);
      }
    }
    // }

    // console.log(tableAllRecords)
    if (tableAllRecords.length > 0) {
      chartDataSource = await this.createOriginalMultiyearChart(requestObj, tableAllRecords);
    }

    return chartDataSource;
  }

  async organizemultiyeartable(requestObj: SurveyEvent, tableData: any): Promise<any> {
    const chartObj: ChartFilter = {
      chartType: requestObj.requestBody.chartType,
      colVarValue: requestObj.requestMetadata.colVarValue,
      rowvarsValues: requestObj.requestMetadata.rowvarsValues,
    };
    let {
      tableAllRecords,
    } = this.dataTableService.organizeMultiyearTableForChart(
      tableData,
      requestObj.requestBody,
      chartObj
    );

    return tableAllRecords;
  }

  async createOriginalMultiyearChart(requestObj: SurveyEvent, tableAllRecords: any): Promise<ChartDataSource> {
    let originalDataset: ChartDataset[] = [];
    let originalCategories: ChartCategory[] = [
      {
        category: [],
      },
    ];

    let dataSource: ChartDataSource = {
      chart: {}
    };

    dataSource.chart = placeChartConfig;

    dataSource.categories = [];
    dataSource.dataset = [];
    var year = ["2021,2019,2017"];

    const { colVarValue } = requestObj.requestMetadata;
    // Multiyear data comes in three arrays
    const { category } = originalCategories[0];
    let showChart = false;
    // Any missing records were handled in the table build, so data can be added to the chart in order
    // Multiyear does years in reverse order
    for (let i = tableAllRecords.length - 1; i >= 0; i--) {
      if(tableAllRecords[i][0].length<=colVarValue.length)
      {
        for(let c=0 ; c< colVarValue.length; c++)
        {
         if( tableAllRecords[i][0].find(x=> x.colheader == colVarValue[c]) == undefined)
         {
           tableAllRecords[i][0].push( { colheader: colVarValue[c], dcount:0, percent:0, year:year[i], isNA:false})
         } 
         
        } 
      }
      const currentYearArray = tableAllRecords[i][0];
      category.push({ label: currentYearArray[0].year.toString() });
      // Tracker for which array to use in originalDataset
      let dataIndex = 0;
      for (let j = 0; j < currentYearArray.length; j++) {
        const item = currentYearArray[j];
        // Make sure item should be displayed
        if (colVarValue.includes(item.colheader)) {
          // For initial items, create dataset
          if (i === tableAllRecords.length - 1) {
            originalDataset.push({ seriesname: item.colheader, data: [] })
          }
          // Check if data exists to display
          if (showChart === false && !item.isNA) {
            showChart = true;
          }
          // Make sure that item surpasses supression count or make percent NA
          if (item.dcount < this.dataTableService.supressioncount) {
            item.percent = "NA";
          }
          // NA values need to be added as displayValue
          if (item.percent === 'NA' || item.percent === '-') {
            originalDataset[dataIndex].data.push({ value: "0", displayValue: item.percent });
          } else if (item.percent === 0) {
            originalDataset[dataIndex].data.push({ value: "0", displayValue: '-' });
          } else {
            originalDataset[dataIndex].data.push({ value: item.percent, valueBgColor: '#ffffff' });
          }
          dataIndex += 1;
        }
      }
    }
    dataSource.categories = originalCategories;
    dataSource.dataset = originalDataset;

    return dataSource;
  }

}
