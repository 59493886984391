import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ChartFilter, TableDataForChart } from 'src/app/models/chartTool.model';
import { CustomDataModel } from 'src/app/models/CustomDataModel';
import { GenericModel } from 'src/app/models/GenericModel.model';
import { SurveyDataMetadata, SurveyDataRequestBody, SurveyEvent } from 'src/app/models/surveyData.model';
import { ApiService } from 'src/app/services/api.service';
import { DataTableService } from 'src/app/services/data-table.service';


@Component({
  selector: 'app-api-vbar',
  templateUrl: './api-vbar.component.html',
  styleUrls: ['./api-vbar.component.scss']
})
export class ApiVbarComponent implements OnInit {

  TableData: any;
  // ColumnHeaders: GenericModel[];
  tableHeaderRow: any[] = [];
  multiHeaderRow: any[][] = [];
  TableReady: boolean = false;
  headersReady: boolean = false;
  //supression count is the amount of dcount necessary to be displayed. if dcount < supression then it is NA instead.
  supressioncount: number = 100;
  TableOrganized: any[][] = [];
  TableAllRecords: any[][] = [];
  tablestring: string;
  dataTitle: string;
  dataSubtitle: string = "";
  requestBody: SurveyDataRequestBody;
  requestMetadata: SurveyDataMetadata;
  rowCategoryColspan = 0;
  Multiyear: boolean = false;
  Multiyeartableorganize: any[][][] = [];
  msadata: any[];



  @Output() tableDataEvent = new EventEmitter<TableDataForChart>();

  constructor(
    private apiService: ApiService,
    private dataTableService: DataTableService
  ) { }

  async ngOnInit(): Promise<void> {


  }

  async getData(requestObj: SurveyEvent): Promise<void> {

    this.requestBody = requestObj.requestBody;
    this.requestMetadata = requestObj.requestMetadata;
    this.msadata = requestObj.msadata;

    // Only fetch data if certain variables changed
    if (this.requestBody.needNewData) {
      const response = await this.apiService.getSurveyData(this.requestBody);
      if (response) {
        if (response.length > 0) {
          this.TableData = response;
          if (this.requestBody.year === "multi") {
            this.Multiyear = true;
            await this.organizemultiyeartable();
          }
        }
      }
    } else {
      if (this.requestBody.year === "multi") {
        this.Multiyear = true;
      } else {
        this.Multiyear = false;
      }
    }

    this.sendTableData();
  }

 async organizemultiyeartable(): Promise<void> {
    const chartObj: ChartFilter = {
      chartType: this.requestBody.chartType,
      colVarValue: this.requestMetadata.colVarValue,
      rowvarsValues: this.requestMetadata.rowvarsValues,
    };
    let {

      tableAllRecords,


    } = this.dataTableService.organizeMultiyearTableForChart(
      this.TableData,
      this.requestBody,
      chartObj
    );
    this.TableAllRecords = tableAllRecords;
    // this.TableReady = true;
  }

  sendTableData(): void {
    this.tableDataEvent.emit({
      tableData: this.TableAllRecords,
      dataTitle: this.dataTitle,
      dataSubtitle: this.dataSubtitle,
      tableHeaderRow: this.tableHeaderRow,
    });
  }


}